import React from 'react';
import Typography from '@material-ui/core/Typography';

export default (props)=>{
    return <div style={{marginTop: 0, marginBottom: 5, cursor: props.editable ? "pointer" : "default"}} onClick={props.onClick}>
        <div style = {{padding: 5}}>
            <Typography variant="body2">
                <b>{props.member.forename + " " + props.member.surname}</b>
            </Typography>
            <Typography variant="body1">
                {props.member.email}
            </Typography>
            {props.member.isAdmin ? <Typography style={{color: "red"}}>Administrator</Typography> : null}
            {!props.member.userKnown ? <div><br/><Typography style={{color: "orange"}}>Benutzer ist nicht bekannt und wird eingeladen</Typography></div> : null}
        </div>
    </div>
}