import React from 'react';
import TextField from '@material-ui/core/TextField';
import AbstractResourceDetails from './abstractresourcedetails.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CarIcon from '../icons/car.js';
import TruckIcon from '../icons/truck.js';
import BusIcon from '../icons/bus.js';
import MotorcycleIcon from '../icons/motorcycle.js';
import TruckTrailerIcon from '../icons/trucktrailer.js';
import CarTrailerIcon from '../icons/cartrailer.js';
import ConstructionVehicleIcon from '../icons/constructionvehicle.js';
import OtherVehicleIcon from '../icons/others.js';
import Config from '../config/config.js';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core/styles';
import {styles} from '@softmanufaktur/timeline';
import Typography from '@material-ui/core/Typography';

class VehicleResourceDetails extends AbstractResourceDetails {
	constructor(props) {
		super(props);
		this.state.category = props.data.getVehicleCategory() || 0;
        this.state.brand = props.data.getVehicleBrand() || 0;
        this.state.vehicleModel = props.data.getVehicleModel();
        this.state.brands = [];
	}
	
	writeToResource(res) {
		super.writeToResource(res);
		res.setVehicleCategory(this.state.category);
		res.setVehicleBrand(this.state.brand);
		res.setVehicleModel(this.state.vehicleModel);
	}

	getTitleString() {
		return (this.props.data && this.props.data.getID() < 0) ? "neues Fahrzeug" : "Fahrzeug";
	}

	categoryChanged(val) {
		this.setState({category: val});
	}
	
	brandChanged(val) {
		this.setState({brand: val});
	}
	
	getIcon() {
		let styles = this.getStyles();
		if(this.state.imageFileName !== "") {
			return <img src={Config.getBaseURL()+"/images/resourceimages/"+this.state.imageFileName} alt=""/>
		} else {
            switch (this.state.category) {
                case 1:
                    return <CarIcon style={styles.resIcon}/>
                case 2:
                    return <BusIcon style={styles.resIcon}/>
                case 3:
                    return <TruckIcon style={styles.resIcon}/>
                case 4:
                    return <MotorcycleIcon style={styles.resIcon}/>
                case 5:
                    return <ConstructionVehicleIcon style={styles.resIcon}/>
                case 6:
                    return <CarTrailerIcon style={styles.resIcon}/>
                case 7:
                    return <TruckTrailerIcon style={styles.resIcon}/>
                default:
                    return <OtherVehicleIcon style={styles.resIcon}/>
            }
        }
	}
	
	getContent() {
		const stylesOld = this.getStyles();
		const classes = this.props.classes;
		let content;
		if (this.state.editMode) {
            let brandMenuItems = [ ];

            for(let brand of this.props.model.getBrandsOfCategory(this.state.category)) {
				brandMenuItems.push(<MenuItem key={brand.id} value={brand.id} onClick={()=>this.setState({brand: brand.id})}>{brand.value}</MenuItem>);
            }
			content = <div>
						  	<TextField id="resNameInputField" label="Bezeichnung" value={this.state.name} onChange={(evt) => this.setState({name : evt.target.value})}  className={classes.field} fullWidth style={{fontWeight: 'bold', fontSize: 20}} />
							<br/>
						  	<table style={stylesOld.table}>
						  		<tbody>
							  		<tr>
								  		<td style={stylesOld.halfWidth}>
											<TextField id="resSecNameInputField" label="Kennzeichen" value={this.state.secname || ""} onChange={(evt) => this.setState({secname : evt.target.value})} fullWidth className={classes.field}/>
											<br/>
											<FormControl fullWidth className={classes.field}>
												<InputLabel htmlFor="resTypeInput">Art des Fahrzeugs</InputLabel>
												<Select
												  value={this.state.category}
												  onChange={(evt)=>this.categoryChanged(evt.target.value)}
												  style={stylesOld.fullWidth}
												  input={<Input id="resTypeInput"/>}
												>
													<MenuItem key={1} value={1}>PKW</MenuItem>
													<MenuItem key={2} value={2}>Bus</MenuItem>
													<MenuItem key={3} value={3}>LKW</MenuItem>
													<MenuItem key={4} value={4}>Motorrad</MenuItem>
													<MenuItem key={5} value={5}>Baumaschine</MenuItem>
													<MenuItem key={6} value={6}>PKW-Anhänger</MenuItem>
													<MenuItem key={7} value={7}>LKW-Anhänger</MenuItem>
													<MenuItem key={100} value={100}>Sonstiges</MenuItem>
												</Select>
											</FormControl>
											<br/>
											<FormControl fullWidth className={classes.field}>
												<InputLabel htmlFor="resBrandInput">Marke</InputLabel>
												<Select
												  value={this.state.brand}
												  onChange={(evt)=>this.brandChanged(evt.target.value)}
												  style={stylesOld.fullWidth}
												  input={<Input id="resBrandInput" />}
												>
													{brandMenuItems}
												</Select>
											</FormControl>
											<br/>
										    <TextField id="resModelInputField" label="Modell" value={this.state.vehicleModel || ""} onChange={(evt) => this.setState({vehicleModel : evt.target.value})} fullWidth className={classes.field}/>
										 </td>
										 <td style={stylesOld.center}>
                                             {this.getIcon()}
											 <br/>
											 {this.getImageUploadForm()}
										 </td>
									 </tr>
								 </tbody>
						   </table>

							<TextField
								helperText="(mehrzeilige Eingabe möglich)"
								label="Beschreibung"
								value={this.state.description}
								onChange={(evt) => this.setState({description: evt.target.value})}
								fullWidth
								multiline
								className={classes.field}
							/>
							<br/>
							<br/>
							{this.getFooter()}
				    </div>
		} else {
			//<Avatar src="images/uxceo-128.jpg" />
			content = <div>
						<Typography>
							<span style={stylesOld.resName}>{this.props.data.getName()}</span>
							<br/>
							{this.props.data.secname}
							<br/>
						</Typography>
							<table style={stylesOld.table}>
								<tbody>
									<tr>
										<td style={{width: "50%", verticalAlign: "top"}}>
											<Typography>
												<b>{this.props.model.getBrandName(this.state.brand)}</b>
											&nbsp;{this.state.vehicleModel}
											<br/>
											{this.state.description}
											</Typography>
										 </td>
										 <td style={stylesOld.center}>
											{this.getIcon()}
										 </td>
									 </tr>
								 </tbody>
						   </table>

						{this.getFooter()}
					  </div>
		}
		
		return content;
	}
}

export default withStyles(styles)(VehicleResourceDetails);