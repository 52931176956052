import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import {DateTimeIntervalBlock, LCal, LCalDateTimeField, LCalFormatter, LCalHelper} from '@softmanufaktur/timeline';
import BookedResourceOptions from './bookedresourceoptions';
import Details from '../ui-components/details';
import {findDOMNode} from 'react-dom';
import Typography from '@material-ui/core/Typography';


class BookingDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: props.initialEditMode,
            name: props.data.getName() || "",
            fromLCal: props.data.getStart(),
            toLCal: props.data.getEnd(),
            duration: props.data.getAbsDurationMinutesConsiderPrecision(),
            newTaskIDs: [],
            debitor: props.data.getDebitor() || "",
            addResourceOpen: false,
            hasOverlap: false,
            hasOverlapWithNoAdminRes: false
        };

        this.overlapTimeoutHandle = null;
        this.checkConstraints();
    }

    componentDidMount() {
        if (this.props.initialEditMode) {
            this.edit();
        }
        this.checkConstraints();
    }

    getAllReferencedTaskIDs() {
        let retArr = (this.props.data.getRelatedTaskIDs() || []).concat(this.state.newTaskIDs);
        retArr.push(this.props.data.getID());
        return retArr;
    }

    updateTasks() {
        let taskIDs = this.getAllReferencedTaskIDs();
        let movedTasks = [];


        for (let id of taskIDs) {
            //Zuserst bei den movedTasks nachschauen. Es könnte sein, dass da z.B. ein gelöschter dabei ist und diese Information findet sich nur dort.
            let task = null;
            for (let mt of this.props.model.getMovedTasks()) {
                if (mt.getID() === id) {
                    task = mt;
                    break;
                }
            }
            if (!task) {
                task = this.props.model.getItemByID(id);
            }
            if (task) {
                let taskClone = task.clone();
                this.writeToTask(taskClone, task.getResID());
                movedTasks.push(taskClone);
            }
        }

        this.props.model.setMovedTasks(movedTasks);
    }

    componentDidUpdate() {
        //Das Model frisch halten
        if (this.state.editMode) {
            this.updateTasks();
        }
        this.checkConstraints();
    }

    componentWillUnmount() {
        this.props.model.setMovedTasks([]);
        //Falls noch nicht gespeichert wurde und es sich um einen neuen Vorgang handelt, dann den Vorgang löschen
        if (this.props.data.getID() < 0) {
            this.props.model.remove(this.props.data);
        }
    }

    save() {
        let taskID2Opts = {};
        for (let taskID of this.getAllReferencedTaskIDs()) {
            let ref = this.refs["bookedResOpt" + taskID];
            if(ref) {
                let opts = ref.getChoosenOptions();
                if (opts) {
                    taskID2Opts[taskID] = opts.toJSON();
                }

                let debitor = ref.getDebitor();
                for (let t of this.props.model.getMovedTasks()) {
                    if (t.getID() === taskID) {
                        t.setDebitor(debitor);
                        break;
                    }
                }
            }
        }
        this.props.onSave(this.props.model.getMovedTasks(), taskID2Opts);
    }

    /**
     * Prüfung auf Überschneidung und Ende vor Start etc.
     */
    checkConstraints() {
        clearTimeout(this.overlapTimeoutHandle);
        this.overlapTimeoutHandle = setTimeout(()=> {
            //this.setState({hasOverlapWithNoAdminRes: false, hasOverlap: false});
            let hasOverlap = false;
            let hasOverlapWithNoAdminRes = false;
            for (let task of this.props.model.getMovedTasks()) {
                if(task) {
                    for (let t of this.props.model.getAll()) {
                        if (t.getResID() === task.getResID() && task.getID() !== t.getID()) {
                            if (task.getEnd().getJulianMinutes() > t.getStart().getJulianMinutes() && task.getStart().getJulianMinutes() < t.getEnd().getJulianMinutes()) {
                                let res = this.props.model.getResourceModel().getItemByID(task.getResID());
                                if (res.hasAdminRights()) {
                                    hasOverlap = true;
                                        this.setState({hasOverlap: true});
                                } else {
                                    hasOverlapWithNoAdminRes = true;
                                    break;
                                }
                            }
                        }
                    }
                    if(hasOverlapWithNoAdminRes) {
                        break;
                    }
                }
            }
            if(this.state.hasOverlapWithNoAdminRes !== hasOverlapWithNoAdminRes || this.state.hasOverlap !== hasOverlap) {
                this.setState({hasOverlapWithNoAdminRes: hasOverlapWithNoAdminRes, hasOverlap: hasOverlap});
            }
        }, 400);
    }

    isInEditMode() {
        return this.state.editMode;
    }

    edit() {
        if (this.props.onAbortableChange) {
            this.props.onAbortableChange(false);
        }

        this.setState({editMode: true});
    }

    writeToTask(task, resID) {
        task.setName(this.state.name);
        if(this.state.fromLCal) {
            task.setStart(this.state.fromLCal);
        }
        if(this.state.toLCal) {
            task.setEnd(this.state.toLCal);
        }
        task.setResID(resID);
        task.setDebitor(this.state.debitor);
        task.setBookingNumber(this.props.data.getBookingNumber());
    }

    getLCal(date) {
        return LCalHelper.getLCal(date);
    }

    getJSDate(lcal) {
        return LCalHelper.getJSDate(lcal);
    }


    getInt(duration) {
        let retVal = 0;
        if (duration) {
            retVal = parseInt(duration);
            if (isNaN(retVal)) {
                retVal = 0;
            }
        }
        return retVal;
    }

    deleteTask() {

    }


    resourceChanged(taskID, resID, isStorno) {
        for (let t of this.props.model.getMovedTasks()) {
            if (t.getID() === taskID) {
                t.setResID(resID);
                t.setDeleted(isStorno);
                this.props.model._fireDataChanged();
                this.updateTasks();
                this.forceUpdate();
                this.checkConstraints();
                return;
            }
        }

        console.log("Bei resourceChanged wurde die Task mit der ID " + taskID + " nicht in den movedTasks gefunden.");
    }

    debitorChanged(debitor) {
        this.setState({debitor: debitor});
    }

    durationChanged(duration) {
        //Geänderte Dauer bedeutet, dass das Ende neu berechnet werden muss (falls Assignment durch Start/Dauer definiert ist)
        let t = null;
        if (this.state.fromLCal !== null && !isNaN(parseFloat(duration)) && isFinite(duration)) {
            t = this.state.fromLCal.clone();
            t.addMinutes(this.getInt(duration));
        }
        this.setState({duration: duration, toLCal: t});
        this.checkConstraints();
    }

    fromLCalChanged(fromLcal) {
        //Dauer bleibt gleich, Ende wird neu berechnet
        let t = null;
        if (fromLcal && this.state.duration) {
            t = fromLcal.clone();
            t.addMinutes(this.getInt(this.state.duration));
        }
        this.setState({fromLCal: fromLcal, toLCal: t});

        this.checkConstraints();
    }


    toLCalChanged(toLCal) {
        //Start bleibt gleich, Dauer wird neu berechnet
        let duration = null;
        if (this.state.fromLCal && toLCal) {
            duration = this.state.fromLCal.getDistanceInMinutes(toLCal);
        }
        this.setState({duration: duration, toLCal: toLCal});
        this.checkConstraints();
    }


    getIcon(res) {
        let img = this.props.model.getIcon(res);
        return <img style={{width: 32, height: 32, verticalAlign: 'middle', margin: 2}} src={img.src} alt=""/>
    }

    addResource(res) {
        let newTask = this.props.model.createBookingItem(res.getID(), this.state.fromLCal, 0);
        this.writeToTask(newTask, res.getID());

        let newTaskIDs = this.state.newTaskIDs.slice();
        newTaskIDs.push(newTask.getID());

        //Der neue Vorgang muss zu den movedTasks zugefügt werden
        let movedTasks = this.props.model.getMovedTasks();
        movedTasks.push(newTask);
        this.props.model.setMovedTasks(movedTasks);

        this.setState({newTaskIDs: newTaskIDs, addResourceOpen: false});
    }

    removeNewTaskByID(taskID) {
        let i = this.state.newTaskIDs.indexOf(taskID);
        if (i >= 0) {
            let newTaskIDs = this.state.newTaskIDs.slice();
            newTaskIDs.splice(i, 1);
            this.setState({newTaskIDs: newTaskIDs});
        }
    }

    getAllowedResources(allowedTask) {
        let resources = [];
        for (let res of this.props.model.getResourceModel().getAll()) {
            //die ausschließen, die bereits zugefügt sind

            let ignoreRes = false;

            let movedTasks = this.props.model.getMovedTasks();
            if(movedTasks && movedTasks.length>0) {
                for (let task of movedTasks) {
                    if (task && (!allowedTask || task.getID() !== allowedTask.getID()) && task.getResID() === res.getID()) {
                        ignoreRes = true;
                        break;
                    }
                }
            } else {
                for (let taskID of this.getAllReferencedTaskIDs()) {
                    let task = this.props.model.getItemByID(taskID);

                    if (task && (!allowedTask || task.getID() !== allowedTask.getID()) && task.getResID() === res.getID()) {
                        ignoreRes = true;
                        break;
                    }
                }
            }


            if (!ignoreRes) {
                resources.push(res);
            }
        }
        return resources;
    }


    render() {
        let titleStr = this.props.data.getID() < 0 ? "neue Buchung" : ("Buchung");

        const styles = {
            buttonStyle: {
                color: "white"
            },
            chip: {
                margin: 4,
            },
            fullWidth: {
                width: "100%"
            },
            timeblock: {
                background: "#FAFAFA",
                border: "1px solid black",
                padding: 5
            }
        };


        let bookedResourceOptions = [];
        let unknownResCnt = 0;

        let warnings = [];
        let allChangePreventingResHasAdminRights = true;
        for (let taskID of this.getAllReferencedTaskIDs()) {
            let task = this.props.model.getItemByID(taskID);

            if (task) {
                let res = this.props.model.getResourceModel().getItemByID(task.getResID());
                if (res) {
                    //TODO: Prüfen, ob die eingegebenen Zeiten für die Ressource passen, ggf. Warnmeldung
                    let tStart = this.state.fromLCal;
                    let tEnd = this.state.toLCal;
                    let duration = 0;
                    if (tStart !== null && tEnd !== null) {
                        if(tStart.getJulianMinutes() > tEnd.getJulianMinutes()) {
                            warnings.push(<Typography key={warnings.length}><li>Der Startzeitpunkt liegt nach dem Endzeitpunkt.</li></Typography>);
                            allChangePreventingResHasAdminRights = false;
                        }
                        if(this.state.hasOverlapWithNoAdminRes) {
                            warnings.push(<Typography key={warnings.length}><li>Die Buchung ist zeitgleich mit einer bestehenden Buchung und kann nicht gespeichert werden.</li></Typography>);
                            allChangePreventingResHasAdminRights = false;
                        } else if(this.state.hasOverlap) {
                            warnings.push(<Typography key={warnings.length}><li>Die Buchung ist zeitgleich mit einer bestehenden Buchung.</li></Typography>);
                        }
                        duration = tStart.getDistanceInMinutes(tEnd);
                        if (duration === 0) {
                            warnings.push(<Typography key={warnings.length}><li>Die Dauer muss größer als 0 Minuten sein.</li></Typography>);
                        }
                        //Startzeit muss bookingDeadline Stunden in der Zukunft liegen
                        let now = LCalHelper.getNowMinutes();

                        //Neue Buchung? Die darf bis zum aktuellen Zeitpunkt gemacht werden.
                        if(this.props.data.getID() < 0) {
                            if (tStart.getJulianMinutes() < now) {
                                warnings.push(<Typography key={warnings.length}>
                                    <li>Der Startzeitpunkt für <b>{res.getName()}</b> liegt in der Vergangenheit.</li>
                                </Typography>);
                                if (!res.hasAdminRights()) {
                                    allChangePreventingResHasAdminRights = false;
                                }
                            } else if (tStart.getJulianMinutes() < now + res.getBookingDeadline() * 60 && !res.hasAdminRights()) {
                                //Trotzdem Warnung, dass ggf. nicht mehr geändert werden darf, falls kein Admin
                                let bookingDeadlineLCal = new LCal().setTimeZone("Europe/Berlin");
                                bookingDeadlineLCal.setJulianMinutes(now + res.getBookingDeadline() * 60);
                                warnings.push(<Typography key={warnings.length}><li>Der Startzeitpunkt für <b>{res.getName()}</b> liegt
                                    vor dem Puffer für Änderungen am
                                    <b>&nbsp;{LCalFormatter.formatDate(bookingDeadlineLCal) + " " + LCalFormatter.formatTime(bookingDeadlineLCal)}
                                        &nbsp;Uhr</b>. Sie können nach der Buchung keine Änderungen mehr vornehmen.</li></Typography>);
                            }
                        }
                        //Bestehende Buchung ändern? -> Nur im erlaubten Zeitbereich
                        if (this.props.data.getID() >= 0 && tStart.getJulianMinutes() < now + res.getBookingDeadline() * 60) {
                            let bookingDeadlineLCal = new LCal().setTimeZone("Europe/Berlin");
                            bookingDeadlineLCal.setJulianMinutes(now + res.getBookingDeadline() * 60);
                            warnings.push(<Typography key={warnings.length}><li>Der Startzeitpunkt für <b>{res.getName()}</b> liegt
                                vor dem notwendigen Puffer für Änderungen am
                                <b>&nbsp;{LCalFormatter.formatDate(bookingDeadlineLCal) + " " + LCalFormatter.formatTime(bookingDeadlineLCal)}
                                    &nbsp;Uhr</b></li></Typography>);
                            if (!res.hasAdminRights()) {
                                allChangePreventingResHasAdminRights = false;
                            }
                        }

                        let bookingHorizontLCal = new LCal().setTimeZone("Europe/Berlin");
                        bookingHorizontLCal.setJulianMinutes(now);
                        bookingHorizontLCal.addDay(res.getBookingHorizont());
                        if (tStart.getJulianMinutes() > bookingHorizontLCal.getJulianMinutes()) {
                            warnings.push(<Typography key={warnings.length}><li>Der Startzeitpunkt für <b>{res.getName()}</b> liegt
                                nach dem erlaubten Zeitpunkt für Änderungen am
                                <b>&nbsp;{LCalFormatter.formatDate(bookingHorizontLCal) + " " + LCalFormatter.formatTime(bookingHorizontLCal)}
                                    &nbsp;Uhr</b></li></Typography>);
                            if (!res.hasAdminRights()) {
                                allChangePreventingResHasAdminRights = false;
                            }
                        }
                    } else {
                        if (tStart === null) {
                            warnings.push(<Typography key={warnings.length}><li>Es ist keine Startzeit angegeben.</li></Typography>);
                            //if (!res.hasAdminRights()) {
                                allChangePreventingResHasAdminRights = false;
                            //}
                        }
                        if (tEnd === null) {
                            warnings.push(<Typography key={warnings.length}><li>Es ist keine Endzeit angegeben.</li></Typography>);
                            //if (!res.hasAdminRights()) {
                                allChangePreventingResHasAdminRights = false;
                            //}
                        }
                    }


                    if (taskID === this.props.data.getID()) {
                        //Bei der eigenen Ressource darf es kein onRemove geben
                        bookedResourceOptions.push(<BookedResourceOptions ref={"bookedResOpt" + task.getID()}
                                                                          key={"task_" + task.getID()}
                                                                          data={task}
                                                                          model={this.props.model}
                                                                          editMode={this.state.editMode}
                                                                          chooseableResources={this.getAllowedResources(task)}
                                                                          onResourceChange={(resID, isStorno) => this.resourceChanged(taskID, resID, isStorno)}/>);
                    } else {
                        bookedResourceOptions.push(<BookedResourceOptions ref={"bookedResOpt" + task.getID()}
                                                                          key={"task_" + task.getID()}
                                                                          data={task}
                                                                          model={this.props.model}
                                                                          editMode={this.state.editMode}
                                                                          onRemove={() => this.removeNewTaskByID(taskID)}
                                                                          chooseableResources={this.getAllowedResources(task)}
                                                                          onResourceChange={(resID, isStorno) => this.resourceChanged(taskID, resID, isStorno)}/>);
                    }

                } else {
                    allChangePreventingResHasAdminRights = false;
                    console.log("Ressource mit id " + task.getResID() + " für Task " + taskID + " nicht gefunden.");
                    unknownResCnt++;
                }
            } else {
                allChangePreventingResHasAdminRights = false;
                console.log("Task mit id " + taskID + " nicht gefunden.");
                unknownResCnt++;
            }
        }

        let warningDiv = "";
        if (warnings.length > 0) {
            warningDiv =
                <div style={{background: "#FFDDDD", border: "1px solid red", padding: "5px", marginTop: 10, marginBottom: 10}}>
                    <ul style={{listStylePosition: "inside"}}>{warnings}</ul>
                </div>
        }
        if (unknownResCnt > 0) {
            bookedResourceOptions.push(<span key="res_unknown"><Typography> {unknownResCnt} nicht sichtbare Ressourcen<br/></Typography></span>);
        }

        let buttons = null;
        if (this.state.editMode) {
            if (warnings.length === 0 || allChangePreventingResHasAdminRights) {
                buttons = <div>
                    <Button style={styles.buttonStyle} onClick={() => this.save()}>Speichern</Button>
                </div>
            }
        } else {
            if (this.props.data.canBook) {
                buttons = <div>
                    {warnings.length === 0 || allChangePreventingResHasAdminRights ?
                        <Button style={styles.buttonStyle} onClick={() => this.edit()}>Bearbeiten</Button> : null }
                </div>
            }
        }

        let content;
        if (this.state.editMode) {
            let resourceMenuItems = [];
            for (let res of this.getAllowedResources()) {
                    resourceMenuItems.push(<MenuItem key={"res_" + res.getID()}
                                                     onClick={(evt) => this.addResource(res)}>{res.getName()}</MenuItem>);
            }

            content = <div>
                {warningDiv}
                <TextField id="bookingNameInputField" label="Kommentar" value={this.state.name}
                           onChange={(evt) => this.setState({name: evt.target.value})}
                           style={{width: "100%", fontWeight: 'bold', fontSize: 20}}/>
                <br/>
                <br/>
                <div style={styles.timeblock}>
                    <LCalDateTimeField label="von" value={this.state.fromLCal}
                                   onChange={(d) => this.fromLCalChanged(d)}/>
                    <br/>
                    <LCalDateTimeField label="bis" value={this.state.toLCal}
                                   onChange={(d) => this.toLCalChanged(d)}/>
                    <br/>
                    <TextField id="bookingDurationInputField" label="Dauer [min]" type="number"
                               value={this.state.duration ? this.state.duration : ""} onChange={(evt) => this.durationChanged(evt.target.value)}/>
                </div>
                <br/>
                {bookedResourceOptions}
                <br/>


                <Menu
                    open={this.state.addResourceOpen}
                    onClose={(reason) => {
                        this.setState({addResourceOpen: false})
                    }}
                    anchorEl={this.state.anchorEl}
                >

                    {resourceMenuItems}
                </Menu>
                <br/>
                <br/>
            </div>
        } else {
            content = <div>
                {warningDiv}
                <Typography variant="title" gutterBottom>
                    {this.props.data.getName()}
                </Typography>
                <br/>
                <DateTimeIntervalBlock interval={this.props.data} defaultDurationType={"minutes"}/>
                <br/>
                {bookedResourceOptions}
                <br/>
                <br/>
                {this.props.data.canBook && <div><Button variant="raised" onClick={() => this.props.onHistory()}>Historie</Button></div>}
            </div>
        }




        let addResButton = null;
        if(this.state.editMode) {
            addResButton = <Button variant="fab" color="primary"
                    onClick={(evt) => {
                        /* evt.preventDefault ist notwendig, da sonst das Popup auf dem mobile nicht geöffnet wird: https://github.com/callemall/material-ui/issues/3335 */
                        evt.preventDefault();
                        this.setState({addResourceOpen: true, anchorEl: findDOMNode(evt.currentTarget)})
                    }}
            ><AddIcon /></Button>
        }

        return (
            <Details onClose={() => this.props.onClose()} title={titleStr} addButtons={buttons} fab={addResButton}
                     statusBarData={this.props.data.canBook ? this.props.data : null}>
                <div style={{
                    flexGrow: 1,
                    overflow: "auto",
                    minHeight: "2em",
                    padding: "10px"
                }}>
                    {content}
                </div>
            </Details>
        )
    }
}

export default BookingDetails;
