import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AlarmIcon from '@material-ui/icons/Alarm';
import OptionsDialog from './optionsdialog.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import TransferHandler from '../transfer/transferhandler.js';
import Config from '../config/config.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Immutable, {Set} from 'immutable';
import OptionGroupEntry from './optiongroupentry.js';
import OptionGroupCard from './bookingoptioncard';
import {styles} from '@softmanufaktur/timeline';
import DistributeOptionsDialog from './distributeoptionsdialog';

class BookingOptions extends React.Component {
    constructor(props) {
        super(props);
        this.dialogOKClicked = this.dialogOKClicked.bind(this);
        this.dialogDeleteClicked = this.dialogDeleteClicked.bind(this);

        this.state = {
            optionGroups:  new Set(),
            editOption: "",
            editOptionMode: 'new',
            optionsLoaded: false,
            loadingError: false,
            expanded: false,
            distributeOptions: false
        };
    }


    componentDidMount() {
        this.props.onMount && this.props.onMount((res)=>this.writeToResource(res));

            const transferObj = {resourceid: this.props.data.id}

            //Im Erfolgsfall den optionGroups-Status aktualisieren
            let successFunc = (json) => {
                let opts = Immutable.Set(json.optionGroups);
                this.setState({optionGroups: opts, optionsLoaded: true});
            }

            let errorFunc = () => {
                this.setState({loadingError: true})
            }
            TransferHandler.request(Config.getOptionGroupsURL(), successFunc, errorFunc, transferObj);
    }


    writeToResource(res) {
        //TODO: In die Datenbank schreiben, bzw. an ein SAVE-Objekt. Nicht an die Ressource!!
        res.setBookingOptions(this.state.optionGroups.toJSON());
    }


    dialogOKClicked(name, options, multichoice) {
        //Hier gleich bestimmen, ob es bereits einen User mit dieser E-Mail gibt und welchen forename, surname, company er hat
        let entry = new OptionGroupEntry(name, options, multichoice);
        let items = new Set(this.state.optionGroups.delete(this.state.editOption).add(entry));
        this.setState({optionGroups: items, editOption: ""});
    }

    dialogDeleteClicked() {
        let items = new Set(this.state.optionGroups.delete(this.state.editOption));
        this.setState({optionGroups: items, editOption: ""});
    }

    render() {
        const classes = this.props.classes;

        let content = this.state.optionsLoaded ?
            (<div>
                <div>
                    {this.state.optionGroups.sort((a, b) => {
                        let retVal = a.name.localeCompare(b.name);
                        return retVal;
                    }).map((optGrp) => {
                               return <OptionGroupCard key={optGrp.name} optionGroup={optGrp} editable={this.props.editMode}
                                                  onClick={() => {
                                                      this.props.editMode && this.setState({
                                                          editOption: optGrp,
                                                          editOptionMode: "edit"
                                                      })
                                                  }}/>
                        }
                    )}
                </div>
            </div>)
            : (this.state.loadingError ?
                <div>Fehler beim Laden</div>
                : <CircularProgress />);



        let innerSubtitle = this.state.optionGroups.size === 1 ? <div>Bei einer Buchung kann aus der Optionsgruppe <span
            style={{color: Config.getNeutralColor()}}>{this.state.optionGroups.first().name}</span> ausgewählt
            werden.</div>
            :
            <div>Es kann aus <span style={{color: Config.getNeutralColor()}}>{this.state.optionGroups.size} Optionsgruppen</span> gewählt
                werden.</div>

        let subtitle = <span style={{display: "flex", flexDirection: "row"}}>
                <span style={{flexGrow: 1}}>
                      {innerSubtitle}
                 </span>
            </span >

        //Können die Optionen an andere Ressourcen verteilt werden? Nur im Bearbeiten-Modus (im Editiermodus sind die Rechte noch nicht gespeichert), nur wenn der User auf mindestens eine andere Ressource Admin-Rechte hat
        let canDistribute = !this.props.editMode;
        if(canDistribute) {
            canDistribute = false;
            for (let res of this.props.model.getResourceModel().getAll()) {
                if(this.props.data !== res && res.hasAdminRights()) {
                    canDistribute = true;
                    break;
                }
            }
        }
        if(this.state.optionGroups.size > 0 || this.props.editMode) {
            return (<div>
                    <Card
                        style={{padding: 5, fontSize: 12, fontWeight: 100, background: '#FBFBFB'}}>
                        <CardHeader
                            style={{padding: 7, paddingBottom: 0}}
                            title="Optionsgruppen"
                            subheader={subtitle}
                            avatar={<AlarmIcon
                                style={{float: 'left', color: 'gray', width: 30, height: 30, marginRight: 5}}/>}
                        />

                        <CardActions disableActionSpacing>
                            {this.props.editMode && <Button color="primary" onClick={() => this.setState({
                                editOption: {},
                                editOptionMode: 'new'
                            })}>Neue Optionsgruppe</Button>}
                            {canDistribute &&
                            <Button color="secondary" onClick={() => this.setState({distributeOptions: true})}>Kopieren
                                nach</Button>}
                            <div style={{flex: '1 1 auto'}}/>
                            {this.state.optionGroups.size > 0 && <IconButton
                                className={classnames(classes.expand, {
                                    [classes.expandOpen]: this.state.expanded,
                                })}
                                onClick={() => this.setState({expanded: !this.state.expanded})}
                            >
                                <ExpandMoreIcon/>
                            </IconButton>
                            }
                        </CardActions>


                        <Collapse in={this.state.expanded} transitionduration="500" unmountOnExit>
                            <CardContent>
                                {content}
                            </CardContent>
                        </Collapse>
                    </Card>

                    {this.state.distributeOptions && <DistributeOptionsDialog onClose={() => {
                        this.setState({distributeOptions: false})
                    }} resource={this.props.data} model={this.props.model}/>}

                    {this.state.editOption !== "" && <OptionsDialog onCancel={() => {
                        this.setState({editOption: ""})
                    }} onOK={this.dialogOKClicked} onDelete={this.dialogDeleteClicked}
                                                                    optionGroup={this.state.editOption}
                                                                    mode={this.state.editOptionMode}/>}
                </div>
            )
        } else {
            return <div></div>
        }

    }
}



export default withStyles(styles)(BookingOptions);
