import React from 'react';
import {StatusBar as SB} from '@softmanufaktur/timeline';
import Config from '../config/config.js';

class StatusBar extends React.Component {
	render() {
        let userImg;
        if(this.props.data.changeUserImg) {
            userImg = Config.getBaseURL() + "/images/resourceimages/thumb/" + this.props.data.changeUserImg;
        }


		if(this.props.data.id < 0) {
            return(<div></div>)
      	  } else {
			return <SB avatarSrc={userImg} name={this.props.data.changeuser} time={this.props.data.changetime} displid={this.props.data.id}/>
       	 }

	}
}

export default StatusBar;