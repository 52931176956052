/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import React from 'react';
import Hammer from '../hammer/hammer';
import Popover from '@material-ui/core/Popover';
import LCalDateField from '../ui-components/datetimepicker/lcaldatefield';
import {findDOMNode} from 'react-dom';
import Button from '@material-ui/core/Button';
import LCal from '../calendar/lcal';


class NowButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {jumpToDate: false, jumpLCal: "__.__.", angle: 0}

        this._press = this._press.bind(this);
        this._tap = this._tap.bind(this);
        this.inputComplete = this.inputComplete.bind(this);
        this.pressTimeoutHandle = 0;
        this.onLongPress = this.onLongPress.bind(this);
        this._updateCanvas = this._updateCanvas.bind(this);
        this._clearPressTimeout = this._clearPressTimeout.bind(this);
        this.closeJumpTo = this.closeJumpTo.bind(this);
        this.ctx = undefined;
        this.domNode = null;
    }

    componentDidMount() {
        this.ctx = this.refs.controllercanvas.getContext('2d');
        this.ctx.fillStyle = "#FFFFFF";
        this.domNode = findDOMNode(this.refs.controllercanvas);
        this._updateCanvas();
    }

    componentWillUpdate(nextProps, nextState) {
        //Muss hier auf eine neue Position gezeigt werden?
    }

    componentDidUpdate() {
        this._updateCanvas();
    }


    _updateCanvas() {
        var SELF = this;
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(function () {
                SELF.paint();
            });
        } else {
            SELF.paint();
        }
    }


    _press(evt) {
        this.pressTimeoutHandle = setTimeout(() => this.onLongPress(evt), 500);
        //let cursorPos = Helper.getCursorPosition(this.refs.controllercanvas, evt);

    }

    onLongPress(evt) {
        this.setState({jumpToDate: true});
    }

    closeJumpTo() {
        this.setState({jumpToDate: false});
    }

    inputComplete() {
        if (this.state.jumpLCal instanceof LCal) {
            this.props.onJump && this.props.onJump(this.state.jumpLCal);
        }
        this.setState({jumpLCal: "__.__."});
        this.closeJumpTo();
        this.props.onClose && this.props.onClose();
    }

    _tap(evt) {
        this._clearPressTimeout();
        this.props.onJump && this.props.onJump();
    }

    _clearPressTimeout() {
        if (this.pressTimeoutHandle !== 0) {
            clearTimeout(this.pressTimeoutHandle);
            this.pressTimeoutHandle = 0;
        }
    }

    setAngle(angle) {
        this.setState({angle});
    }

    paint() {
        this.ctx.save();
        this.ctx.strokeStyle = "#000000";

        this.ctx.fillStyle = "rgba(255, 61, 0, 0.5)";

        this.ctx.beginPath();
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

        let midX = Math.round(this.ctx.canvas.width / 2);
        let midY = Math.round(this.ctx.canvas.height / 2);
        this.ctx.arc(midX, midY, midY - 1, 0, 2 * Math.PI);

        this.ctx.translate(midX, midY);
        this.ctx.rotate(this.state.angle);

        this.ctx.fill();
        this.ctx.stroke();

        this.ctx.beginPath();
        this.ctx.lineWidth = 4;
        this.ctx.strokeStyle = 'white';
        this.ctx.moveTo(-6, 6);
        this.ctx.lineTo(0, -6);
        this.ctx.lineTo(6, 6);
        this.ctx.stroke();

        this.ctx.restore();
    }


    getCanvas() {
        return this.refs.controllercanvas;
    }

    render() {
        const divStyle = {
            position: "relative",
            cursor: "pointer"
        };


        const controllerCanvasStyle = {
            position: "absolute",
            top: 0,
            left: 0
        };

        const textFieldStyle = {
            width: 170,
            marginLeft: 20
        }

        const options = {
            recognizers: {
                press: {
                    time: 0
                }
            }
        }


        return (
            <div style={divStyle}>
                <Popover
                    open={this.state.jumpToDate}
                    onClose={() => {
                        this.closeJumpTo();
                        this.props.onClose && this.props.onClose();
                    }}
                    anchorEl={this.domNode}
                >

                    <div style={{padding: 10}}>
                        <LCalDateField label="springe zu" value={this.state.jumpLCal}
                                       onChange={(d) => this.setState({jumpLCal: d})}
                                       onInputComplete={this.inputComplete} textFieldStyle={textFieldStyle}
                                       yearPositions={this.props.yearPositions}/>
                        <br/>
                        {this.props.children}
                        <br/>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Button variant="raised" onClick={this.inputComplete}>
                                OK
                            </Button>
                        </div>
                    </div>

                </Popover>

                <Hammer direction={'DIRECTION_ALL'} options={options} onPress={this._press}
                        onPressUp={this._clearPressTimeout} onTap={(evt) => this._tap(evt)}
                        style={controllerCanvasStyle}>
                    <canvas ref="controllercanvas" width={this.props.width} height={this.props.height}>
                    </canvas>
                </Hammer>
            </div>
        )
    }
}

export default NowButton
