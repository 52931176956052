/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import LCal from "../../calendar/lcal";

class LCalDurationField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            yearValue: 0,
            monthValue: 0,
            dayValue: 0,
            hourValue: 0,
            minuteValue: 0
        }
    }

    componentDidMount() {
        if (this.props.refStart instanceof LCal) {
            this.occupyDurationStates(this.props.value, this.props.refStart);
        }
    }

    componentWillReceiveProps(nextProps) {
        //Wenn der von aussen gesetzte Wert in Minuten ein anderer ist als die anhand des Status berechneten Minuten, dann die Felder ändern
        let currentDuration = this.computeDuration(this.props.refStart, this.state.yearValue, this.state.monthValue, this.state.dayValue, this.state.hourValue, this.state.minuteValue);
        if (currentDuration !== nextProps.value && nextProps.refStart) {
            this.occupyDurationStates(nextProps.value, nextProps.refStart);
        }
    }

    occupyDurationStates(duration, refStart) {
        if (refStart instanceof LCal) {
            let lcal2 = refStart.clone();
            lcal2.addMinutes(duration);
            const ymdhm = refStart.getDistanceInYMDHM(lcal2, refStart.getTimeZone());
            const durationFactor = duration < 0 ? -1 : 1;
            this.setState({
                yearValue: ymdhm[0] * durationFactor,
                monthValue: ymdhm[1] * durationFactor,
                dayValue: ymdhm[2] * durationFactor,
                hourValue: ymdhm[3] * durationFactor,
                minuteValue: ymdhm[4] * durationFactor
            });
        }
    }

    computeDuration(refStart, year, month, day, hour, minute) {
        //Auf den refStart die Zeiten aufschlagen, dann zum Original die Differenz berechnen.
        if (refStart instanceof LCal) {
            let time = refStart.clone();
            time.addYear(year);
            time.addMonth(month);
            time.addDay(day);
            time.addHour(hour);
            time.addMinutes(minute);

            let duration = refStart.getDistanceInMinutes(time);
            return duration;
        }
        return null;
    }

    durationChanged() {
        let durationMinutes = this.computeDuration(this.props.refStart, this.state.yearValue, this.state.monthValue, this.state.dayValue, this.state.hourValue, this.state.minuteValue);
        this.props.onChange && this.props.onChange(durationMinutes);
    }

    toInt(val) {
        let v = parseInt(val);
        if (Number.isInteger(v)) {
            return this.props.allowNegativeDuration ? v : Math.abs(v);
        }
        return "";
    }

    render() {
        return (
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <TextField label="Jahre" value={this.state.yearValue}
                           onChange={(evt) => this.setState({yearValue: this.toInt(evt.target.value)}, () => this.durationChanged())}/>
                <TextField label="Monate" value={this.state.monthValue}
                           onChange={(evt) => this.setState({monthValue: this.toInt(evt.target.value)}, () => this.durationChanged())}/>
                <TextField label="Tage" value={this.state.dayValue}
                           onChange={(evt) => this.setState({dayValue: this.toInt(evt.target.value)}, () => this.durationChanged())}/>
                {!this.props.hideHourMinutes && <TextField label="Stunden" value={this.state.hourValue}
                                                           onChange={(evt) => this.setState({hourValue: this.toInt(evt.target.value)}, () => this.durationChanged())}/>}
                {!this.props.hideHourMinutes && <TextField label="Minuten" value={this.state.minuteValue}
                                                           onChange={(evt) => this.setState({minuteValue: this.toInt(evt.target.value)}, () => this.durationChanged())}/>}
            </div>
        )
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 5
    },
    formControl: {
        margin: theme.spacing.unit,
    },
});


export default withStyles(styles)(LCalDurationField);
