/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import AbstractModel from './abstractmodel.js';

/**
 * Die Datenquelle für Ressourcen
 */
class ResourceModel extends AbstractModel {
    constructor(taskModel) {
        super();
        this.totalResHeight = undefined;
        this.taskModel = taskModel;
    }

    getTotalResourceHeight() {
        return this.totalResHeight;
    }

    _setDisplayDataDirty(dirty) {
        this.displDataDirty = dirty;
        //super._setDisplayDataDirty(dirty);
        //Immer, wenn die Ressourcen neu berechnet werden müssen, dann müssen auch die Tasks neu berechnet werden
        if (dirty) {
            this.taskModel._setDisplayDataDirty(dirty);
        }
    }

    recomputeDisplayData() {
            this.sort();
            //Neuberechnung der relativen Startposition der Ressourcen
            let curY = 0;
            for (let res of this.data) {
                res.getDisplayData().setRelativeYStart(curY);
                curY += res.getDisplayData().getHeight();
            }
            this._setDisplayDataDirty(false);
            this.totalResHeight = curY;
    }

    /*
     * Sortierung ist aufsteigend nach Name, dann Zweitname, dann ID
     */
    sort() {
        this.data.sort((i1, i2) => {
            let retVal = i1.getName().localeCompare(i2.getName());
            if (retVal === 0) {
                retVal = i1.secname.localeCompare(i2.secname);
                if (retVal === 0) {
                    retVal = i1.getID() - i2.getID();
                }
            }
            return retVal;
        });
    }
}

export default ResourceModel
