/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */
import {TaskModel} from '@softmanufaktur/timeline';
import BookingItem from '../data/bookingitem';
import {Map} from 'immutable';

/**
* Die Datenquelle
*/
class PixiModel extends TaskModel {
    constructor() {
        super();
        this.barSize = 110;
        this.brands = [];
        this.nextID = -1;
    }

    createBookingItem(resID, start, duration) {
        let s = start;
        s.setTimeZone("Europe/Berlin");
        let end = s.clone();
        end.setTimeZone("Europe/Berlin");
        end.addMinutes(duration);

        let bi = new BookingItem(this.nextID, s, end, resID, "", "", "", Map(), true, 0, []);
        bi.getDisplayData().setColor("#009900");
        this.nextID--;

        return bi;
    }

    setBrands(brands) {
        this.brands = brands;
    }

    getBrands() {
        return this.brands;
    }

    getBrandsOfCategory(categoryID) {
        let retVal = [];
        for(let brand of this.brands) {
            if(brand.categorykey === categoryID) {
                retVal.push(brand);
            }
        }
        return retVal;
    }

    getBrandName(brandID) {
        for(let brand of this.brands) {
            if(brand.id === brandID) {
                return brand.value;
            }
        }
        return "-";
    }
}

export default PixiModel
