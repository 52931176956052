import React from 'react';
import 'whatwg-fetch';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Config from './config/config.js';
import {ExpandableCardHeader, Helper, styles} from '@softmanufaktur/timeline';
import TransferHandler from './transfer/transferhandler.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Details from './ui-components/details';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openCard: this.props.initialCard || "login",
            loginMail: "",
            loginPass: "",
            rememberMe: false,
            dialogOpen: null,
            newUserForename: "",
            newUserSurname: "",
            newUserEmail: "",
            newUserCompany: "",
            acceptTermsOfUse: false,
            errorFields: []
        };
        this.login = this.login.bind(this);
        this.showRequestNewPassword = this.showRequestNewPassword.bind(this);
        this.requestNewPassword = this.requestNewPassword.bind(this);
        this.toggleRememberMe = this.toggleRememberMe.bind(this);
    }

    componentDidMount() {

    }

    login() {
        let encryptedPass = Helper.sha1(this.state.loginPass + this.state.loginMail.toLowerCase());

        let transferObj = {
            email: this.state.loginMail,
            pass: encryptedPass,
            rememberme: this.state.rememberMe
        }

        let successFunc = (json) => {
            //Falls "Remember me" angefordert wurde, den entsprechenden Eintrag im Storage machen, ansonsten löschen
            if (this.state.rememberMe) {
                localStorage.setItem('pixipoint_rememberme_key', json.autologinkey);
                localStorage.setItem('pixipoint_rememberme_user', this.state.loginMail);
            } else {
                localStorage.removeItem('pixipoint_rememberme_key');
                localStorage.removeItem('pixipoint_rememberme_user');
            }
            this.props.onLogIn(json.sessionid, json.email, json.forename, json.surname, json.company);
        }
        let errorFunc = (code, json) => {
            if (code === 500) {
                //Interner Serverfehler
                this.setState({dialogOpen: "INTERNAL_ERROR"});
            } else if (code === 902) {
                //User oder Passwort falsch
                this.setState({dialogOpen: "WRONG_USER_OR_PWD"});
            } else if (code === 404) {
                //User oder Passwort falsch
                this.setState({dialogOpen: "NOT_FOUND"});
            } else {
                this.setState({dialogOpen: "NETWORK_ERROR"});
            }
        }

        TransferHandler.request(Config.getLoginURL(), successFunc, errorFunc, transferObj);
    }

    showRequestNewPassword() {
        this.setState({dialogOpen: null, openCard: "lostPassword"});
    }

    requestNewPassword() {
        let transferObj = {
            email: this.state.loginMail
        }

        let successFunc = (json) => {
            //Bei Erfolg das Callback aufrufen
            this.setState({dialogOpen: "NEW_PASSWORD_SENT"});
        }
        let errorFunc = (code, json) => {
            if (code === 1) {
                //Interner Serverfehler
                this.setState({dialogOpen: "INTERNAL_ERROR"});
            } else if (code === 789) {
                this.setState({dialogOpen: "USER_NOT_FOUND"});
            } else {
                this.setState({dialogOpen: "NETWORK_ERROR"});
            }
        }

        TransferHandler.request(Config.getRequestNewPasswordURL(), successFunc, errorFunc, transferObj);
    }

    createAccount() {
        let transferObj = {
            email: this.state.newUserEmail,
            forename: this.state.newUserForename,
            surname: this.state.newUserSurname,
            company: this.state.newUserCompany
        }

        let successFunc = (json) => {
            //Bei Erfolg das Callback aufrufen
            this.setState({dialogOpen: "ACCOUNT_CREATED", newUserEmail: "", newUserForename: "", newUserSurname: "", newUserCompany: ""});
        }
        let errorFunc = (code, json) => {
            if (code === 998) {
                //Plausicheck failed
                this.setState({errorFields: json.fields});
            } else if (code === 920) {
                //Doppelter Eintrag
                this.setState({dialogOpen: "DUPLICATE_ENTRY"});
            } else if (code === 1) {
                //Interner Serverfehler
                this.setState({dialogOpen: "INTERNAL_ERROR"});
            } else {
                this.setState({dialogOpen: "NETWORK_ERROR"});
            }
        }

        TransferHandler.request(Config.getCreateAccountURL(), successFunc, errorFunc, transferObj);
    }

    closeDialog() {
        //Falls status = ACCOUNT_CREATED, dann direkt in den Anmeldedialog abspringen und den User vorbelegen
        if (this.state.dialogOpen === "ACCOUNT_CREATED") {
            this.setState({dialogOpen: null, openCard: "login", loginMail: this.state.newUserEmail});
        } else {
            this.setState({dialogOpen: null});
        }
    }

    termsOfUsage() {
        //Neue Seite öffnen und Nutzungsbedingungen anzeigen
        window.open(Config.getAGBURL(),'_blank');
    }

    dataprotection() {
        //Neue Seite öffnen und Nutzungsbedingungen anzeigen
        window.open(Config.getDataprotectionURL(),'_blank');
    }

    toggleRememberMe() {
        this.setState({rememberMe: !this.state.rememberMe});
    }

    _renderContent() {
        const styles = {
            block: {
                maxWidth: 550
            },
            buttonDiv: {
                margin: 5
            },
            paper: {
                padding: 5
            },
            contentStyle: {
                height: "90%",
                overflowY: "auto"

            },
            fatLink: {
                textDecoration: 'underline',
                fontWeight: 'bold',
                cursor: 'pointer'
            }
        };

        let dialogTitle = null;
        let dialogContent = null;
        if (this.state.dialogOpen === "INTERNAL_ERROR") {
            dialogTitle = "Fehler";
            dialogContent = (
                <Typography variant="subheading" color="inherit">Es ist ein unbekannter Fehler aufgetreten.<br/>Bitte setzen Sie sich mit einem Ansprechpartner von
                    pixipoint in Verbindung.</Typography>);
        } else if (this.state.dialogOpen === "WRONG_USER_OR_PWD") {
            dialogTitle = "Fehler beim Anmelden";
            dialogContent = (
                <Typography variant="subheading" color="inherit">Ihre E-Mailadresse oder das eingegebene Passwort sind uns nicht bekannt.<br/>Falls Sie Ihr Passwort
                    vergessen haben, so fordern Sie bitte <span style={styles.fatLink}
                                                                onClick={this.showRequestNewPassword}>hier</span> ein
                    neues an.</Typography>);
        } else if (this.state.dialogOpen === "NEW_PASSWORD_SENT") {
            dialogTitle = "Neues Passwort angefordert";
            dialogContent = (
                <Typography variant="subheading" color="inherit">Wir haben Ihnen eine E-Mail mit einem Bestätigungslink gesendet.<br/>Bitte klicken Sie diesen Link
                    an, damit wir Ihnen ein neues Passwort erstellen und zusenden können.</Typography>);
        } else if (this.state.dialogOpen === "NOT_FOUND") {
            dialogTitle = "Fehler";
            dialogContent = (
                <Typography variant="subheading" color="inherit">Das angeforderte Buchungsobjekt wurde nicht gefunden.<br/>Bitte setzen Sie sich mit einem
                    Ansprechpartner von pixipoint in Verbindung.</Typography>);
        } else if (this.state.dialogOpen === "NETWORK_ERROR") {
            dialogTitle = "Fehler";
            dialogContent = (
                <Typography variant="subheading" color="inherit">Es ist ein Fehler aufgetreten.<br/>Bitte stellen Sie eine funktionierende Internetverbindung
                    sicher.</Typography>);
        } else if (this.state.dialogOpen === "ACCOUNT_CREATED") {
            dialogTitle = "Kontoerstellung";
            dialogContent = (
                <Typography variant="subheading" color="inherit">Ihr Konto wurde erstellt.<br/>Wir haben Ihnen Ihre Anmeldedaten per E-Mail gesendet.<br/>Bitte
                    prüfen Sie eventuell auch Ihren Spam-Ordner.</Typography>);
        } else if (this.state.dialogOpen === "DUPLICATE_ENTRY") {
            dialogTitle = "Fehler";
            dialogContent = (
                <Typography variant="subheading" color="inherit">Wir konnten Ihr Konto nicht neu anlegen, da der Benutzer bereits in unserer Datenbank vorhanden
                    war.<br/>Bitte melden Sie sich mit Ihren bestehenden Daten an, oder lassen Sie sich unter "Passwort
                    vergessen" ein neues Passwort zusenden.</Typography>);
        } else if (this.state.dialogOpen === "USER_NOT_FOUND") {
            dialogTitle = "Fehler";
            dialogContent = (<Typography variant="subheading" color="inherit">Der Benutzer existiert nicht.</Typography>);
        } else {
            dialogTitle = "Fehler";
            dialogContent = (<Typography variant="subheading" color="inherit">Es ist ein unbekannter Fehler aufgetreten.</Typography>);
        }


        const actions = [
            <Button
                key="close"
                color="primary"
                keyboardFocused={true}
                onClick={() => this.closeDialog()}
            >OK</Button>
        ];

        return (<div style={styles.contentStyle}>
            <div>
                <Card>
                    <ExpandableCardHeader title="Melden Sie sich an" open={this.state.openCard === "login"}
                                          onOpen={() => this.setState({openCard: this.state.openCard === "login" ? "" : "login"})}/>

                    <Collapse in={this.state.openCard === "login"} transitionduration="500" unmountOnExit>
                        <CardContent>
                            <Paper style={styles.paper}>
                                <TextField
                                    label="E-Mail"
                                    value={this.state.loginMail}
                                    onChange={(evt) => this.setState({loginMail: evt.target.value})}
                                    fullWidth
                                />
                                <br/>
                                <TextField
                                    label="Passwort"
                                    type="password"
                                    value={this.state.loginPass}
                                    onChange={(evt) => this.setState({loginPass: evt.target.value})}
                                    fullWidth
                                />
                                <br/>
                                <br/>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={this.state.rememberMe}
                                            onChange={(evt) => this.toggleRememberMe()}
                                        />
                                    }
                                    label="angemeldet bleiben"
                                />

                                <br/>

                                <Button variant="raised" color="primary" onClick={this.login}
                                        style={styles.buttonDiv}>Anmelden</Button>
                            </Paper>
                            <br/>
                        </CardContent>
                    </Collapse>
                </Card>

                <Card>
                    <ExpandableCardHeader title="Noch kein Konto?" open={this.state.openCard === "noAccount"}
                                          onOpen={() => this.setState({openCard: this.state.openCard === "noAccount" ? "" : "noAccount"})}/>

                    <Collapse in={this.state.openCard === "noAccount"} transitionduration="500" unmountOnExit>
                        <CardContent>
                            <Paper style={styles.paper}>
                                <Typography>Erstellen Sie hier mit wenigen Angaben kostenlos Ihr neues pixipoint-Konto</Typography>
                                <br/>
                                <TextField
                                    label="Vorname"
                                    value={this.state.newUserForename}
                                    onChange={(evt) => this.setState({newUserForename: evt.target.value})}
                                    helperText={this.state.errorFields.indexOf("forename") >= 0 ? "Bitte einen Vornamen angeben" : ""}
                                    fullWidth
                                />
                                <br/>
                                <TextField
                                    label="Nachname"
                                    value={this.state.newUserSurname}
                                    onChange={(evt) => this.setState({newUserSurname: evt.target.value})}
                                    helperText={this.state.errorFields.indexOf("surname") >= 0 ? "Bitte einen Nachnamen angeben" : ""}
                                    fullWidth
                                />
                                <br/>
                                <TextField
                                    label="E-Mail"
                                    value={this.state.newUserEmail}
                                    onChange={(evt) => this.setState({newUserEmail: evt.target.value})}
                                    helperText={this.state.errorFields.indexOf("email") >= 0 ? "Bitte eine gültige E-Mailadresse angeben" : ""}
                                    fullWidth
                                />
                                <br/>
                                <TextField
                                    placeholder="z.B. Fahrschule Müller"
                                    label="Firmenname"
                                    value={this.state.newUserCompany}
                                    onChange={(evt) => this.setState({newUserCompany: evt.target.value})}
                                    helperText={this.state.errorFields.indexOf("company") >= 0 ? "Falls ein Firmenname angegeben wird, dann muss dieser mindestens drei Zeichen lang sein" : ""}
                                    fullWidth
                                />
                                <br/>

                                <br/>
                                <Button onClick={() => this.termsOfUsage()} style={{margin: 10}}>Nutzungsbedingungen</Button>
                                <Button onClick={() => this.dataprotection()} style={{margin: 10}}>Datenschutzerklärung</Button>
                                <br/>
                                <br/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={this.state.acceptTermsOfUse}
                                            onChange={() => this.setState({acceptTermsOfUse: !this.state.acceptTermsOfUse})}
                                        />
                                    }
                                    label={<span
                                        style={this.state.acceptTermsOfUse ? {color: "black"} : {color: "red"}}>Ich habe die Nutzungsbedingungen und die Datenschutzerklärung gelesen und akzeptiere diese</span>}
                                />

                                <br/>
                                <Button variant="raised" color="primary" disabled={!this.state.acceptTermsOfUse}
                                        onClick={() => this.createAccount()} style={{margin: 10}}>Konto erstellen</Button>
                            </Paper>
                        </CardContent>
                    </Collapse>
                </Card>
                <Card>
                    <ExpandableCardHeader title="Passwort vergessen?" open={this.state.openCard === "lostPassword"}
                                          onOpen={() => this.setState({openCard: this.state.openCard === "lostPassword" ? "" : "lostPassword"})}/>

                    <Collapse in={this.state.openCard === "lostPassword"} transitionduration="500" unmountOnExit>
                        <CardContent>
                            <Paper style={styles.paper}>
                                <Typography>Bitte geben Sie Ihre bei pixipoint hinterlegte E-Mail-Adresse ein</Typography>
                                <br/>
                                <TextField
                                    value={this.state.loginMail}
                                    onChange={(evt) => this.setState({loginMail: evt.target.value})}
                                    label="E-Mail"
                                    fullWidth
                                />
                                <br/>
                                <br/>
                                <Button variant="raised" color="primary" onClick={() => this.requestNewPassword()}>Neues Passwort
                                    zusenden</Button>
                            </Paper>
                        </CardContent>
                    </Collapse>
                </Card>

            </div>

            <Dialog
                open={this.state.dialogOpen !== null}
                onClose={() => this.closeDialog()}
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {dialogContent}
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>

            </Dialog>
        </div>);
    }

    render() {
        const styles = {
            outerContainer: {
                height: "100%"
            }
        };

        return (
            <div style={styles.outerContainer}>
                <Details onClose={() => this.props.onClose()} title={"Anmeldung"}>
                    {this._renderContent()}
                </Details>
            </div>
        )
    }
}

export default withStyles(styles)(Login);
