/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import React from 'react';
import Hammer from '../hammer/hammer';
import Helper from '../helper/helper.js'


class Slider extends React.Component {
    constructor(props) {
        super(props);

        this.XPADDING = 35;

        this.horizontalOrientation = true;

        this._pan = this._pan.bind(this);
        this._press = this._press.bind(this);
        this._updateCanvas = this._updateCanvas.bind(this);
        this._updateControllerCanvas = this._updateControllerCanvas.bind(this);
        this.paint = this.paint.bind(this);

        this.ctx = undefined;
        this.ctrctx = undefined;

        this.sliderVal2Percentage = null;

        this.buildSliderValue2Percentage(props.sliderValues);

        this.state = {controllerX: 0}
    }

    componentDidMount() {
        this.ctx = this.refs.slidercanvas.getContext('2d');
        this.ctx.fillStyle = "#FFFFFF";

        this.ctrctx = this.refs.controllercanvas.getContext('2d');
        this.setControllerValue(this.props.controllerValue);
        this._updateCanvas();
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.props.width !== nextProps.width) {
            this.setState({controllerX: Math.round(this.state.controllerX * nextProps.width / this.props.width)});
        }
    }

    componentDidUpdate() {
        this._updateCanvas();
    }

    componentWillReceiveProps(nextProps) {
        this.buildSliderValue2Percentage(nextProps.sliderValues);
        this.setControllerValue(nextProps.controllerValue);
    }

    buildSliderValue2Percentage(sliderValues) {
        this.sliderVal2Percentage = new Map();
        let stepWidth = 100 / (sliderValues.length - 1);
        for (let i = 0; i < sliderValues.length; i++) {
            let sv = sliderValues[i];
            this.sliderVal2Percentage.set(sv.value, Math.round(stepWidth * i));
        }
    }

    _updateCanvas() {
        var SELF = this;
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(function () {
                SELF.paint();
            });
        } else {
            SELF.paint();
        }
    }

    _updateControllerCanvas() {
        var SELF = this;
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(function () {
                // SELF.paintController();
                SELF.paint(); //Komplettzeichnen notwendig, damit auch die Beschriftung geändert wird
            });
        } else {
            SELF.paint();
        }
    }

    _pan(evt) {
        let cursorPos = Helper.getCursorPosition(this.refs.slidercanvas, evt);
        this._setControllerX(cursorPos[0] - this.XPADDING);
        //this._updateControllerCanvas();
        this.fireZoomCallback();
    }

    _press(evt) {
        let cursorPos = Helper.getCursorPosition(this.refs.slidercanvas, evt);
        this._setControllerX(cursorPos[0] - this.XPADDING);
        //this._updateControllerCanvas();
        this.fireZoomCallback();
    }

    _setControllerX(x) {
        if (x < 0) {
            this.setState({controllerX: 0});
        } else if (x > (this.ctx.canvas.width - 2 * this.XPADDING)) {
            this.setState({controllerX: this.ctx.canvas.width - 2 * this.XPADDING});
        } else {
            this.setState({controllerX: x});
        }
    }

    fireZoomCallback() {
        this.props.onChange && this.props.onChange(this.getControllerValue());
    }


    getControllerValue() {
        //Zwischen welchen beiden Slidervalues befindet sich der Controller? -> Dazwischen linear rechnen
        let minVal = undefined;
        let maxVal = undefined;
        let minValX = undefined;
        let maxValX = undefined;
        let absMinVal = undefined;
        let absMaxVal = undefined;
        for (let val of this.props.sliderValues) {
            let xpos = this.getXPosForSliderValue(val);
            if (this.state.controllerX >= xpos && (minValX === undefined || minValX < xpos)) {
                minVal = val;
                minValX = xpos;
            } else if (this.state.controllerX <= xpos && (maxValX === undefined || maxValX > xpos)) {
                maxVal = val;
                maxValX = xpos;
            }
            if (!absMinVal || absMinVal > val) {
                absMinVal = val;
            }
            if (!absMaxVal || absMaxVal < val) {
                absMaxVal = val;
            }
        }
        if (!minVal) {
            minVal = absMinVal;
            minValX = this.getXPosForSliderValue(minVal);
        }
        if (!maxVal) {
            maxVal = absMaxVal;
            maxValX = this.getXPosForSliderValue(maxVal);
        }

        if (minVal !== undefined && maxVal !== undefined) {
            let retVal = minVal.value + ((this.state.controllerX - minValX) / (maxValX - minValX)) * (maxVal.value - minVal.value);
            return retVal;
        } else {
            return undefined;
        }
    }

    setControllerValue(minutes) {
        //Zwischen welchen beiden Slidervalues befindet sich der Wert, den der Controller annehmen soll? -> Dazwischen linear rechnen
        let minVal = undefined;
        let maxVal = undefined;
        for (let val of this.props.sliderValues) {
            if (minutes >= val.value && (minVal === undefined || val.value >= minVal.value)) {
                minVal = val;
            }
            if (minutes < val.value && (maxVal === undefined || val.value < maxVal.value)) {
                maxVal = val;
            }
        }
        if (minVal !== undefined && maxVal !== undefined) {
            let minValX = this.getXPosForSliderValue(minVal);
            let maxValX = this.getXPosForSliderValue(maxVal);

            this.setState({controllerX: minValX + (minutes - minVal.value) / (maxVal.value - minVal.value) * (maxValX - minValX)});
        } else if (minVal !== undefined) {
            this.setState({controllerX: this.getXPosForSliderValue(minVal)});
        } else if (maxVal !== undefined) {
            this.setState({controllerX: this.getXPosForSliderValue(maxVal)});
        }
        this._updateControllerCanvas();
    }

    getXPosForSliderValue(val) {
        return this.sliderVal2Percentage.get(val.value) * (this.ctx.canvas.width - 2 * this.XPADDING) / 100;
    }

    setHorizontalOrientation(isHorizontal) {
        this.horizontalOrientation = isHorizontal;
    }

    isHorizontalOrientation() {
        return this.horizontalOrientation;
    }

    paint() {
        this.ctx.font = "12px Roboto, sans-serif";

        this.ctx.clearRect(0, 0, this.horizontalOrientation ? this.ctx.canvas.width : this.ctx.canvas.height, this.horizontalOrientation ? this.ctx.canvas.height : this.ctx.canvas.width);
        this.ctx.fillStyle = "#FFFFFF";
        this.ctx.strokeStyle = "#AAAAAA";

        this.ctx.beginPath();
        this.ctx.rect(this.XPADDING, this.props.height - 20, this.props.width - 2 * this.XPADDING, 4);
        this.ctx.fill();


        //Die Sliderwerte zeichnen
        /*for(var val of this.props.sliderValues) {
          let xpos = this.XPADDING + this.sliderVal2Percentage.get(val) * (this.ctx.canvas.width - 2 * this.XPADDING) / 100;
          //Je weiter die aktuelle x-Position des Sliders entfernt ist, desto weiter nach unten rückt die Beschriftung
          let ypos = Math.abs(this.state.controllerX - xpos +this.XPADDING) > 20 ? ypos = 30: ypos = 15;

          var w = this.ctx.measureText(val.name).width;
          this.ctx.rect(Math.round(xpos - w/2 - 2), ypos, w + 4, 13);
        }*/

        this.ctx.fill();
        this.ctx.stroke();


        let lastTextEndLow = -1000;
        let lastTextEndHigh = -1000;
        for (let val of this.props.sliderValues) {
            let xpos = this.XPADDING + this.sliderVal2Percentage.get(val.value) * (this.ctx.canvas.width - 2 * this.XPADDING) / 100;
            let ypos = Math.abs(this.state.controllerX - xpos + this.XPADDING) > 20 ? 40 : 25;
            let w = Helper.textWidthFromCache(val.name, this.ctx);//this.ctx.measureText(val.name).width;
            let txtStart = Math.round(xpos - w / 2);
            if (txtStart > lastTextEndLow + 5) {
                if (ypos === 40) {
                    this.ctx.fillStyle = "#AAA";
                    this.ctx.fillText(val.name, txtStart, ypos);
                }
                lastTextEndLow = txtStart + w;
            }
            if (ypos === 25 && txtStart > lastTextEndHigh + 5) {
                //Je weiter die aktuelle x-Position des Sliders entfernt ist, desto weiter nach unten rückt die Beschriftung
                this.ctx.fillStyle = "#000";
                this.ctx.fillText(val.name, txtStart, ypos);
                lastTextEndHigh = txtStart + w;
            }

        }

        this.paintController();
    }

    //Zeichnet nur den beweglichen Slider
    paintController() {
        this.ctrctx.strokeStyle = "#000000";
        this.ctrctx.fillStyle = "#DDDDDD";

        this.ctrctx.beginPath();
        this.ctrctx.clearRect(0, 0, this.horizontalOrientation ? this.ctrctx.canvas.width : this.ctrctx.canvas.height, this.horizontalOrientation ? this.ctrctx.canvas.height : this.ctrctx.canvas.width);

        let ctrlHeight = 36;
        let top = this.props.height - ctrlHeight;
        this.ctrctx.moveTo(this.XPADDING + this.state.controllerX - 10, top + 10);
        this.ctrctx.lineTo(this.XPADDING + this.state.controllerX - 10, top + ctrlHeight);
        this.ctrctx.lineTo(this.XPADDING + this.state.controllerX + 10, top + ctrlHeight);
        this.ctrctx.lineTo(this.XPADDING + this.state.controllerX + 10, top + 10);
        this.ctrctx.lineTo(this.XPADDING + this.state.controllerX, top);
        this.ctrctx.closePath();

        this.ctrctx.fill();
        this.ctrctx.stroke();
    }

    render() {
        var divStyle = {
            position: "relative",
            cursor: "pointer"
        };

        var controllerCanvasStyle = {
            position: "absolute",
            top: 0,
            left: 0
        };

        var options = {
            recognizers: {
                press: {
                    time: 0
                }
            }
        }

        return <div style={divStyle}>
            <canvas ref="slidercanvas" width={this.props.width} height={this.props.height}>
            </canvas>

            <Hammer direction={'DIRECTION_ALL'} options={options} onPan={this._pan} onPress={this._press}
                    style={controllerCanvasStyle}>
                <canvas ref="controllercanvas" width={this.props.width} height={this.props.height}
                        style={{cursor: "pointer"}}>
                </canvas>
            </Hammer>

        </div>
    }
}

export default Slider
