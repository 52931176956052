import React from 'react';
import 'whatwg-fetch';
import Config from './config/config.js';
import Details from './ui-components/details';
import Typography from '@material-ui/core/Typography';

let helpContent = "";

class Impressum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {contentLoaded: false};
  }

  componentDidMount() {
	    let SELF = this;
	    if(helpContent === "") {
		    fetch(Config.getImpressumURL()).then(function(response) {
		      response.text().then(function(data) {
		    	  helpContent = data;
		          SELF.setState({contentLoaded: true});
		        }
		      );
		    });
	    }
   }

  render() {
    const styles = {
			  outerContainer: {
				  height: "100%"
			  },
			  contentStyle: {
	    		    height: "90%",
	    		    overflowY: "auto",
				  	padding: 10
			  }
	  };
	  
    function createMarkup() {
      return {__html: helpContent};
    };

    return (
        <Details onClose={()=>this.props.onClose()} title="Impressum">
			<Typography variant="body1" color="inherit">
						<span key = "help" dangerouslySetInnerHTML={createMarkup()} style={styles.contentStyle} />
			</Typography>
		</Details>
    )
  }

  
}

export default Impressum;
