/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 * --------------------------------------------------------
 * Die Daten, die für die Anzeige der Ressource gebraucht werden
 * Diese Daten werden nicht mit den Ressourcendaten gespeichert, sondern allenfalls profilabhängig
*/
import DisplayData from './displaydata.js'

class TaskDisplayData extends DisplayData {
    constructor() {
        super();
        this.color = "#DDD";
        this.labelColor = "#FFF";
        this.secLabelColor = "#CCC";
        this.isShadow = false;
        this.level = 0;
    }

    clone() {
        let t = new TaskDisplayData();
        t.height = this.height;
        t.relYStart = this.relYStart;
        t.color = this.color;
        t.labelColor = this.labelColor;
        t.secLabelColor = this.secLabelColor;
        t.isShadow = this.isShadow;
        t.level = this.level;
        return t;
    }

    getLabelColor() {
        return this.labelColor;
    }

    setLabelColor(value) {
        this.labelColor = value;
    }

    getSecLabelColor() {
        return this.secLabelColor;
    }

    setSecLabelColor(value) {
        this.secLabelColor = value;
    }

    getColor() {
        return this.color;
    }

    setColor(color) {
        this.color = color;
    }

    setIsShadowTask(b) {
        this.isShadow = b;
    }

    isShadowTask() {
        return this.isShadow;
    }

    setLevel(level) {
        this.level = level;
    }

    getLevel() {
        return this.level;
    }
}

export default TaskDisplayData
