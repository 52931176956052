import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const path = "M 20,17 19.93687,13.967323 C 9.5083667,13.969458 16.549034,14 4,14 l 0,3 c 0,1.1 0.9,2 2,2 l 2,0 c 0,1.66 1.34,3 3,3 1.66,0 3,-1.34 3,-3 l 8,0 0,-2 z m -9,3 c -0.55,0 -1,-0.45 -1,-1 0,-0.55 0.45,-1 1,-1 0.55,0 1,0.45 1,1 0,0.55 -0.45,1 -1,1 z";

const CarTrailer = (props) => (
		  <SvgIcon {...props}>
		    <path d={path} />
		  </SvgIcon>
);

export default CarTrailer;
export {path};