/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import React from 'react';
import LCalFormatter from '../calendar/lcalformatter.js';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

class DateTimeBlock extends React.Component {
    render() {
        return this.props.lcal ?
            <Paper style={{
                display: "flex",
                flexDirection: "column",
                flex: "0 0 50%",
            }}>
                <div style={{margin: 1, padding: 7, flex: "1 1 auto"}}>
                    {LCalFormatter.formatType(this.props.lcal) &&
                    <Typography style={{color: "gray"}}>
                        {LCalFormatter.formatType(this.props.lcal)}
                    </Typography>
                    }
                    <div style={{display: "flex", justifyContent: "center"}}>
                        {this.props.lcal.precision > 10 && //Mehr als monatsgenau (=tagesgenau und genauer) -> Datum mit anzeigen
                        <div style={{marginRight: 8}}>
                            <Typography style={{fontSize: 30}}>
                                {LCalFormatter.formatDay(this.props.lcal)}.
                            </Typography>
                        </div>
                        }
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div>
                                <Typography>
                                    {(this.props.lcal.precision > 9 ? (LCalFormatter.formatMonthNameL(this.props.lcal) + " ") : "") + LCalFormatter.formatYear(this.props.lcal) //Mehr als jahresgenau (=monatsgenau und genauer) -> Wochentag mit anzeigen
                                    }
                                </Typography>
                            </div>
                            <div>
                                {this.props.lcal.precision > 10 && //Mehr als monatsgenau (=tagesgenau und genauer) -> Wochentag mit anzeigen
                                <Typography style={{color: "gray"}}>
                                    {LCalFormatter.formatDayNameL(this.props.lcal)}
                                </Typography>}
                            </div>
                        </div>
                    </div>
                    {this.props.lcal.precision > 11 && //Mehr als tagesgenau (=stundengenau und besser) -> Uhrzeit mit anzeigen
                    <div style={{fontSize: 20}}>
                        <hr style={{margin: "4px 0", padding: 0}}/>
                        <Typography>
                            {LCalFormatter.formatTime(this.props.lcal) + " Uhr"}
                        </Typography>
                    </div>
                    }
                </div>
            </Paper>
            :
            <Paper style={{display: "flex", flex: "0 0 50%", justifyContent: "center"}}>
                <div style={{
                    padding: 7,
                    margin: 1,
                    flex: "1 1 auto",
                    justifyContent: "center"
                }}>

                </div>
            </Paper>

    }
}

export default DateTimeBlock
