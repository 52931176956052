import React from 'react';
import MaskedInput from '../mymaskedfield';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import LCal from '../..//calendar/lcal.js';
import ReactDOM from 'react-dom';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
    },
});

function getTextMaskCustom(props, precision, SELF) {
    const {inputRef, ...other} = props;

    //Stundengenau (12) > nur Stunden anzeigen.
    const mask = precision === 12 ? "11" : "11:11";

    return (
        <MaskedInput
            {...other}
            mask={mask}
            name="time"
            size={5}
            ref={(r) => SELF.tmRef = r}
        />
    );
}

class FormattedInputs extends React.Component {
    constructor(props) {
        super(props);
        this.textMaskCustomFunc = (props) => getTextMaskCustom(props, this.props.precision, this);
        this.tmRef = null;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.precision !== this.props.precision) {
            //Die Funktion muss auf diese Weise erneuert werden. Wird sie nicht erneuert, dann kommt es zu komischen Effekten bei der Mask.
            this.textMaskCustomFunc = (props) => getTextMaskCustom(props, this.props.precision, this);
        }
    }

    componentDidMount() {
        if (this.props.focusableCB) {
            this.props.focusableCB(this);
        }
    }

    getStringValue(val) {
        if (val instanceof LCal) {
            let datestring = ("0" + val.getHour()).slice(-2) + ":" + ("0" + val.getMinute()).slice(-2);
            return datestring;
        }
        return val;
    }

    /**
     * Liefert ein LCal zu einem Zeitstring mit heutigem Datum, oder den Zeitstring, falls kein LCal erzeugt werden konnte
     */
    getLCal(dateStr) {
        let arr = dateStr.split(":");
        if(this.props.precision === 12) {
            arr = (dateStr+":00").split(":");
        }

        if (arr.length === 2 && arr[0].length === 2 && arr[1].length === 2) {
            let hour = parseInt(arr[0]);
            if (!isNaN(hour) && hour >= 0 && hour < 24) {
                let minute = parseInt(arr[1]);
                if (!isNaN(minute) && minute >= 0 && minute < 60) {
                    var today = new Date();
                    let lcal = new LCal().initYMDHM(today.getFullYear(), today.getMonth() + 1, today.getDate(), hour, minute, "Europe/Berlin");

                    //Die eingegebene Zeichenkette und die aus dem LCal erstellte müssen identisch sein
                    let datestring;
                    if(this.props.precision === 12) {
                        datestring = ("0" + lcal.getHour()).slice(-2);
                    } else {
                        datestring = ("0" + lcal.getHour()).slice(-2) + ":" + ("0" + lcal.getMinute()).slice(-2);
                    }
                    if (datestring === dateStr) {
                        return lcal;
                    }
                }
            }
        }
        return dateStr;
    }

    handleChange(event) {
        if (this.props.onChange) {
            this.props.onChange(this.getLCal(event.target.value));
        }
    };

    focus() {
        //console.log("Focus me");
        ReactDOM.findDOMNode(this.tmRef).focus()
        /*setTimeout(()=>
        ReactDOM.findDOMNode(tmRef).focus(), 500);*/
    }



    render() {
        const {classes} = this.props;
        //vor 10000 wird der Tag mit angezeigt, vor 100000 wird das Datum mit angezeigt
        return (
            <FormControl className={classes.formControl} style={{margin: 1, padding: 0}}>
                <InputLabel htmlFor="formatted-text-mask-input">{""}</InputLabel>
                <Input
                    value={this.getStringValue(this.props.value)}
                    onChange={(evt) => this.handleChange(evt)}
                    id="formatted-text-mask-input"
                    inputComponent={this.textMaskCustomFunc}
                    onKeyPress={(ev) => {
                        if (this.props.onInputComplete && ev.key === 'Enter') {
                            this.props.onInputComplete();
                        }
                    }}
                    disableUnderline={true}
                    ref="input"
                />
            </FormControl>
        );
    }
}

FormattedInputs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormattedInputs);
