import {Resource} from '@softmanufaktur/timeline';

/**
 *
 */
class BaseResource extends Resource {
    constructor(id, name, secname, deleted, desc, isAdmin, bookingdeadline, bookinghorizont, changeuser, userImgSrc, changetime, imgSrc) {
        super(id, name, secname, deleted);
        this.isAdmin = isAdmin;
        this.bookingdeadline = (bookingdeadline || bookingdeadline === 0) ? bookingdeadline : 24;
        this.bookinghorizont = bookinghorizont || 30;
        this.changeuser = changeuser;
        this.changetime = changetime;
        this.members = null;
        this.bookingOptions = null;
        this.imgSrc = imgSrc;
        this.changeUserImg = userImgSrc;
        this.description = desc;
    }

    getMarkingColor() {
        return this.isAdmin ? "#EE0000" : null;
    }

    setBookingDeadline(m) {
        this.bookingdeadline = m;
    }

    getBookingDeadline() {
        return this.bookingdeadline;
    }

    setBookingHorizont(m) {
        this.bookinghorizont = m;
    }

    getBookingHorizont() {
        return this.bookinghorizont;
    }

    setMembers(members) {
        this.members = members;
    }

    getMembers() {
        return this.members;
    }

    setBookingOptions(opts) {
        this.bookingOptions = opts;
    }

    getBookingOptions() {
        return this.bookingOptions;
    }

    getChangeUserName() {
        return this.changeuser;
    }

    getChangeTime() {
        return this.changetime;
    }

    setImgSrc(s) {
        this.imgSrc = s
    }

    getImgSrc() {
        return this.imgSrc;
    }

    getChangeUserImg() {
        return this.changeUserImg;
    }

    hasAdminRights() {
        return this.isAdmin;
    }

    setDescription(desc) {
        this.description = desc;
    }

    getDescription() {
        return this.description;
    }
}



export default BaseResource;