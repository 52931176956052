import React from 'react';
import 'whatwg-fetch';
import Config from '../config/config.js';
import NewsEntry from './newsentry.js';
import NewsItem from './newsitem.js';
import TransferHandler from '../transfer/transferhandler.js';
import Details from '../ui-components/details';
import NewsDialog from "./newsdialog";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {mailEntries: [], selectedItemID: null};
  }
  
  componentDidMount() {
	    let successFunc = function(json) {
	    	let mailEntries = [];
	    	
	    	//Für jeden MailEntry ein Objekt erzeugen und zufügen
	        for(let jsonEvt of json.entries) {
	          let entry = new NewsEntry(jsonEvt.id, jsonEvt.subject, jsonEvt.creation_date, jsonEvt.isread);	         
	          
	          mailEntries.push(entry);
	        }
	        
	        this.setState({mailEntries});
	    }.bind(this);
	    
	  	TransferHandler.request(Config.getNewsListURL(), successFunc);
  }

  newsTapped(newsEntry, index) {
	  //Der Eintrag wird sofort als gelesen gekennzeichnet, obwohl er erst ein paar Momente später auf dem Server als gelesen markiert wird
	  newsEntry.isread = true;
	  //Für diesen Newsentry nun die Details setzen (Status, der dann von NewsDetail genommen wird)
	  this.setState({selectedItemID: newsEntry ? newsEntry.id : null});
  }

  //<NewsDetail height={(window.innerHeight - 55)/2} itemID={this.state.selectedItemID}/>
 render() {
    return (
		<Details onClose={()=>this.props.onClose()} title="Nachrichten">
						<div>
							{
								this.state.mailEntries.map((news, index) => {
									return <NewsItem key={index} entry={news} onClick={()=>this.newsTapped(news, index)} selected={this.state.selectedItemID===news.id}/>
								})
							}
						</div>
			{this.state.selectedItemID !== null && <NewsDialog itemID={this.state.selectedItemID} onClose={()=>this.setState({selectedItemID: null})}/>}
    	</Details>
    )
  }
}

export default News;
