import React from "react";
import Typography from '@material-ui/core/Typography';

const NewsItem = ({entry, onClick, selected}) =>
    <div onClick={() => onClick()} style={{
        color: entry.isread ? "lightgray" : "black",
        cursor: "pointer",
        margin: 5,
        padding: 10,
        border: selected ? "2px solid black" : "1px solid lightgray"
    }}>
        <div>
           <Typography style={{color: entry.isread ? "black" : "#0067b0", fontWeight: entry.isread ? "normal" : "bold"}}>{entry.subject}</Typography>
        </div>
        <Typography variant="caption">{entry.creationDate}</Typography>
    </div>;

export default NewsItem;