/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import React from 'react';
import DateTimeBlock from './datetimeblock';
import LCalFormatter from '../calendar/lcalformatter';
import LCalInterval from '../calendar/lcalinterval';
import LCalHelper from "../calendar/lcalhelper";
import LCal from "../calendar/lcal";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

class DateTimeIntervalBlock extends React.Component {
    constructor(props) {
        super(props)
        this.state = {durationPanelVisible: false}
    }
    render() {
        let durationStr = "";
        let secondsDurationStr = "";
        let minuteDurationStr = "";
        let hourDurationStr = "";
        let dayDurationStr = "";
        if (this.props.interval.start && this.props.interval.end) {
            durationStr = LCalFormatter.formatDuration(this.props.interval);
            secondsDurationStr = (this.props.interval.getAbsDurationMinutesConsiderPrecision() * 60) + " Sekunden";
            minuteDurationStr = this.props.interval.getAbsDurationMinutesConsiderPrecision() + " Minuten";
            hourDurationStr = Math.round(this.props.interval.getAbsDurationMinutesConsiderPrecision() / 6) / 10 + " Stunden";
            dayDurationStr = Math.round(this.props.interval.getAbsDurationMinutesConsiderPrecision() / 6 / 24) / 10 + " Tage";
        } else if (this.props.interval.start) {
            //Ende ist nicht gegeben -> Wenn start vor heute liegt, dann ist das Ende heute, ansonsten ist die Dauer unbekannt
            let now = new LCal().setJulianMinutes(LCalHelper.getNowMinutes());
            if (!this.props.interval.start.after(now)) {
                let interval = new LCalInterval(this.props.interval.start, now);
                durationStr = LCalFormatter.formatDuration(interval) + " bis jetzt";
                secondsDurationStr = (interval.getAbsDurationMinutesConsiderPrecision() * 60) + " Sekunden";
                minuteDurationStr = interval.getAbsDurationMinutesConsiderPrecision() + " Minuten bis jetzt";
                hourDurationStr = Math.round(interval.getAbsDurationMinutesConsiderPrecision() / 60) + " Stunden bis jetzt";
                dayDurationStr = Math.round(interval.getAbsDurationMinutesConsiderPrecision() / 60 / 24) + " Tage bis jetzt";
            } else {
                durationStr = "unbekannte Dauer";
            }
        } else if (this.props.interval.end) {
            //Start ist nicht gegeben -> Wenn ende nach heute liegt, dann ist der Start heute, ansonsten ist die Dauer unbekannt
            let now = new LCal().setJulianMinutes(LCalHelper.getNowMinutes());
            if (!this.props.interval.end.before(now)) {
                let interval = new LCalInterval(now, this.props.interval.end);
                durationStr = LCalFormatter.formatDuration(interval) + " ab jetzt";
                secondsDurationStr = (interval.getAbsDurationMinutesConsiderPrecision() * 60) + " Sekunden ab jetzt";
                minuteDurationStr = interval.getAbsDurationMinutesConsiderPrecision() + " Minuten ab jetzt";
                hourDurationStr = Math.round(interval.getAbsDurationMinutesConsiderPrecision() / 60) + " Stunden ab jetzt";
                dayDurationStr = Math.round(interval.getAbsDurationMinutesConsiderPrecision() / 60 / 24) + " Tage ab jetzt";
            } else {
                durationStr = "unbekannte Dauer";
            }
        }

        const pointInTime = this.props.interval.start && this.props.interval.start.type === 585;
        return (
            pointInTime ? <DateTimeBlock lcal={this.props.interval.start}/>
                : <React.Fragment>
                <div style={{display: "flex"}}>
                    <DateTimeBlock lcal={this.props.interval.start}/>
                    <DateTimeBlock lcal={this.props.interval.end}/>
                </div>
                <div style={{
                    padding: "5px 1px",
                    margin: 1
                }}>
                    <ExpansionPanel style={{
                        textAlign: "center"
                    }}
                                    expanded={this.state.durationPanelVisible}
                                    onChange={() => {
                        this.setState({durationPanelVisible: !this.state.durationPanelVisible})
                    }} CollapseProps={{timeout: 500}}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} >
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%"
                            }}>
                            <Typography variant="subheading" gutterBottom >
                                {this.props.defaultDurationType==="minutes" ? minuteDurationStr : null}
                                {this.props.defaultDurationType!=="minutes" ? durationStr : null}
                            </Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{display: 'flex', flexDirection: "column", justifyContent: 'center'}}>
                            <Typography>= {dayDurationStr}</Typography>
                            <Typography>= {hourDurationStr}</Typography>
                            {this.props.defaultDurationType!=="minutes" && <Typography>= {minuteDurationStr}</Typography>}
                            <Typography>= {secondsDurationStr}</Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </React.Fragment>
        )
    }
}

export default DateTimeIntervalBlock
