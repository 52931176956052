/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ActionToday from '@material-ui/icons/Today';
import DateTimePickerDlg from './datetimepickerdialog';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import LCalDateField from './lcaldatefield';
import LCalTimeField from './lcaltimefield';
import LCalPrecisionField from './lcalprecisionfield';
import LCalTypeField from './lcaltypefield';
import LCal from '../../calendar/lcal';
import LCalHelper from '../../calendar/lcalhelper';
import LCalFormatter from '../../calendar/lcalformatter';

class LCalDateTimeField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateValue: this.props.value instanceof LCal ? this.props.value.clone() : "__.__.",
            timeValue: this.props.value instanceof LCal ? this.props.value.clone() : "__:__",
            precision: this.props.value instanceof LCal ? this.props.value.precision : 11,
            type: this.props.value instanceof LCal ? ((this.props.value.type===585 && this.props.isEndType) ? 582 : this.props.value.type) : (this.props.isEndType ? 582 : 580),
            dlgOpen: false
        }

        this.focusableTimeField = null;
    }

    fieldChanged(newVal) {
        this.props.onChange && this.props.onChange(newVal);
    }

    componentWillReceiveProps(nextProps) {
        //Wenn die Zeit, die aus dem State gebildet werden kann, die gleiche ist, wie die Zeit, die hereinkommt, dann nichts ändern
        let shouldUpdateTime = true;
        if (this.state.dateValue instanceof LCal && this.state.timeValue instanceof LCal) {
            let referenceLCal = new LCal().initYMDHM(this.state.dateValue.getYear(), this.state.dateValue.getMonth(), this.state.dateValue.getDay(), this.state.timeValue.getHour(), this.state.timeValue.getMinute(), "Europe/Berlin", this.state.precision, this.state.type);
            if (nextProps.value instanceof LCal && nextProps.value.getJulianMinutes() === referenceLCal.getJulianMinutes() && nextProps.value.type === referenceLCal.getType()) {
                shouldUpdateTime = false;
            }
        }
        if (!(nextProps.value instanceof LCal)) { //Wenn das was reinkommt kein LCal ist, z.B. null, dann bleibt alles so stehen, wie es ist
            shouldUpdateTime = false;
        }

        if (shouldUpdateTime) {
            this.setState({
                dateValue: nextProps.value instanceof LCal ? nextProps.value.clone() : "__.__.",
                timeValue: nextProps.value instanceof LCal ? nextProps.value.clone() : "__:__",
                precision: nextProps.value instanceof LCal ? nextProps.value.precision : 11,
                type: nextProps.value instanceof LCal ? ((nextProps.value.type===585 && nextProps.isEndType) ? 582 : nextProps.value.type) : (nextProps.isEndType ? 582 : 580),
            });
        }
    }

    dateChanged(d) {
        //Falls d und timeValue ein LCal sind, dann kann das Ergebnis auch ein LCal sein
        const f = () => {
            if (d instanceof LCal && this.state.timeValue instanceof LCal) {
                let retVal = new LCal().initYMDHM(d.getYear(), d.getMonth(), d.getDay(), this.state.timeValue.getHour(), this.state.timeValue.getMinute(), "Europe/Berlin", this.state.precision, this.state.type);
                this.fieldChanged(retVal);
            } else {
                this.fieldChanged(null);
            }
        }

        if(d != null) {
            if (this.state.precision <= 12 && d instanceof LCal && !(this.state.timeValue instanceof LCal)) {
                let time = d.clone();
                this.setState({dateValue: d, timeValue: time}, f);
            } else {
                this.setState({dateValue: d}, f);
            }
        } else {
            f();
        }
    }

    timeChanged(t) {
        const f = () => {
            if (t instanceof LCal && this.state.dateValue instanceof LCal) {
                let retVal = new LCal().initYMDHM(this.state.dateValue.getYear(), this.state.dateValue.getMonth(), this.state.dateValue.getDay(), t.getHour(), t.getMinute(), "Europe/Berlin", this.state.precision, this.state.type);
                this.fieldChanged(retVal);
            } else {
                this.fieldChanged(null);
            }
        }
        this.setState({timeValue: t}, f);
    }

    precisionChanged(val) {
        //Falls die precision sich geändert hat, so dass das Zeitfeld nicht mehr angezeigt wird, aber es keinen LCal für das Zeitfeld gibt, dann ausnullen
        const f = () => {
            if (this.state.timeValue instanceof LCal && this.state.dateValue instanceof LCal) {
                let retVal = new LCal().initYMDHM(this.state.dateValue.getYear(), this.state.dateValue.getMonth(), this.state.dateValue.getDay(), this.state.timeValue.getHour(), this.state.timeValue.getMinute(), "Europe/Berlin", val, this.state.type);
                this.fieldChanged(retVal);
            } else {
                this.fieldChanged(null);
            }
        }
        if (val <= 12 && this.state.dateValue instanceof LCal && !(this.state.timeValue instanceof LCal)) {
            let time = this.state.dateValue.clone();

            this.setState({precision: val, timeValue: time}, f);
        } else {
            this.setState({precision: val}, f);
        }
    }

    typeChanged(val) {
        const f = () => {
            if (this.state.timeValue instanceof LCal && this.state.dateValue instanceof LCal) {
                let retVal = new LCal().initYMDHM(this.state.dateValue.getYear(), this.state.dateValue.getMonth(), this.state.dateValue.getDay(), this.state.timeValue.getHour(), this.state.timeValue.getMinute(), "Europe/Berlin", this.state.precision, this.state.type);
                this.fieldChanged(retVal);
            } else {
                this.fieldChanged(null);
            }
            this.props.onTypeChange && this.props.onTypeChange(this.state.type);
        }
        this.setState({type: val}, f);
    }

    dateTimeChanged(d) {
        const f = () => {
            if (d instanceof LCal) {
                d.getYear();
                let retVal = new LCal().initYMDHM(d.getYear(), d.getMonth(), d.getDay(), d.getHour(), d.getMinute(), d.getTimeZone(), d.getPrecision(), d.getType());
                this.fieldChanged(retVal);
            } else {
                this.fieldChanged(null);
            }
        }
        this.setState({timeValue: d, dateValue: d}, f);
    }

    render() {
        const classes = this.props.classes;
        let date = new Date();
        if (this.props.value instanceof LCal) {
            date = this.props.value.getJSDate();
        }

        return (
            <div style={{border: "1px solid gray", borderRadius: 5, overflow: "hidden"}}>
                {(this.props.showTypeField || this.props.showPrecisionField) &&
                <div className={classes.container} style={{ justifyContent: "space-between"}}>
                    <LCalTypeField isEndType={this.props.isEndType}
                                   type={this.state.type}
                                   onTypeChange={(val) => this.typeChanged(val)}/>
                    <div style={{marginRight: 5}}>
                        <LCalPrecisionField precision={this.state.precision}
                                            onPrecisionChange={(val) => this.precisionChanged(val)}/>
                    </div>
                </div>
                }
                <div>
                    <div className={classes.container}>
                        <LCalDateField label={this.props.label}
                                       value={this.state.dateValue}
                                       onChange={a => this.dateChanged(a)}
                                       onInputComplete={() => this.focusableTimeField && this.focusableTimeField.focus()}
                                       yearPositions={this.props.yearPositions ? this.props.yearPositions : 4}
                                       precision={this.state.precision}
                        />

                        {this.state.precision >= 12 &&
                        <LCalTimeField focusableCB={(focusable) => this.focusableTimeField = focusable}
                                       value={this.state.timeValue}
                                       onChange={a => this.timeChanged(a)}
                                       precision={this.state.precision}
                        />}

                        <div style={{display: "flex", alignItems: "center"}}>
                            <IconButton onClick={() => this.setState({dlgOpen: true})}
                                        style={{margin: 1, padding: 0}}>
                                <ActionToday/>
                            </IconButton>
                        </div>
                    </div>
                    <Typography variant="caption"
                                style={{marginLeft: 20}}>{LCalFormatter.formatDateTime(this.props.value)}</Typography>
                    <DateTimePickerDlg
                        open={this.state.dlgOpen}
                        onRequestClose={() => {
                            this.setState({dlgOpen: false});
                            if (this.props.onInputComplete) {
                                this.props.onInputComplete()
                            }
                        }}
                        onDateChange={(date) => date ? this.dateTimeChanged(new LCal().setTimeZone("Europe/Berlin").setJulianMinutes(LCalHelper.unixToJulian(date.getTime()))) : this.dateTimeChanged(null)}
                        value={date}
                    />
                </div>
            </div>
        )
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 5
    },
    formControl: {
        margin: theme.spacing.unit,
    },
});

LCalDateTimeField.propTypes = {
    value: PropTypes.instanceOf(LCal),
    onChange: PropTypes.func.isRequired,
    onInputComplete: PropTypes.func,
};

export default withStyles(styles)(LCalDateTimeField);
