import React from 'react';
import StudentDriverIcon from '../icons/studentdriver';
import DefaultResourceDetails from './defaultresourcedetails.js';

class StudentDriverResourceDetails extends DefaultResourceDetails {
    getIcon() {
        let styles = this.getStyles();
        let a = super.getIcon();
        if(a===null) {
            return <StudentDriverIcon style={styles.resIcon}/>
        } else {
            return a;
        }
    }

	getTitleString() {
		return (this.props.data && this.props.data.getID()) < 0 ? "neuer Fahrschüler" : "Fahrschüler";
	}
}

export default StudentDriverResourceDetails;