import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton/IconButton';
import NavigationCloseIcon from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import StatusBar from '../ui-components/statusbar';


class Details extends React.Component {
  render() {
    return (
    		<div style={{width: Math.min(this.props.width ? this.props.width : 600, window.innerWidth),
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
    		}}>

	    		<AppBar position="static">
					<Toolbar style={{paddingLeft: 0}}>
                        <IconButton color="inherit" onClick={(evt)=>{if(this.props.onClose) {this.props.onClose()}}}>
                            <NavigationCloseIcon />
                        </IconButton>
						<Typography variant="title" color="inherit" style={{flex: 1}}>
							{this.props.title ? this.props.title : ""}
						</Typography>
						{this.props.addButtons}

					</Toolbar>
				</AppBar>

                <div style={{
                    flex: 1,
                    overflow: "auto",
                    padding: "10px",
                    position: "relative"
                }}>
                            <div>
                                {this.props.children}
                            </div>


                </div>


                {this.props.fab ? <div style={{position: "absolute", bottom: 60, right: 10}}>{this.props.fab}</div> : null}

                {this.props.statusBarData ? <StatusBar data={this.props.statusBarData}/> : null}
    		</div>
    )
  }

  
}

export default Details;
