import React from 'react';
import DrivingInstructorIcon from '../icons/drivinginstructor';
import DefaultResourceDetails from './defaultresourcedetails.js';

class DrivingInstructorResourceDetails extends DefaultResourceDetails {
    getIcon() {
        let styles = this.getStyles();
        let a = super.getIcon();
        if(a) {
            return a;
        } else {
            return <DrivingInstructorIcon style={styles.resIcon}/>
        }
    }

	getTitleString() {
		return (this.props.data && this.props.data.getID()) < 0 ? "neuer Fahrlehrer" : "Fahrlehrer";
	}
}

export default DrivingInstructorResourceDetails;