/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


class LCalPrecisionField extends React.Component {
    render() {
        const classes = this.props.classes;
        return (
            <FormControl className={classes.formControl} style={{margin: 1, padding: 0}}>
                <InputLabel htmlFor="precision">Genauigkeit</InputLabel>
                <Select
                    value={this.props.precision}
                    onChange={(evt) => this.props.onPrecisionChange(evt.target.value)}
                    inputProps={{
                        name: 'precision',
                        id: 'precision',
                    }}
                >
                    <MenuItem value={0}>1 Mrd Jahre</MenuItem>
                    <MenuItem value={1}>100 Mio Jahre</MenuItem>
                    <MenuItem value={2}>10 Mio Jahre</MenuItem>
                    <MenuItem value={3}>1 Mio Jahre</MenuItem>
                    <MenuItem value={4}>100 Tsd Jahre</MenuItem>
                    <MenuItem value={5}>10 Tsd Jahre</MenuItem>
                    <MenuItem value={6}>Jahrtausend</MenuItem>
                    <MenuItem value={7}>Jahrhundert</MenuItem>
                    <MenuItem value={8}>Jahrzehnt</MenuItem>
                    <MenuItem value={9}>Jahr</MenuItem>
                    <MenuItem value={10}>Monat</MenuItem>
                    <MenuItem value={11}>Tag</MenuItem>
                    <MenuItem value={12}>Stunde</MenuItem>
                    <MenuItem value={13}>Minute</MenuItem>
                    <MenuItem value={14}>Sekunde</MenuItem>
                </Select>
            </FormControl>
        )
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    }
});


export default withStyles(styles)(LCalPrecisionField);
