import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton/IconButton';
import NavigationCloseIcon from '@material-ui/icons/Close';
import 'whatwg-fetch';

class EditRights extends React.Component {
  render() {
  	const styles = {
			  outerContainer: {
				  height: "100%"
			  },
			  contentStyle: {
				  	padding: 10,
	    		    height: "90%",
	    		    overflowY: "auto"			    		  
			  }
	  };
	 

    return (
    		<div style={styles.outerContainer}>
	    		<AppBar title="Berechtigungen"
					iconElementLeft={<IconButton onClick={()=>this.props.onClose()}><NavigationCloseIcon /></IconButton>}
				
	    		/>
	    		<div style={styles.contentStyle}>
	    			Berechtigungen
	    		</div>	    		
    		</div>
    )
  }

  
}

export default EditRights;
