import React from 'react';
import SvgIconFace from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';


class StatusBar extends React.Component {
    render() {
        let avatar;
        if (this.props.avatarSrc) {
            avatar = <Avatar src={this.props.avatarSrc}/>
        } else {
            avatar = <Avatar style={{width: 40, height: 40}}><SvgIconFace/></Avatar>
        }

        let label =
            <div>
				<span>
					{this.props.name && <div>{this.props.name}</div>}
                    {this.props.time && <div>{this.props.time}</div>}
				</span>
            </div>

        return (
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                background: "rgba(100, 100, 100, 0.3)"
            }}>

                <Chip
                    avatar={avatar}
                    label={label}
                    style={{height: 40, margin: 5}}
                />

                <span style={{margin: 5}}>
					<Typography variant="caption">
					       #{this.props.displid ? this.props.displid : "unknown"}
					</Typography>
				</span>
            </div>
        )
    }
}

export default StatusBar;