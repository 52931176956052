import React from 'react';
import PropTypes from 'prop-types';
import ReactAutosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: 'relative',
        height: 70,
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 3,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    textField: {
        width: '100%',
    },
});

class Autosuggest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
        this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
        this.renderInput = this.renderInput.bind(this);
    }


    handleSuggestionsFetchRequested({value}) {
        let SELF = this;
        setTimeout(() => {
            SELF.setState({
                suggestions: SELF.props.dataSource ? SELF.props.dataSource : [value],
            });
        }, 0);
    };

    handleSuggestionsClearRequested() {
        this.setState({
            suggestions: [],
        });
    };

    handleChange(event, {newValue}) {
        if (this.props.onUpdateInput) {
            this.props.onUpdateInput(newValue);
        }
    };

    renderInput(inputProps) {
        const {classes, autoFocus, value, ref, ...other} = inputProps;

        return (
            <TextField
                autoFocus={autoFocus}
                className={classes.textField}
                value={value}
                inputRef={ref}
                label={this.props.label}
                helperText={this.props.helperText}
                fullWidth={this.props.fullWidth}
                InputProps={{
                    classes: {
                        input: classes.input,
                    },
                    ...other,
                }}
            />
        );
    }

    renderSuggestion(suggestion, {query, isHighlighted}) {
        const matches = match(suggestion, query);
        const parts = parse(suggestion, matches);

        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map((part, index) => {
                        return part.highlight ? (
                            <span key={index} style={{fontWeight: 300}}>
                            <b style={{background: "yellow"}}>{part.text}</b>
                         </span>
                        ) : (
                            <strong key={index} style={{fontWeight: 500}}>
                                {part.text}
                            </strong>
                        );
                    })}
                </div>
            </MenuItem>
        );
    }

    renderSuggestionsContainer(options) {
        const {containerProps, children} = options;

        return (
            <Paper {...containerProps} square style={{zIndex: 1000}}>
                {children}
            </Paper>
        );
    }


    render() {
        const {classes} = this.props;

        return (
            <ReactAutosuggest
                id="autosuggest"
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderInputComponent={this.renderInput}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                getSuggestionValue={(val) => val}
                renderSuggestion={this.renderSuggestion}
                inputProps={{
                    classes,
                    placeholder: this.props.placeholder,
                    value: this.props.searchText,
                    onChange: this.handleChange
                }}
            />
        );
    }
}

Autosuggest.propTypes = {
    classes: PropTypes.object.isRequired,
    searchText: PropTypes.string,
};

export default withStyles(styles)(Autosuggest);