import {Task} from '@softmanufaktur/timeline';

/**
* Eine Buchung
*/
class BookingItem extends Task {
  constructor(id, start, end, resID, name, secname, debitor, selectedOptions, canBook, changeuser, changeUserImg, changetime, bookingNumber, relatedTaskIDs) {
    super(id, start, end, resID, name, secname);

    this.debitor = debitor;
    this.selectedOptions = selectedOptions;
    this.changeuser = changeuser;
    this.changeUserImg = changeUserImg;
    this.changetime = changetime;
    this.canBook = canBook;
    this.bookingNumber = bookingNumber;
    this.relatedTaskIDs = relatedTaskIDs;
  }

  clone() {
	  let bookingItem = new BookingItem(this.id, this.start.clone(), this.end.clone(), this.resID, this.name, this.secname, this.debitor, this.selectedOptions, this.canBook, this.changeuser, this.changeUserImg, this.changetime, this.bookingNumber, this.relatedTaskIDs);
	  bookingItem.setDisplayData(this.getDisplayData().clone());
      bookingItem.deleted = this.deleted;
	  return bookingItem;
  }

    setDebitor(name) {
        this.debitor = name;
    }

    getDebitor() {
        return this.debitor;
    }

    setSelectedOptions(opt) {
        this.selectedOptions = opt;
    }

    getSelectedOptions() {
        return this.selectedOptions;
    }

    getChangeUserName() {
        return this.changeuser;
    }

    getChangeUserImg() {
        return this.changeUserImg;
    }

    getChangeTime() {
        return this.changetime;
    }

    getBookingNumber() {
      return this.bookingNumber;
    }

    setBookingNumber(bn) {
      this.bookingNumber = bn;
    }

    getRelatedTaskIDs() {
      return this.relatedTaskIDs;
    }

    setRelatedTaskIDs(rt) {
      this.relatedTaskIDs = rt;
    }
}

export default BookingItem;
