/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 * --------------------------------------------------------
 * Die Daten, die für die Anzeige der Ressource gebraucht werden
 * Diese Daten werden nicht mit den Ressourcendaten gespeichert, sondern allenfalls profilabhängig
*/
import DisplayData from './displaydata.js'

class ResourceDisplayData extends DisplayData {
}

export default ResourceDisplayData
