import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import 'whatwg-fetch';
import Config from './config/config.js';
import TransferHandler from './transfer/transferhandler';
import {Map, Set} from 'immutable';
import Details from './ui-components/details';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import {ExpandableCardHeader} from '@softmanufaktur/timeline';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            historyEntries: [],
            historyByUser: Map(),
            expandedUserIDs: Set(),
            sorting: "byUser",
            byTimeLoaded: false
        };
    }

    componentDidMount() {
        this.readHistoryUsers();
    }

    readHistoryUsers() {
        let transferObj;
        if (this.props.task) {
            transferObj = {
                type: "task",
                id: this.props.task.getID(),
                sessionid: sessionStorage.getItem('pixipoint_sessionid')
            }
        } else {
            transferObj = {
                type: "resource",
                id: this.props.resource.getID(),
                sessionid: sessionStorage.getItem('pixipoint_sessionid')
            }
        }

        //Historie nach Benutzer
        TransferHandler.request(Config.getHistoryUsersURL(), (json) => {
            let historyByUser = Map();
            for (let user of json.users) {
                historyByUser = historyByUser.set(user, []);
            }

            this.setState({historyByUser});
        }, () => console.log("Fehler beim Holen der Historie"), transferObj);
    }

    toggleUser(user) {
        if (this.state.expandedUserIDs.includes(user.id)) {
            this.setState({expandedUserIDs: this.state.expandedUserIDs.delete(user.id)});
        } else {
            this.setState({expandedUserIDs: this.state.expandedUserIDs.add(user.id)});

            //TODO: Nur, wenn noch nicht geladen
            this.readHistory(user);
        }

    }

    readHistory(user) {
        if (user || (!user && !this.state.byTimeLoaded)) {
            let transferObj;
            if (this.props.task) {
                transferObj = {
                    type: "task",
                    id: this.props.task.getID(),
                    sessionid: sessionStorage.getItem('pixipoint_sessionid')
                }
            } else {
                transferObj = {
                    type: "resource",
                    id: this.props.resource.getID(),
                    sessionid: sessionStorage.getItem('pixipoint_sessionid')
                }
            }
            if (user) {
                transferObj.userid = user.id;
            }

            //Historie nach Benutzer
            TransferHandler.request(Config.getHistoryURL(), (json) => {
                if (user) {
                    let historyByUser = this.state.historyByUser.set(user, json.entries);
                    this.setState({historyByUser});
                } else {
                    this.setState({historyEntries: json.entries});
                }
            }, () => console.log("Fehler beim Holen der Historie"), transferObj);
        }
    }

    render() {
        //Historie nach Benutzer
        let users = [];
        for (let user of this.state.historyByUser.keys()) {
            users.push(user);
        }

        let sortedContent = this.state.sorting === "byUser" ?
            users.map((user) => {
                return <Card key={user.id}>
                    <ExpandableCardHeader title={user.username} open={this.state.expandedUserIDs.includes(user.id)}
                                              onOpen={() => this.toggleUser(user)} style={{fontSize: 15}}/>

                        <Collapse in={this.state.expandedUserIDs.includes(user.id)} transitionduration="500" unmountOnExit>

                        {this.state.historyByUser.get(user).map((entry, index) => {
                            let date = new Date(entry.minstart * 1000);
                            let startTime = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);

                            date = new Date(entry.maxend * 1000);
                            let endTime = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);

                            //Unix-Zeitstempel
                            date = new Date(entry.changeTimeUnix * 1000);
                            let changeTime = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);

                            let a;
                            switch (entry.actType) {
                                case 0:
                                    a = <span style={{color: "green"}}>erstellt</span>
                                    break;
                                case 1:
                                    a = <span style={{color: "orange"}}>geändert</span>
                                    break;
                                case 2:
                                    a = <span style={{color: "red"}}>gelöscht</span>
                                    break;
                                default:
                                    a = "";
                            }
                            return <Typography key={index} style={{
                                margin: 10,
                                background: "lightgray",
                                padding: 10
                            }}>{"Am " + changeTime + " "} {a} {" durch " + entry.username}<br/>Start: {startTime}<br/>Ende: {endTime}<br/>ID: {entry.taskid}<br/>
                            </Typography>
                        })}

                        </Collapse>
                </Card>
            })
            :
            this.state.historyEntries.map((entry, index) => {
                let date = new Date(entry.minstart * 1000);
                let startTime = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);

                date = new Date(entry.maxend * 1000);
                let endTime = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);

                //Unix-Zeitstempel
                date = new Date(entry.changeTimeUnix * 1000);
                let changeTime = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);

                let a;
                switch (entry.actType) {
                    case 0:
                        a = <span style={{color: "green"}}>erstellt</span>
                        break;
                    case 1:
                        a = <span style={{color: "orange"}}>geändert</span>
                        break;
                    case 2:
                        a = <span style={{color: "red"}}>gelöscht</span>
                        break;
                    default:
                        a = "";
                }
                return <Typography key={index} style={{
                    margin: 10,
                    background: "lightgray",
                    padding: 10
                }}>{"Am " + changeTime + " "} {a} {" durch " + entry.username}<br/>Start: {startTime}<br/>Ende: {endTime}<br/>ID: {entry.taskid}<br/>
                </Typography>
            });


        return (
            <Details onClose={() => this.props.onClose()} title="Historie">
                <RadioGroup name="sort" style={{flexShrink: 0, display: "flex", flexDirection: "row"}}
                            onChange={(evt, val) => {
                                this.setState({sorting: val});
                                if (val === 'byTime') this.readHistory()
                            }}>
                    <FormControlLabel value="male" control={
                        <Radio
                            color="primary"
                            value="byUser"
                            checked={this.state.sorting === 'byUser'}
                        />}
                                      label="nach Benutzer"/>

                    <FormControlLabel value="male" control={
                        <Radio
                            color="primary"
                            value="byTime"
                            checked={this.state.sorting === 'byTime'}
                        />}
                                      label="nach Zeit"/>

                </RadioGroup>

                <div style={{
                    flexGrow: 1,
                    overflow: "auto",
                    minHeight: "2em",
                    padding: "10px"
                }}>
                    {
                        sortedContent
                    }
                </div>
            </Details>
        )
    }
}

export default History;
