import React from 'react';
import TransferHandler from '../transfer/transferhandler.js';
import Config from '../config/config.js';
import ResourceItem from './resourceitem';
import {Set} from 'immutable';


class ResourceTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {resources: [], pendingResourceIDs: new Set()};
    }

    componentDidMount() {
        //Holen der (gefilterten) Ressourcen
        let successFunc = (json) => {
            //TODO: Im ersten Schritt alles anzeigen. Später Filter und Paging einführen
            this.setState({resources: json.resources});
        }
        let errorFunc = (code, json) => {

        }
        TransferHandler.request(Config.getPossibleRessourcesURL(), successFunc, errorFunc, {});
    }

    resTapped(res, index) {
            let pendingResourceIDs = this.state.pendingResourceIDs.add(res.id);
            this.setState({pendingResourceIDs});

            //Holen der (gefilterten) Ressourcen
            let transferObj = {
                resourceid: res.id,
                isvisible: !res.isVisible
            }
            let successFunc = (json) => {
                let newResources = [];
                for(let r of this.state.resources) {
                    if(r.id !== res.id) {
                        newResources.push(r);
                    } else {
                        r = JSON.parse(JSON.stringify(r));
                        r.isVisible = !r.isVisible;
                        newResources.push(r);
                    }
                }
                this.setState({resources: newResources});

                //In der Tabelle den Eintrag bestätigen und die Info nach aussen propagieren
                this.props.onVisibilityChange(res.id, !res.isVisible);
                let pendingResourceIDs = this.state.pendingResourceIDs.delete(res.id);
                this.setState({pendingResourceIDs});
            }
            let errorFunc = (json) => {
                let pendingResourceIDs = this.state.pendingResourceIDs.delete(res.id);
                this.setState({pendingResourceIDs});
            }
            TransferHandler.request(Config.getVisibleResourceURL(), successFunc, errorFunc, transferObj);
    }

    render() {
        return (
            <div>
                {
                    this.state.resources.map((res, index) => {
                        return <ResourceItem key={index} res={res} onClick={()=>this.resTapped(res, index)} resIsPending={this.state.pendingResourceIDs.includes(res.id)} model={this.props.model}/>
                    })
                }
            </div>
        )
    }
}

export default ResourceTable;
