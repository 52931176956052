/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import React from 'react';

class NumberField extends React.Component {
    inputChanged(evt) {
        this.props.onChange(evt.target.value);
    }


    render() {
        return (
            <span style={{cursor: "pointer"}}>
        	<input type="number" style={{width: 30}} value={this.props.value}
                   onChange={(evt) => this.inputChanged(evt)}/>
        </span>
        )
    }
}

export default NumberField
