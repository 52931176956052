import React from "react";
import ReactDOM from "react-dom";
import PixiPointMain from "./pixipointmain";


class Index extends React.Component {
  render() {
     return (
            <PixiPointMain/>
        )
  }
}

ReactDOM.render(<Index/>, document.getElementById('root'));
