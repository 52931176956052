import React from 'react';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

const ResourceItem = ({ res, onClick, isChecked, model }) =>
        <div style={{display: "flex", border: "1px solid lightgray", background: "white", color: "black", cursor: "pointer", margin: 5, padding: 10}}>
            <div style = {{marginRight: 5}}>
                {/* <img style={{width: 32, height: 32, verticalAlign: 'middle', margin: 2}} src={this.props.model.getIcon(res).src}/>*/}
                <img style={{width: 32, height: 32, verticalAlign: 'middle', margin: 2}} alt="" src={model.getIcon(res).src}/>
            </div>
            <div style = {{flexGrow: 30}}>
                <Typography variant="subheading" color="inherit">
                    {res.name}
                </Typography>
                <Typography variant="body1" color="inherit">
                    {res.secname}
                </Typography>
            </div>
            <div>
                <div>
                    <div style={{textAlign: "right"}}>
                        <Checkbox
                            color="primary"
                            checked={isChecked}
                            onChange={onClick}
                        />
                    </div>
                </div>
            </div>
        </div>


export default ResourceItem;