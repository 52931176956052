import React from 'react';
import Details from './ui-components/details';
import 'whatwg-fetch';
import Config from './config/config.js';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Helper} from '@softmanufaktur/timeline';
import TransferHandler from './transfer/transferhandler.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {oldPassword: "", newPassword1: "", newPassword2: "", dialogOpen: null, errorCode: 0}
  }

  changePassword() {
	  //Ist das neue Kennwort mindestens 8 Zeichen lang und entsprichet das zuerst eingegebene Kennwort dem zweiten?
	  if(this.state.newPassword1.length < 8) {
		  this.setState({dialogOpen: "PASSWORD_POLICY"});
	  } else if (this.state.newPassword1 !== this.state.newPassword2) {
		  this.setState({dialogOpen: "PASSWORDS_NOT_EQUAL"});
	  } else {
		  let loginMail = sessionStorage.getItem('pixipoint_login_user');

		  let encryptedOldPass = Helper.sha1(this.state.oldPassword + loginMail.toLowerCase());
		  let encryptedNewPass = Helper.sha1(this.state.newPassword1 + loginMail.toLowerCase());
		  
		  let errorFunc = function(eC, json) {
			  //Im Fehlerfall wird das json-Objekt im Status gespeichert
			  this.setState({dialogOpen: "ERROR", errorCode: eC});			  
		  }.bind(this);
		  
		  let successFunc = function(json) {
		  	//Bei Erfolg das Callback aufrufen

			//Falls "Remember me" angefordert wurde, den entsprechenden Eintrag im Storage machen, ansonsten löschen
			if(this.state.rememberMe) {
				localStorage.setItem('pixipoint_rememberme_key', json.autologinkey);
				localStorage.setItem('pixipoint_rememberme_user', this.state.loginMail);
			} else {
				localStorage.removeItem('pixipoint_rememberme_key');
				localStorage.removeItem('pixipoint_rememberme_user');
			}

			this.setState({dialogOpen: "CHANGE_OK"});

		  }.bind(this);
		  
		  TransferHandler.request(Config.getChangePasswordURL(), successFunc, errorFunc, {oldPass: encryptedOldPass, newPass: encryptedNewPass});
	  }
  }

  closeDialog() {
      this.setState({dialogOpen: null});
      if (this.state.dialogOpen === "CHANGE_OK" && this.props.onClose) {
          this.props.onClose();
      }
  }
  
  render() {
      let dialogInfo = {dialogTitle: "Passwortänderung", dialogContent: (<div>Ihr Passwort wurde erfolgreich geändert.</div>)};
	  if(this.state.dialogOpen === "ERROR") {
		  dialogInfo = TransferHandler.getDialogInfo(this.state.errorCode);
	  } else if (this.state.dialogOpen === "PASSWORD_POLICY") {
		  dialogInfo.dialogTitle = "Fehler beim Ändern des Passworts";
		  dialogInfo.dialogContent = (<div>Das gewählte Passwort muss mindestens 8 Zeichen enthalten.</div>);
	} else if (this.state.dialogOpen === "PASSWORDS_NOT_EQUAL") {
		  dialogInfo.dialogTitle = "Fehler beim Ändern des Passworts";
		  dialogInfo.dialogContent = (<div>Das wiederholte Passwort unterscheidet sich vom ersten eingegebenen Passwort.</div>);
	}

    return (
		<Details onClose={()=>this.props.onClose()} title="Passwort ändern" addButtons={<Button style={{color: "white"}} onClick={() => this.changePassword()}>Speichern</Button>}>
	    		<div>
	    			<TextField id="oldPasswordField" label="Altes Passwort" type="password" value={this.state.oldPassword} onChange={(evt)=>this.setState({oldPassword: evt.target.value})} fullWidth/>
	    			<TextField id="newPasswordField1" label="Neues Passwort (min. 8 Zeichen)" type="password" value={this.state.newPassword1} onChange={(evt)=>this.setState({newPassword1: evt.target.value})} fullWidth/>
	    			<TextField id="newPasswordField2" label="Neues Passwort (Wiederholung)" type="password" value={this.state.newPassword2} onChange={(evt)=>this.setState({newPassword2: evt.target.value})} fullWidth/>
	    		</div>
	    			
				<Dialog
			          open={this.state.dialogOpen !== null}
					  onClose={()=>this.closeDialog()}
			        >
					<DialogTitle>
						{dialogInfo.dialogTitle}
					</DialogTitle>
					<DialogContent>
			     		{dialogInfo.dialogContent}
					</DialogContent>
			     </Dialog>
		</Details>
    )
  }

  
}

export default ChangePassword;
