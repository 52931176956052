import React from 'react';
import ResourceTable from './resourcetable';
import 'whatwg-fetch';
import Details from '../ui-components/details';
import Typography from '@material-ui/core/Typography';

class Filter extends React.Component {
  render() {
		return (
            <Details onClose={() => this.props.onClose()} title="Filter">
                <Typography variant="subheading" color="inherit">
                        Wählen Sie hier die Objekte aus, die in der Plantafel sichtbar sein sollen.
                </Typography>
                        <br/>
                        <ResourceTable onVisibilityChange={(resID, isvisible)=>this.props.onVisibilityChange(resID, isvisible)} model={this.props.model}/>
            </Details>
    )
  }

  
}

export default Filter;
