/**
* Eine Eintrag in der Optionsgruppen-Tabelle
*/
class OptionGroupEntry {
  constructor(name, options, multichoice) {
    this.name = name;
    this.options = options;
    this.multichoice = multichoice;
  }
}

export default OptionGroupEntry
