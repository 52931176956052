/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import BasicTimeline from './basictimeline';
import LCal from '../calendar/lcal';
import LCalFormatter from '../calendar/lcalformatter';
import LCalHelper from '../calendar/lcalhelper';
import Helper from '../helper/helper'
import TimelineEvent from './timelineevent';
import TaskBarBounds from './taskbarbounds';
import LevelComputationTaskBarBounds from './levelcomputationtaskbarbounds';
import LCalInterval from "../calendar/lcalinterval";

const LABEL_LINE_HEIGHT = 15;
const SECLABEL_LINE_HEIGHT = 15;
const SINGLEEVENTRADIUS = 20;
const ARROWHEADLENGTH = 20;
const TASKBARINSET = 5;
const timelineBarHeaderFont = "12px Roboto, sans-serif";
const timelineBarSubHeaderFont = "12px Roboto, sans-serif";
const timelineHeaderColor = "#F7F7F7";
const timelineMainFont = "16px Roboto, sans-serif";
const timelineMainFontMini = "12px Roboto, sans-serif";
const timelineSubFont = "12px Roboto, sans-serif";
const resourceMainFont = "12px Roboto, sans-serif";
const saturdayColor = "rgba(255, 240, 240, 0.3)";
const sundayColor = "rgba(255, 220, 220, 0.3)";
const labelBGColor = "rgba(200, 200, 200, 0.5)";  //"red";
const innerEventColor = "rgba(0,255,0, 0.4)";

/**
 * Hier wird die konkrete Timeline gezeichnet
 **/
class Timeline extends BasicTimeline {
    constructor(props) {
        super(props);

        props.model.addDataChangeCallback(() => this._updateCanvas());
        props.model.addMovedTasksChangeCallback(() => this._updateCanvas()); //TODO: Wenn auf separates Canvas gezeichnet wird, dann auch hier das Update entsprechend ändern

        this.beforeMovementJulMin = null;
        this.beforeMovementY = null;
        this.lastFullPaintTime = 0;

        this.resOffset = 0; //Offset für die Ressourcen
        this.workResOffset = 0;

        this.lastIntervalPaintDuration = 0; //Die Dauer, die für das letzte Zeichnen der Intervalle benötigt wurde

        this.animationTimeoutHandle = 0;
        this.fullPaintHandle = 0;

        //this.performancePaintMode = 0; //0 -> wenn zu langsam gezeichnet wird, dann wird nur das Canvas bei Verschiebungen an eine andere Stelle kopiert; 1 -> immer mit Offscreen-Image arbeiten; 2 -> immmer komplett zeichnen

        this.centerPinchTime = null;

        this.oldOrientation = null;
        this.oldWidth = null;
        this.oldHeight = null;

        this.getTaskBarBounds = this.getTaskBarBounds.bind(this);
        this.getTaskBarBoundsForLevelComputation = this.getTaskBarBoundsForLevelComputation.bind(this);

        this.lockDuration = 0;

        this.initMeasureSliders(this.props);

        /*if(this.props.innerEventBackground) {
            this.innerEventBackground = new Image();
            this.innerEventBackground.src = this.props.innerEventBackground;
        }*/
        /*let svgData = '<svg xmlns="http://www.w3.org/2000/svg"><path d="M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5z" /></svg>';
        this.innerEventBackground.src = "data:image/svg+xml," + encodeURIComponent(svgData);
        this.innerEventBackground.width = 16;
        this.innerEventBackground.height = 16;*/

    }


    initMeasureSliders(p) {
        let start = p.initialMeasureInterval ? p.initialMeasureInterval.start.clone() : null;
        if (start) {
            start.precision = 13;
        }
        let end = p.initialMeasureInterval ? p.initialMeasureInterval.end.clone() : null;
        if (end) {
            end.precision = 13;
        }
        if ((!this.initialMeasureStart && start)
            || (this.initialMeasureStart && !this.initialMeasureStart.equals(start))
            || (!this.initialMeasureEnd && end)
            || (this.initialMeasureEnd && !this.initialMeasureEnd.equals(end))) {
            this.measureSliderStart = start;
            this.measureSliderEnd = end;
            this.initialMeasureStart = start;
            this.initialMeasureEnd = end;
            this.measureSliderOffset = 0;
            this.activeMeasureSlider = 0;
            this.paintMeasureStart = null;
            this.paintMeasureEnd = null;
            this.recomputeMeasureSliderTimes();
            this.recomputeMeasureInterval();
        }
        if (p.measureDurationLock) {
            this.lockDuration = new LCalInterval(this.paintMeasureStart, this.paintMeasureEnd).getAbsDurationMinutesConsiderPrecision();
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillReceiveProps(nextProps) {
        this.initMeasureSliders(nextProps);
        this._updateCanvas();
    }

    //Wenn sich die Orientation ändert, dann gibt es ein Update
    componentDidUpdate() {
        if (this.oldWidth !== this.props.width || this.oldHeight !== this.props.height || this.props.horizontalOrientation !== this.oldOrientation) {
            this.oldOrientation = this.props.horizontalOrientation;
            this.oldWidth = this.props.width;
            this.oldHeight = this.props.height;

            this.ctx.setTransform(1, 0, 0, 1, 0, 0);

            if (!this.props.horizontalOrientation) {
                this.ctx.rotate(Math.PI / 2);
                this.ctx.translate(0, -this.ctx.canvas.width);
            }

            this.virtualCanvasWidth = this.props.horizontalOrientation ? this.props.width : this.props.height;
            this.virtualCanvasHeight = this.props.horizontalOrientation ? this.props.height : this.props.width;

            this._updateCanvas();
        }
    }

    _createTimelineEvent(evt) {
        //befindet sich die Maus über einem Task, über einer Resource, oder über der Zeitleiste?
        let mousePos = Helper.getCursorPosition(this.refs.canvas, evt);
        return this._createTimelineEventXY(mousePos[0], mousePos[1]);
    }

    _createTimelineEventXY(x, y) {
        let retVal = new TimelineEvent();
        if (!this.props.horizontalOrientation) {
            //Transformation der Mousekoordinaten
            let tmpX = x;
            x = y;
            y = this.refs.canvas.width - tmpX;
        }

        //Timeline-Header wurde gedrückt
        //Zu welcher Zeit?
        let t = this.getTimeForXPos(x);
        let lcal = new LCal().setTimeZone("Europe/Berlin");
        lcal.setJulianMinutes(t);

        retVal.setTime(lcal);

        retVal.setTimeHeaderPressed(x > this.resourceHeaderHeight && y <= this.timelineHeaderHeight);

        retVal.setTask(this.getTask(x, y));

        retVal.setResource(this.getResource(y));

        retVal.setX(x);

        retVal.setY(y);

        if (this.measureSliderStart && this.measureSliderEnd) {
            let xStartSlider = this.getXPosForTime(this.measureSliderStart.getJulianMinutes());

            if (x >= xStartSlider - 40 && x <= xStartSlider) {
                retVal.mouseOverStartMeasureSlider = true;
            } else {
                let xEndSlider = this.getXPosForTime(this.measureSliderEnd.getJulianMinutes());
                if (x >= xEndSlider  && x <= xEndSlider + 40) {
                    retVal.mouseOverEndMeasureSlider = true;
                }
            }
        }

        if(this.props.overlayheader) {
            if (x <= 100) {
                retVal.setResourceHeaderPressed(true);
            }
        } else {
            if (x <= this.resourceHeaderHeight) {
                retVal.setResourceHeaderPressed(true);
            }
        }

        return retVal;
    }

    onTap(evt) {
        super.onTap(evt);
        this._fireClickCallback(this._createTimelineEvent(evt));
    }

    onMouseMove(evt) {
        super.onMouseMove(evt);
        let mousePos = Helper.getCursorPosition(this.refs.canvas, evt);
        this._fireMouseMoveCallback(this._createTimelineEvent(evt));
    }

    onLongPress(evt) {
        super.onLongPress(evt);
        this._fireLongPressCallback(this._createTimelineEvent(evt));
    }

    _press(evt) {
        super._press(evt);
        let mousePos = Helper.getCursorPosition(this.refs.canvas, evt);
        //Ist die Maus / Geste über einem Measureslider?
        //(aktuelle x-Position -40 oder +40 je nach direction)
        if (this.measureSliderStart && this.measureSliderEnd) {
            let sliderStartX = this.getXPosForTime(this.measureSliderStart.getJulianMinutes());
            let sliderEndX = this.getXPosForTime(this.measureSliderEnd.getJulianMinutes());
            if ((this.props.horizontalOrientation && mousePos[0] >= sliderStartX - 40 && mousePos[0] <= sliderStartX)
                || (!this.props.horizontalOrientation && mousePos[1] >= sliderStartX - 40 && mousePos[1] <= sliderStartX)) {
                this.activeMeasureSlider = 1;
            } else if ((this.props.horizontalOrientation && mousePos[0] >= sliderEndX && mousePos[0] <= sliderEndX + 40)
                || (!this.props.horizontalOrientation && mousePos[1] >= sliderEndX && mousePos[1] <= sliderEndX + 40)) {
                this.activeMeasureSlider = -1;
            } else {
                this.activeMeasureSlider = 0;
            }
            this.recomputeMeasureSliderTimes();
        }

        this._firePressCallback(this._createTimelineEvent(evt));
    }

    recomputeMeasureInterval() {
        if (this.paintMeasureStart && this.paintMeasureEnd) {
            //Die Zeit zwischen den beiden Slidern ausgeben
            let interval = new LCalInterval(this.paintMeasureStart, this.paintMeasureEnd);

            this.props.onMeasureIntervalChanged(this.paintMeasureStart ? interval : null, true);
        }
    }

    getNextSnapTime(x, julMin) {
        //Suche Ereignisse, die den Start oder das Ende nicht weiter als x Minuten entfernt haben. Schnappe zum nächstliegenden ein
        let rangeStartX = x - 20;
        let rangeStartTime = this.getTimeForXPos(rangeStartX);
        let rangeEndX = x + 20;
        let rangeEndTime = this.getTimeForXPos(rangeEndX);
        let foundLCal = null;

        let check = (lcal) => {
            let foundLCal = null;
            if (lcal.getJulianMinutes() >= rangeStartTime && lcal.getJulianMinutes() <= rangeEndTime) {
                if (!foundLCal || Math.abs(lcal.getJulianMinutes() - julMin) < Math.abs(foundLCal.getJulianMinutes() - julMin)) {
                    foundLCal = lcal;
                }
            }
            return foundLCal;
        }

        for (let task of this.props.model.getAll()) {
            foundLCal = check(this.props.model.getDisplayedStart(task));
            if (!foundLCal) {
                foundLCal = check(this.props.model.getDisplayedEnd(task));
            }
            if (!foundLCal) {
                foundLCal = check(this.props.model.getDisplayedEnd(task));
            }
            if (!foundLCal && task.innerEvents) {
                for (let interval of task.innerEvents) {
                    if (interval.getStart()) {
                        foundLCal = check(interval.getStart());
                        if (foundLCal) {
                            break;
                        }
                    }
                    if (!foundLCal && interval.getEnd()) {
                        foundLCal = check(interval.getEnd());
                        if (foundLCal) {
                            break;
                        }
                    }
                }

            }
            if (foundLCal) {
                break;
            }
        }

        //Auch noch jetzt überprüfen
        if (!foundLCal) {
            foundLCal = check(new LCal().setJulianMinutes(LCalHelper.getNowMinutes()));
        }

        return foundLCal ? foundLCal.clone().setPrecision(13) : null;
    }

    recomputeMeasureSliderTimes() {
        if (this.measureSliderStart && this.measureSliderEnd) {
            let t = this.measureSliderStart;
            if (this.activeMeasureSlider === 1) {
                let x = this.getXPosForTime(this.measureSliderStart.getJulianMinutes()) + this.measureSliderOffset;
                let julMin = this.getTimeForXPos(x);
                if (!this.props.measureDurationLock && julMin >= this.paintMeasureEnd.getJulianMinutes()) {
                    //Maximal so weit nach rechts schieben, bis der zweite Slider kommmt
                    t = this.paintMeasureEnd;
                } else {
                    t = this.getNextSnapTime(x, julMin);
                    if (!t) {
                        //Nichts zum einschnappen? Ist der Lock gesetzt, dann auch beim anderen Slider das einrasten versuchen
                        if (this.props.measureDurationLock) {
                            let x2 = this.getXPosForTime(this.measureSliderStart.getJulianMinutes() + this.lockDuration) + this.measureSliderOffset;
                            let julMin2 = this.getTimeForXPos(x);
                            t = this.getNextSnapTime(x2, julMin2);
                            if (t) {
                                t = new LCal().setJulianMinutes(t.getJulianMinutes() - this.lockDuration).setTimeZone(this.measureSliderStart);
                            }
                        }

                        if (!t) {
                            t = new LCal().setJulianMinutes(julMin).setTimeZone(this.measureSliderStart);
                        }
                    }


                }
            }
            this.paintMeasureStart = t ? t.clone().setPrecision(13) : null;

            t = this.measureSliderEnd;
            if (this.activeMeasureSlider === -1) {
                let x = this.getXPosForTime(this.measureSliderEnd.getJulianMinutes()) + this.measureSliderOffset;
                let julMin = this.getTimeForXPos(x);
                if (!this.props.measureDurationLock && julMin <= this.paintMeasureStart.getJulianMinutes()) {
                    //Maximal so weit nach links schieben, bis der erste Slider kommt
                    t = this.paintMeasureStart;
                } else {
                    t = this.getNextSnapTime(x, julMin);
                    if (!t) {
                        //Nichts zum einschnappen? Ist der Lock gesetzt, dann auch beim anderen Slider das einrasten versuchen
                        if (this.props.measureDurationLock) {
                            let x2 = this.getXPosForTime(this.measureSliderEnd.getJulianMinutes() - this.lockDuration) + this.measureSliderOffset;
                            let julMin2 = this.getTimeForXPos(x);
                            t = this.getNextSnapTime(x2, julMin2);
                            if (t) {
                                t = new LCal().setJulianMinutes(t.getJulianMinutes() + this.lockDuration).setTimeZone(this.measureSliderStart);
                            }
                        }

                        if (!t) {
                            t = new LCal().setJulianMinutes(julMin).setTimeZone(this.measureSliderEnd);
                        }
                    }
                }

            }

            this.paintMeasureEnd = t ? t.clone().setPrecision(13) : null;

            //Falls die Messdauer gelockt ist
            if (this.props.measureDurationLock) {
                if (this.activeMeasureSlider === 1) {
                    this.paintMeasureEnd = this.paintMeasureStart.clone();
                    this.paintMeasureEnd.addMinutes(this.lockDuration);
                } else if (this.activeMeasureSlider === -1) {
                    this.paintMeasureStart = this.paintMeasureEnd.clone();
                    this.paintMeasureStart.addMinutes(-this.lockDuration);
                }
            }
        } else {
            this.paintMeasureStart = null;
            this.paintMeasureEnd = null;
        }
    }

    offsetResetted() {
        //this.props.model._setDisplayDataDirty(true);
        this._alignWorkResOffset();
        this.resOffset = this.workResOffset;

        this.measureSliderStart = this.paintMeasureStart;
        this.measureSliderEnd = this.paintMeasureEnd;

        this.measureSliderOffset = 0;
        this.activeMeasureSlider = 0;

        this.recomputeMeasureSliderTimes();

        super.offsetResetted();

        let interval = new LCalInterval(this.paintMeasureStart, this.paintMeasureEnd);
        this.props.onMeasureIntervalChanged(this.paintMeasureStart ? interval : null, false);
    }

    offsetChanged() {
        if (this.activeMeasureSlider === 0) {
            if (this.props.horizontalOrientation) {
                this.workResOffset = this.resOffset + this.offsetY;
            } else {
                this.workResOffset = this.resOffset - this.offsetX;
            }
            this._alignWorkResOffset();
            super.offsetChanged();
            this._fireOffsetChanged();
        } else {
            this.measureSliderOffset = this.props.horizontalOrientation ? this.offsetX : this.offsetY;
            this.recomputeMeasureSliderTimes();
            this.recomputeMeasureInterval();
        }
    }

    _alignWorkResOffset() {
        let refHeight = Math.max(this.props.model.getResourceModel().getTotalResourceHeight() + this.timelineHeaderHeight, this.virtualCanvasHeight);
        if (this.workResOffset < this.virtualCanvasHeight - refHeight) {
            this.workResOffset = this.virtualCanvasHeight - refHeight;
        } else if (this.workResOffset > 0) {
            this.workResOffset = 0;
        }
    }

    getDisplayedMinutes() {
        return this.workStartTime.getDistanceInMinutes(this.workEndTime);
    }

    animateTo(startLCal, endLCal, animationCompletedCB) {
        if (!this.isInMovement()) {
            //Das hier nur zur Sicherheit. Eigentlich sollte das immer von pan vorher aufgerufen werden.
            this.beforeMovement();
        }

        if (this.slideTimeoutHandle !== 0) {
            clearTimeout(this.slideTimeoutHandle);
        }

        if (!endLCal) {
            //Nur das isSwiping-Flag setzen, falls nicht gezoomt wird (falls keine Ende-Zeit angegeben wird)
            this.isSwiping = true;
            let dist = this.workStartTime.getDistanceInMinutes(this.workEndTime);
            endLCal = startLCal.clone();
            endLCal.addMinutes(dist);
        }

        //Je nach Anzahl der Balken wird entweder animiert, oder gleich ohne Animation gezoomed
        this._animateTo(startLCal, endLCal,  0, 10, animationCompletedCB);
    }

    _animateTo(targetStartLCal, targetEndLCal, step, totalSteps, animationCompletedCB) {
        let SELF = this;
        clearTimeout(this.animationTimeoutHandle);

        if (step < totalSteps) {
            //Zunächst eine ganz einfache Funktion: Wie viele Steps habe ich noch prozentual, so viel nähere ich mich dem Ziel an
            const startStepWidth = this.workStartTime.getDistanceInMinutes(targetStartLCal) / (totalSteps - step);
            const endStepWidth = this.workEndTime.getDistanceInMinutes(targetEndLCal) / (totalSteps - step);

            this.workStartTime.setJulianMinutes(this.workStartTime.getJulianMinutes() + startStepWidth);
            this.workEndTime.setJulianMinutes(this.workEndTime.getJulianMinutes() + endStepWidth);
            this.offsetResetted();
            this._updateCanvas();
            this._fireZoomChanged();
            this._fireOffsetChanged();

            this.animationTimeoutHandle = setTimeout(function () {
                step++;
                SELF._animateTo(targetStartLCal, targetEndLCal, step, totalSteps, animationCompletedCB);
            }, 17);
        } else {
            this.isSwiping = false;
            this._updateCanvas();
            if(animationCompletedCB) {
                animationCompletedCB();
            }
        }
    }

    _fireZoomChanged() {
        this.props.onZoomChange && this.props.onZoomChange(this.workStartTime, this.workEndTime);
        if (this.props.longlabels) {
            this.props.model._setDisplayDataDirty(true);
        }
    }

    _fireClickCallback(timelineEvt) {
        this.props.onClick && this.props.onClick(timelineEvt);
    }

    _fireMouseMoveCallback(timelineEvt) {
        this.props.onMouseMove && this.props.onMouseMove(timelineEvt);
    }

    _firePressCallback(timelineEvt) {
        this.props.onPress && this.props.onPress(timelineEvt);
    }

    _fireLongPressCallback(timelineEvt) {
        this.props.onLongPress && this.props.onLongPress(timelineEvt);
    }

    _fireToolTip(timelineEvt) {
        this.props.onToolTip && this.props.onToolTip(timelineEvt);
    }

    _fireOffsetChanged() {
        this.props.onOffsetChange && this.props.onOffsetChange(this.workStartTime, this.workEndTime, this.workResOffset);
    }


    zoomToDisplayMinutes(newDuration) {
        super.zoomToDisplayMinutes(newDuration);
        this._fireOffsetChanged();
    }

    zoom(delta, offsetFromStart) {
        var timeForPixel = this.getTimeForXPos(offsetFromStart);

        let scale = 1 - Math.min(9, Math.abs(delta)) * 0.1;
        if (delta < 0) {
            scale = 1 / scale;
        }

        let zoomTotalTime = Math.round((this.workEndTime.getJulianMinutes() - this.workStartTime.getJulianMinutes()) / scale);

        if (zoomTotalTime > 1 && zoomTotalTime < 30000000000000000) {
            super.zoom(delta, offsetFromStart);

            var origDurationToCenter = timeForPixel - this.workStartTime.getJulianMinutes();

            var newStart = this.workStartTime.getJulianMinutes() - origDurationToCenter / scale + origDurationToCenter;

            this.workStartTime.setJulianMinutes(newStart);
            this.workEndTime.setJulianMinutes(newStart + zoomTotalTime);

            this.offsetResetted();

            this._updateCanvas();

            this._fireZoomChanged();
            this._fireOffsetChanged();
        }
    }

    startPinch(center) {
        super.startPinch(center);
        this.centerPinchTime = this.getTimeForXPos(center);
    }

    pinch(scale) {
        let zoomTotalTime = Math.round((this.canvasEndTime.getJulianMinutes() - this.canvasStartTime.getJulianMinutes()) / scale);

        let timeToCenter = this.centerPinchTime - this.canvasStartTime.getJulianMinutes();
        let newStart = this.canvasStartTime.getJulianMinutes() - timeToCenter / scale + timeToCenter;
        let newEnd = newStart + zoomTotalTime;

        this.workStartTime.setJulianMinutes(newStart);
        this.workEndTime.setJulianMinutes(newEnd);

        this._fireZoomChanged();
        this._updateCanvas();
    }

    endPinch() {
        super.endPinch();
        this.offsetResetted();
        this._fireZoomChanged();
    }



    /*setModel(model) {
      this.model = model;
      this._updateCanvas();
      let SELF = this;
      this.model.addDataChangeCallback(()=>SELF._updateCanvas());
    }*/


    beforeMovement() {
        //Merken der x und y-Position
        this.beforeMovementJulMin = this.workStartTime.getJulianMinutes();
        this.beforeMovementY = this.workResOffset;
        this.lastFullPaintTime = Date.now();
    }

    paint() {
        let SELF = this;
        //TODO: Prüfen, ob alles neu gezeichnet werden muss
        this.ctx.clearRect(0, 0, this.virtualCanvasWidth, this.virtualCanvasHeight);

        //Header für die Timeline zeichnen
        this.ctx.fillStyle = timelineHeaderColor;
        this.ctx.fillRect(this.resourceHeaderHeight, 0, this.virtualCanvasWidth - this.resourceHeaderHeight, this.timelineHeaderHeight);
        this.ctx.fillRect(0, 0, this.resourceHeaderHeight, this.virtualCanvasHeight);

        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.rect(this.resourceHeaderHeight, 0, this.virtualCanvasWidth - this.resourceHeaderHeight, this.virtualCanvasHeight);
        this.ctx.clip();

        //this.paintResourcesIntervals(this.ctx);

        //Bestimmen der Skala, die gezeichnet werden soll
        var minutesPerPixel = this.getMinutesPerPixel();

        if (minutesPerPixel < 0.2) {
            //Stundenskala
            this.paintGrid(this.ctx, this.workStartTime, this.workEndTime, function (time) {
                return new LCal().initYMDHM(time.getYear(), time.getMonth(), time.getDay(), time.getHour(), 0, SELF.timeZone)
            }, function (time) {
                return time.addHour(1)
            }, function (time) {
                return time.addMinutes(10)
            }, function (time) {
                //return LCalFormatter.formatMonthNameL(time) + " " + time.getYear();
                return LCalFormatter.formatDayName(time) + ", " + time.getDay() + ". " + LCalFormatter.formatMonthName(time) + " " + LCalFormatter.formatYear(time) + " " + time.getHour() + " Uhr";
            }, function (time, index) {
                return time.getMinute();
            }, function (time, isMainScale) {
                if (isMainScale) {
                    let dayInWeek = LCalHelper.getDayInWeek(time);
                    if (dayInWeek === 5) {
                        return saturdayColor;
                    } else if (dayInWeek === 6) {
                        return sundayColor;
                    }
                }
                return undefined;
            });
        } else if (minutesPerPixel < 10) {
            //Tagesskala
            this.paintGrid(this.ctx, this.workStartTime, this.workEndTime, function (time) {
                return new LCal().initYMDHM(time.getYear(), time.getMonth(), time.getDay(), 0, 0, SELF.timeZone)
            }, function (time) {
                return time.addDay(1)
            }, function (time) {
                return time.addHour(1)
            }, function (time) {
                return LCalFormatter.formatDayName(time) + ", " + time.getDay() + ". " + LCalFormatter.formatMonthNameL(time) + " " + LCalFormatter.formatYear(time);
                //return time.getDay();
            }, function (time, index) {
                if (minutesPerPixel < 4 || ((index) % 5 === 0)) {
                    return time.getHour();
                } else {
                    return "";
                }
            }, function (time, isMainScale) {
                if (!isMainScale) {
                    let dayInWeek = LCalHelper.getDayInWeek(time);
                    if (dayInWeek === 5) {
                        return saturdayColor;
                    } else if (dayInWeek === 6) {
                        return sundayColor;
                    }
                }
                return undefined;
            });
        } else if (minutesPerPixel < 300) {
            //Monatsskala
            this.paintGrid(this.ctx, this.workStartTime, this.workEndTime, function (time) {
                return new LCal().initYMDHM(time.getYear(), time.getMonth(), 1, 0, 0, SELF.timeZone)
            }, function (time) {
                return time.addMonth(1);
            }, function (time) {
                return time.addDay(1)
            }, function (time) {
                return LCalFormatter.formatMonthNameL(time) + " " + LCalFormatter.formatYear(time);
            }, function (time, index) {
                if (minutesPerPixel < 120 || ((index + 1) % 5 === 0)) {
                    return time.getDay();
                } else {
                    return "";
                }
            }, function (time, isMainScale) {
                if (!isMainScale) {
                    let dayInWeek = LCalHelper.getDayInWeek(time);
                    if (dayInWeek === 5) {
                        return saturdayColor;
                    } else if (dayInWeek === 6) {
                        return sundayColor;
                    }
                }
                return undefined;
            });
        } else if (minutesPerPixel < 10000) {
            //Jahresskala
            this.paintGrid(this.ctx, this.workStartTime, this.workEndTime, function (time) {
                return new LCal().initYMDHM(time.getYear(), 1, 1, 0, 0, SELF.timeZone)
            }, function (time) {
                return time.addYear(1)
            }, function (time) {
                return time.addMonth(1)
            }, function (time) {
                return LCalFormatter.formatYear(time);
            }, function (time, index) {
                if (minutesPerPixel > 5000) {
                    if (index % 2 === 0) {
                        return LCalFormatter.formatMonthNameS(time);
                    } else {
                        return "";
                    }
                } else if (minutesPerPixel > 1700) {
                    return LCalFormatter.formatMonthNameS(time);
                } else {
                    return LCalFormatter.formatMonthName(time);
                }
            }, function (time) {
                return undefined;
            });
        } else {

            var yearStepWidth = Math.pow(10, (Math.floor(minutesPerPixel / 400) + "").length - 1);
            this.paintGrid(this.ctx, this.workStartTime, this.workEndTime, function (time) {
                var startYear = time.getYear();
                startYear = startYear - (startYear % yearStepWidth) - (startYear <= 0 ? yearStepWidth : 0);
                if (startYear === 0) {
                    startYear = 1;
                }
                return new LCal().initYMDHM(startYear, 1, 1, 0, 0, SELF.timeZone)
            }, function (time) {
                if (time.getYear() === 1) {
                    time.addYear(yearStepWidth - 1);
                } else {
                    time.addYear(yearStepWidth);
                }
                return time;
            }, function (time) {
                if (time.getYear() === 1) {
                    time.addYear(yearStepWidth / 10 - 1 === 0 ? 1 : yearStepWidth / 10 - 1);
                } else {
                    time.addYear(yearStepWidth / 10);
                }
                return time;
            }, function (time) {
                return LCalFormatter.formatYear(time);
            }, function (time, index) {
                let mDivY = Math.floor(minutesPerPixel / yearStepWidth);
                if (mDivY > 550) {
                    if (mDivY > 1500) {
                        if (index % 5 === 0) {
                            return LCalFormatter.formatYear(time);
                        } else {
                            return "";
                        }
                    } else {
                        if (index % 2 === 0) {
                            return LCalFormatter.formatYear(time);
                        } else {
                            return "";
                        }
                    }
                } else {
                    return LCalFormatter.formatYear(time);
                }
            }, function (time) {
                return undefined;
            });
        }

        this.ctx.restore();



        //RessourcenIntervalle zeichnen
        this.ctx.save();
        this.ctx.rect(this.resourceHeaderHeight, this.timelineHeaderHeight, this.virtualCanvasWidth - this.resourceHeaderHeight, this.virtualCanvasHeight - this.timelineHeaderHeight);
        this.ctx.clip();

        //Wenn das Canvas bewegt werden soll und das letzte Zeichnen mehr als x Millisekunden gedauert hat, dann nur die Kopie aus dem Offscreen verschieben
        this.ctx.lineWidth = 1;
        this.paintTasks(this.ctx);
        this.paintMovedTasks(this.ctx);
        this.ctx.lineWidth = 1;
        //Zeichnen der Messlineale

        this.paintMeasureSliders(this.ctx);

        //aktuelle Zeit zeichnen
        let now = LCalHelper.getNowMinutes();
        let x = this.getXPosForTime(now);
        this.ctx.strokeStyle = "#FF0000";
        this.ctx.beginPath();
        this.ctx.moveTo(x, this.timelineHeaderHeight);
        this.ctx.lineTo(x, this.props.horizontalOrientation ? this.ctx.canvas.height : this.ctx.canvas.width);
        this.ctx.stroke();

        this.ctx.restore();

        this.paintScrollBar(this.ctx);

        //TODO: prüfen, ob sich etwas geändert hat, und das überhaupt neu gezeichnet werden muss
        //Ressourcen zeichnen
        this.ctx.save();
        this.ctx.rect(0, this.timelineHeaderHeight, this.virtualCanvasWidth, this.virtualCanvasHeight - this.timelineHeaderHeight);
        this.ctx.clip();
        this.paintResources(this.ctx);
        this.ctx.restore();

        /*let paintTime = (new Date().getTime()) - currentTime.getTime();

        if(!paintOffscreenImage) {
            //console.log("paintTime: " + paintTime + "  useOffscreen = " + useOffscreen + ", timeoutPaint = " + timeoutPaint);
        }*/
    }

    /**
     * Liefert die TaskBarBounds um die TaskLevel im TaskModel zu berechnen.
     * Hier gibt es keinen alignedStart. Der Balken wird für sich betrachtet -> was wäre die optimale Darstellung, wenn der Balken voll angezeigt werden kann?
     * @param task
     */
    getTaskBarBoundsForLevelComputation(task) {
        let startX = this.getXPosForTime(this.props.model.getDisplayedStart(task).getJulianMinutes());
        let endX = this.getXPosForTime(this.props.model.getDisplayedEnd(task).getJulianMinutes());

        let labelStartX = startX;
        if (task.isPointInTime()) {
            startX -= SINGLEEVENTRADIUS;
            endX += SINGLEEVENTRADIUS;
            labelStartX = startX + 2 * SINGLEEVENTRADIUS; //Bei Zeitpunkt-Ereignissen beginnt das Label nach dem Punkt
        }

        //Falls kein Start vorhanden, dann noch mal für den Pfeil etwas draufschalgen. Genauso, wenn kein Ende vorhanden ist
        if (task.getStart() === null) {
            startX -= ARROWHEADLENGTH;
        }
        if (task.getEnd() === null) {
            endX += ARROWHEADLENGTH;
        }

        const icon = this.props.model.getIcon(task);

        let imgWidth = 0;
        let imgHeight = 0;
        let imgEnd = startX;
        if (icon && icon.width && icon.height) {
            if (this.props.horizontalOrientation) {
                imgHeight = task.getDisplayData().getHeight() - 2 * TASKBARINSET;
                imgWidth = icon.width * imgHeight / icon.height;
                imgEnd = Math.min(startX + imgWidth, endX);
            } else {
                imgWidth = task.getDisplayData().getHeight() - 2 * TASKBARINSET;
                imgHeight = icon.height * imgWidth / icon.width;
                imgEnd = Math.min(startX + imgHeight, endX);
            }
        }

        if (this.props.model.getDisplayedStart(task).getJulianMinutes() !== this.props.model.getDisplayedEnd(task).getJulianMinutes()) {
            labelStartX = imgEnd;
        }

        let labelEndX = labelStartX + 50;
        let labelArr;
        let maxWidth = 0;

        this.ctx.font = timelineBarHeaderFont;
        const taskLabel = task.getName() && task.getName().length > 0 ? task.getName() : (task.secname ? task.secname : "");

        if (this.props.horizontalOrientation) {
            labelEndX = labelStartX + Helper.textWidthFromCache(taskLabel, this.ctx) + 50; //50 Puffer z.B. wenn der Nachfolger ein Ereignis ist
        } else {
            let maxLabelWidth = task.getDisplayData().getHeight() - 5 - 2 * TASKBARINSET;

            labelArr = taskLabel ? Helper.textToArrayFromCache(taskLabel, maxLabelWidth, this.ctx) : [];

            //Die Anzahl der Zeilen bestimmt die Länge des Labels im vertikalen Fall
            maxWidth = 6 + LABEL_LINE_HEIGHT * labelArr.length;

            labelEndX = labelStartX + maxWidth + 10;
            if (!this.props.longlabels) {
                labelEndX = Math.min(endX, labelEndX);
            }
            if (task.isPointInTime()) {
                labelEndX += 20;
            }
        }

        return new LevelComputationTaskBarBounds(startX, Math.max(endX, labelEndX));
    }

    /**
     * Liefert die TaskBarBounds, die zum Anzeigen benötigt werden. Hier wird der alignedStart berücksichtigt
     * @param task
     */
    getTaskBarBounds(task) {
        let startX = this.getXPosForTime(this.props.model.getDisplayedStart(task).getJulianMinutes());
        let endX = task.isPointInTime() ? startX : this.getXPosForTime(this.props.model.getDisplayedEnd(task).getJulianMinutes());

        const alignedStart = startX < this.resourceHeaderHeight - 1 && endX > this.resourceHeaderHeight ? this.resourceHeaderHeight - 1 : startX;

        let labelStartX = alignedStart;
        if (task.isPointInTime()) {
            startX -= SINGLEEVENTRADIUS;
            endX += SINGLEEVENTRADIUS;
            labelStartX = startX + 2 * SINGLEEVENTRADIUS; //Bei Zeitpunkt-Ereignissen beginnt das Label nach dem Punkt
        }

        //Falls kein Start vorhanden, dann noch mal für den Pfeil etwas draufschalgen. Genauso, wenn kein Ende vorhanden ist
        if (!task.getStart()) {
            startX -= ARROWHEADLENGTH;
        }
        if (!task.getEnd()) {
            endX += ARROWHEADLENGTH;
        }

        const icon = this.props.model.getIcon(task);

        let imgWidth = 0;
        let imgHeight = 0;
        let imgEnd = alignedStart;
        if (icon && icon.width && icon.height) {
            if (this.props.horizontalOrientation) {
                imgHeight = task.getDisplayData().getHeight() - 2 * TASKBARINSET;
                imgWidth = icon.width * imgHeight / icon.height;
                imgEnd = Math.min(alignedStart + imgWidth, endX);
            } else {
                imgWidth = task.getDisplayData().getHeight() - 2 * TASKBARINSET;
                imgHeight = icon.height * imgWidth / icon.width;
                imgEnd = Math.min(alignedStart + imgHeight, endX);
            }
        }

        if (this.props.model.getDisplayedStart(task).getJulianMinutes() !== this.props.model.getDisplayedEnd(task).getJulianMinutes()) {
            labelStartX = imgEnd;
        }

        let labelEndX = labelStartX + 50;
        let labelArr;
        let maxWidth = 0;

        this.ctx.font = timelineBarHeaderFont;
        const taskLabel = task.getName() && task.getName().length > 0 ? task.getName() : task.secname;

        if (this.props.horizontalOrientation) {
            //this.props.longlabels: Wenn das Label nicht komplett einzeilig in den Balken passt, dann darf es maximal bis zum Successor oder dem Ende des Bildschirms gehen
            let maxLabelEndX = this.virtualCanvasWidth;
            if (task.getDisplayData().getSuccessor()) {
                maxLabelEndX = this.getXPosForTime(this.props.model.getDisplayedStart(task.getDisplayData().getSuccessor()).getJulianMinutes());
            }
            const maxLabelWidth = maxLabelEndX - labelStartX - 5;

            labelArr = taskLabel ? Helper.textToArrayFromCache(taskLabel, maxLabelWidth, this.ctx) : [];

            //Der längste Text im Array bestimmt die Länge des Labels im horizontalen Fall
            for (let a of labelArr) {
                let w = Helper.textWidthFromCache(a, this.ctx);
                if (w > maxWidth) {
                    maxWidth = w;
                }
            }

            labelEndX = Math.max(labelEndX, labelStartX + maxWidth + 10);
        } else {
            let maxLabelWidth = task.getDisplayData().getHeight() - 6 - 2 * TASKBARINSET;

            labelArr = taskLabel ? Helper.textToArrayFromCache(taskLabel, maxLabelWidth, this.ctx) : [];

            let maxLabelEndX = this.virtualCanvasWidth;
            if (task.getDisplayData().getSuccessor()) {
                maxLabelEndX = this.getXPosForTime(this.props.model.getDisplayedStart(task.getDisplayData().getSuccessor()).getJulianMinutes());
            }
            //Die Anzahl der Zeilen bestimmt die Länge des Labels im vertikalen Fall
            maxWidth = Math.min(maxLabelEndX - labelStartX - 10, 6 + LABEL_LINE_HEIGHT * labelArr.length);

            labelEndX = labelStartX + maxWidth + 10;
            if (!this.props.longlabels) {
                labelEndX = Math.min(endX, labelEndX);
            }
        }

        return new TaskBarBounds(startX, endX, labelStartX, labelEndX, labelArr);
    }

    //Liefert ein ResourceInerval, falls sich an dieser Position eines befindet
    getTask(x, y) {
        if (x > this.resourceHeaderHeight && y > this.timelineHeaderHeight) {

            this.props.model.recomputeDisplayData(this.getTaskBarBoundsForLevelComputation);

            for (let n = 0; n < this.props.model.size(); n++) {
                let task = this.props.model.getItemAt(n);

                let tbb = this.getTaskBarBounds(task);
                let xStart = tbb.barStartX;
                if (xStart <= this.virtualCanvasWidth) {
                    let xEnd = Math.max(tbb.labelEndX, tbb.barEndX);
                    if (xEnd > this.resourceHeaderHeight) {
                        let resStartY = this.timelineHeaderHeight + task.getDisplayData().getRelativeYStart() + this.workResOffset;

                        if (x >= xStart && x <= xEnd && y >= resStartY && y <= (resStartY + task.getDisplayData().getHeight())) {
                            return task;
                        }
                    }
                }
            }
        }
        return null;
    }

    getResource(y) {
        if (this.props.model !== undefined && y > this.timelineHeaderHeight) {
            let resModel = this.props.model.getResourceModel();

            resModel.recomputeDisplayData();

            for (let n = 0; n < resModel.size(); n++) {
                let res = resModel.getItemAt(n);
                let relResStartY = res.getDisplayData().getRelativeYStart();

                let resStartY = this.timelineHeaderHeight + relResStartY + this.workResOffset;

                if (y >= resStartY && y < resStartY + res.getDisplayData().getHeight()) {
                    return res;
                }
            }
        }
        return null;
    }

    scrollToResource(res) {
        if(res) {
            let resModel = this.props.model.getResourceModel();
            resModel.recomputeDisplayData();
            const relResStartY = res.getDisplayData().getRelativeYStart();
            //const height = res.getDisplayData().getHeight();

            //Immer auf die untere Basis der Timeline scrollen, falls diese höher ist als die verfügbare Höhe
            let hightOverlap = res.getDisplayData().getHeight() + this.timelineHeaderHeight - this.virtualCanvasHeight;
            if(hightOverlap < 0) {
               hightOverlap = 0;
            }
            this.offsetY  =  - relResStartY - this.resOffset - hightOverlap;
            this.offsetChanged();
            this.offsetY = 0;
            this.offsetResetted();
        }
    }

    paintTaskBar(ctx, task, col, fill) {
        let xStart = this.getXPosForTime(this.props.model.getDisplayedStart(task).getJulianMinutes());
        if (xStart <= this.virtualCanvasWidth) {
            let xEnd = this.getXPosForTime(this.props.model.getDisplayedEnd(task).getJulianMinutes());
            if (xEnd > this.resourceHeaderHeight) {
                let resStartY = this.timelineHeaderHeight + task.getDisplayData().getRelativeYStart() + this.workResOffset;

                if (resStartY + task.getDisplayData().getHeight() - TASKBARINSET> this.timelineHeaderHeight && resStartY < this.virtualCanvasHeight) {
                    let mode = 0;
                    if (task.getStart() && !task.getEnd()) {
                        mode = -1;
                    } else if (task.getEnd() && !task.getStart()) {
                        mode = 1;
                    } else if ((task.isPointInTime())) {
                        mode = 2;
                    }
                    this.paintBar(ctx, col, xStart, xEnd, resStartY + TASKBARINSET, task.getDisplayData().getHeight() - TASKBARINSET*2, mode, SINGLEEVENTRADIUS, false, fill);
                    if (fill) {
                        let alignedStart = xStart < this.resourceHeaderHeight - 1 ? this.resourceHeaderHeight - 1 : xStart;
                        this.paintIcon(ctx, task, alignedStart, resStartY);
                    }
                    this.paintInnerTasks(ctx, resStartY + task.getDisplayData().getHeight() / 2 , task.getDisplayData().getHeight() / 2 - TASKBARINSET, task.innerEvents, col ? innerEventColor : null, xStart, xEnd);
                }
            }
        }
    }

    paintFuzzyTaskBarTimes(ctx, task) {
        let displStart = this.props.model.getDisplayedStart(task);
        let startPrecision = task.getStart() ? task.getStart().getPrecision() : 14;
        let displEnd = this.props.model.getDisplayedEnd(task);
        let endPrecision = task.getEnd() ? task.getEnd().getPrecision() : 14;
        if (startPrecision < 11 || endPrecision < 11) {
            let xStart = this.getXPosForTime(this.props.model.getDisplayedStart(task).getJulianMinutes());
            if (xStart <= this.virtualCanvasWidth) {
                let xEnd = this.getXPosForTime(this.props.model.getDisplayedEnd(task).getJulianMinutes());
                if (xEnd > this.resourceHeaderHeight) {
                    let resStartY = this.timelineHeaderHeight + task.getDisplayData().getRelativeYStart() + this.workResOffset;

                    if (resStartY + task.getDisplayData().getHeight() > this.timelineHeaderHeight && resStartY < this.virtualCanvasHeight) {
                        let gradientWidth = Math.min(40, Math.abs((this.getXPosForTime(displEnd.getJulianMinutes()) - this.getXPosForTime(displStart.getJulianMinutes())) / 2));
                        if (startPrecision < 11) {
                            let alignedStart = xStart < this.resourceHeaderHeight - 1 ? this.resourceHeaderHeight - 1 : xStart;

                            let objGradient = ctx.createLinearGradient(alignedStart - 1, 0, alignedStart + gradientWidth, 0);
                            // Verlaufspunkte setzen (gelb-&gt;rot-&gt;blau-&gt;gruen)
                            objGradient.addColorStop(0, '#FFF');
                            objGradient.addColorStop(1, 'rgba(255,255,255,0)');

                            // Füll-Style mit Gradient auszeichnen
                            ctx.fillStyle = objGradient;
                            ctx.fillRect(alignedStart - 1, resStartY + TASKBARINSET, gradientWidth, task.getDisplayData().getHeight() - 2*TASKBARINSET);
                        }

                        if (endPrecision < 11) {
                            let alignedEnd = xEnd > this.virtualCanvasWidth + 1 ? this.virtualCanvasWidth + 1 : xEnd;

                            let objGradient = ctx.createLinearGradient(alignedEnd - gradientWidth, 0, alignedEnd, 0);
                            // Verlaufspunkte setzen (gelb-&gt;rot-&gt;blau-&gt;gruen)
                            objGradient.addColorStop(0, 'rgba(255,255,255,0)');
                            objGradient.addColorStop(1, '#FFF');


                            // Füll-Style mit Gradient auszeichnen
                            ctx.fillStyle = objGradient;
                            ctx.fillRect(alignedEnd - gradientWidth, resStartY + TASKBARINSET, gradientWidth + 1, task.getDisplayData().getHeight() - 2*TASKBARINSET);
                        }
                    }
                }
            }
        }
    }

    paintInnerTasks(ctx, resStartY, height, innerEvents, col, minStart, maxEnd) {
        //Für jedes innerEvent auch noch einen Balken innerhalb zeichnen
        if (innerEvents) {
            for (let innerT of innerEvents) {
                let xStart = this.getXPosForTime(this.props.model.getDisplayedStart(innerT).getJulianMinutes());
                if (xStart <= this.virtualCanvasWidth) {
                    let xEnd = this.getXPosForTime(this.props.model.getDisplayedEnd(innerT).getJulianMinutes());
                    //console.log(xStart + " bis " + xEnd);
                    if (xEnd > this.resourceHeaderHeight) {
                        let mode = 0;
                        if (innerT.getStart() && !innerT.getEnd()) {
                            mode = -1;
                            maxEnd -= 20;
                        } else if (innerT.getEnd() && !innerT.getStart()) {
                            mode = 1;
                            minStart += 20;
                        } else if (this.props.model.getDisplayedStart(innerT).getJulianMinutes() === this.props.model.getDisplayedEnd(innerT).getJulianMinutes()) {
                            mode = 2;
                        }

                        this.paintBar(ctx, col, Math.max(xStart, minStart), Math.min(xEnd, maxEnd), resStartY, height, mode, SINGLEEVENTRADIUS / 2, true, true);
                    }
                }
            }
        }
    }

    paintBar(ctx, col, xStart, xEnd, resStartY, height, mode, singleEventRadius, isInnerEvent, fill) {
        if (fill) {
            ctx.beginPath();
        }

        let alignedStart = xStart < this.resourceHeaderHeight - 1 ? this.resourceHeaderHeight - 1 : xStart;
        let alignedEnd = xEnd > this.virtualCanvasWidth + 1 ? this.virtualCanvasWidth + 1 : xEnd;
        let halfHeight = Math.round(height / 2);

        switch (mode) {
            //Start gegeben, aber kein Ende?
            case -1:
                if (isInnerEvent) {
                    ctx.moveTo(alignedStart, resStartY);
                    ctx.lineTo(alignedEnd + ARROWHEADLENGTH, resStartY);
                    ctx.lineTo(alignedEnd, resStartY + height);
                    ctx.lineTo(alignedStart, resStartY + height);
                    ctx.lineTo(alignedStart, resStartY);
                } else {
                    ctx.moveTo(alignedStart, resStartY);
                    ctx.lineTo(alignedEnd, resStartY);
                    ctx.lineTo(alignedEnd + ARROWHEADLENGTH, resStartY + halfHeight);
                    ctx.lineTo(alignedEnd, resStartY + height);
                    ctx.lineTo(alignedStart, resStartY + height);
                    ctx.lineTo(alignedStart, resStartY);
                }
                if (col) {
                    ctx.fillStyle = col;
                    ctx.fill();
                }
                break;
            case 1:
                //Ende gegeben, aber kein Start?
                if (isInnerEvent) {
                    ctx.moveTo(alignedEnd, resStartY);
                    ctx.lineTo(alignedStart - ARROWHEADLENGTH, resStartY);
                    ctx.lineTo(alignedStart, resStartY + height);
                    ctx.lineTo(alignedEnd, resStartY + height);
                    ctx.lineTo(alignedEnd, resStartY);
                } else {
                    ctx.moveTo(alignedEnd, resStartY);
                    ctx.lineTo(alignedStart, resStartY);
                    ctx.lineTo(alignedStart - ARROWHEADLENGTH, resStartY + halfHeight);
                    ctx.lineTo(alignedStart, resStartY + height);
                    ctx.lineTo(alignedEnd, resStartY + height);
                    ctx.lineTo(alignedEnd, resStartY);
                }
                if (col) {
                    ctx.fillStyle = col;
                    ctx.fill();
                }
                break;
            case 2:
                //Zeitpunkt zeichnen
                ctx.moveTo(alignedStart - halfHeight, resStartY + halfHeight);
                ctx.arc(alignedStart, resStartY + halfHeight, halfHeight, Math.PI, 4 * Math.PI);
                if (fill) {
                    ctx.fillStyle = col;
                    ctx.fill();
                }
                break;
            default:
                if (xEnd - xStart <= 1) {
                    ctx.moveTo(alignedStart, resStartY);
                    ctx.lineTo(alignedStart, resStartY + height);
                } else {
                    ctx.rect(alignedStart, resStartY, alignedEnd - alignedStart, height);
                    if (col) {
                        ctx.fillStyle = col;
                        ctx.fillRect(alignedStart, resStartY, alignedEnd - alignedStart, height);
                    }
                }
        }
    }

    paintIcon(ctx, task, alignedStart, resStartY) {
        const icon = this.props.model.getIcon(task);
        if (icon) {
            ctx.save();
            ctx.clip();
            try {
                if (this.props.horizontalOrientation) {
                    let imgHeight = task.getDisplayData().getHeight() - 2 * TASKBARINSET;
                    let imgWidth = icon.width * imgHeight / icon.height;
                    if (task.isPointInTime()) {
                        ctx.drawImage(icon, alignedStart - imgWidth/2, resStartY + TASKBARINSET, imgWidth, imgHeight);
                    } else {
                        ctx.drawImage(icon, alignedStart, resStartY + TASKBARINSET, imgWidth, imgHeight);
                    }
                } else {
                    let imgWidth = task.getDisplayData().getHeight() - 2 * TASKBARINSET;
                    let imgHeight = icon.height * imgWidth / icon.width;
                    ctx.translate(alignedStart + imgWidth, resStartY + imgWidth + TASKBARINSET);
                    ctx.rotate(-Math.PI / 2);
                    if (task.isPointInTime()) {
                        ctx.drawImage(icon, 0, -imgWidth - 20, imgWidth, imgHeight);
                    } else {
                        ctx.drawImage(icon, 0, -imgWidth, imgWidth, imgHeight);
                    }
                }
            } catch (e) {
            }
            ctx.restore();
        }

    }

    formatBarDate(d) {
        if (this.props.dateFormatter) {
            return this.props.dateFormatter(d);
        }
        return LCalFormatter.formatDate(d, true);
    }

    paintTaskBarLabel(ctx, task) {
        const tbb = this.getTaskBarBounds(task);
        const txtXStart = tbb.lableStartX;
        if (txtXStart <= this.virtualCanvasWidth) {
            let txtXEnd = tbb.labelEndX;
            let tWidth = this.props.horizontalOrientation ? txtXEnd - txtXStart - 4 : task.getDisplayData().getHeight() - 6 - 2 * TASKBARINSET;
            let tHeight = this.props.horizontalOrientation ? task.getDisplayData().getHeight() - 5 - 2 * TASKBARINSET : txtXEnd - txtXStart - 4;

            if (txtXEnd > this.resourceHeaderHeight && tWidth > 20) {
                let resStartY = this.timelineHeaderHeight + task.getDisplayData().getRelativeYStart() + this.workResOffset + TASKBARINSET;

                if (tHeight > 10 && resStartY + task.getDisplayData().getHeight() + TASKBARINSET > this.timelineHeaderHeight && resStartY < this.virtualCanvasHeight) {
                    ctx.save();

                    ctx.font = timelineBarHeaderFont;
                    let labelArr = tbb.labelArray;

                    const lStart = task.getStart();
                    const lEnd = task.getEnd();

                    if (this.props.horizontalOrientation) {
                        //Falls das Label über den Balken hinausgeht, dann einen grauen Hintergrund zeichnen
                        let maxLabelLines = Math.min(labelArr.length, Math.floor((task.getDisplayData().getHeight() - 2 * TASKBARINSET - 6) / LABEL_LINE_HEIGHT));
                        let startTimeLabelWidth = 0;
                        let startTimeLabel;
                        let textYPosOffset = LABEL_LINE_HEIGHT;
                        if (lStart) {
                            startTimeLabel = this.formatBarDate(lStart);
                            startTimeLabelWidth = Helper.textWidthFromCache(startTimeLabel, ctx);
                        }
                        if(maxLabelLines > 0) {
                            textYPosOffset = LABEL_LINE_HEIGHT * maxLabelLines;
                            if (tbb.hasLongLabel() && labelArr) {
                                ctx.fillStyle = labelBGColor;
                                //Wie viele Labelzeilen können angezeigt werden?
                                ctx.fillRect(txtXStart, resStartY, tWidth + 2, textYPosOffset + 6);
                                textYPosOffset += 2;
                            }

                            ctx.font = timelineBarHeaderFont;
                            ctx.fillStyle = tbb.hasLongLabel() ? "#000" : task.getDisplayData().getLabelColor();
                            if (labelArr) {
                                for (let i = 0; i < maxLabelLines; ++i) {
                                    ctx.fillText(labelArr[i], txtXStart + 5, resStartY + (i + 1) * LABEL_LINE_HEIGHT);
                                }
                            }
                        }

                            //Ab jetzt nur innerhalb des Balkens zeichnen (falls kein Zeitpunkt)
                            if (!task.isPointInTime()) {
                                ctx.beginPath();
                                ctx.rect(tbb.barStartX, resStartY, tbb.barEndX - tbb.barStartX, task.getDisplayData().getHeight());
                                ctx.clip();
                            }


                        //Noch Platz für den secname? Der Secname ist immer maximal so lang wie der Balken.
                        if (maxLabelLines > 0 &&
                            textYPosOffset + SECLABEL_LINE_HEIGHT < task.getDisplayData().getHeight() - TASKBARINSET - 2 * TASKBARINSET
                            && !task.isPointInTime()) {
                                let maxSecLabelLen = Math.min(tbb.barEndX, this.virtualCanvasWidth) - tbb.lableStartX;
                                this.ctx.fillStyle = task.getDisplayData().getSecLabelColor();
                                ctx.font = timelineBarSubHeaderFont;
                                let secLabelArr = task.secname === null || !(task.getName() && task.getName().length > 0) ? "" : Helper.textToArrayFromCache(task.secname, maxSecLabelLen, ctx); //Falls kein name gegeben ist, dann wird sowieso der secname schon als name angezeigt

                                for (let i = 0; i < secLabelArr.length; ++i) {
                                    textYPosOffset += SECLABEL_LINE_HEIGHT;
                                    if (textYPosOffset > task.getDisplayData().getHeight() - 2 * TASKBARINSET) {
                                        break;
                                    }
                                    ctx.fillText(secLabelArr[i], txtXStart + 5, resStartY + textYPosOffset);
                                }
                        }


                        //Noch genügend Platz um Uhrzeiten zu zeichnen?
                        if (textYPosOffset + SECLABEL_LINE_HEIGHT < task.getDisplayData().getHeight() -  2 * TASKBARINSET) {
                            let timesYPos = resStartY + task.getDisplayData().getHeight() - 2 * TASKBARINSET - 4;
                            ctx.font = timelineBarSubHeaderFont;
                            this.ctx.fillStyle = task.getDisplayData().getSecLabelColor();
                            let startTimeWidth = -5;
                            if (lStart) {
                                startTimeWidth = startTimeLabelWidth;
                                ctx.fillText(startTimeLabel, txtXStart + 5 + (task.isPointInTime() ? 5 : 0), timesYPos);
                            }
                            if (lEnd) {
                                let endTimeLabel = this.formatBarDate(lEnd); //
                                let endTimeWidth = Helper.textWidthFromCache(endTimeLabel, ctx);
                                if (tbb.barEndX - endTimeWidth - 5 > txtXStart + 5 + startTimeWidth && !task.isPointInTime() && task.getEnd()) {
                                    ctx.fillText(endTimeLabel, tbb.barEndX - endTimeWidth - 5, timesYPos);
                                }
                            }
                        }

                    } else {
                        ctx.translate(tbb.barStartX, resStartY - 2*TASKBARINSET);
                        ctx.rotate(-Math.PI / 2);
                        let txtYOffset = tbb.lableStartX - tbb.barStartX;

                        if (tbb.hasLongLabel()) {
                            ctx.fillStyle = labelBGColor;
                            ctx.fillRect(-task.getDisplayData().getHeight(), txtYOffset, tWidth + 6, tbb.labelEndX - tbb.lableStartX + 6);
                        } else {
                            //clip, damit der darüberstehende Text nicht darüber gezeichnet wird
                            const clipStartOffset = task.getStart() ? 0 : ARROWHEADLENGTH;
                            const clipEndOffset = task.getEnd() ? 0 : -ARROWHEADLENGTH;
                            ctx.beginPath();
                            ctx.rect(-task.getDisplayData().getHeight(), clipStartOffset, task.getDisplayData().getHeight(), tbb.barEndX - tbb.barStartX + clipEndOffset);
                            ctx.clip();
                        }

                        txtYOffset += LABEL_LINE_HEIGHT;

                        //Noch genügend Platz um Uhrzeiten zu zeichnen? (Mindestens 40 Pixel muss der Balken breit sein)
                        if (tWidth > 40) {
                            ctx.font = timelineBarSubHeaderFont;
                            this.ctx.fillStyle = task.getDisplayData().getSecLabelColor();
                            let startTime = this.formatBarDate(lStart);
                            let startTimeWidth = Helper.textWidthFromCache(startTime, ctx);//ctx.measureText(startTime).width;
                            ctx.fillText(startTime, -startTimeWidth - 5 - 2 * TASKBARINSET, txtYOffset);

                            txtYOffset += LABEL_LINE_HEIGHT;
                        }

                        ctx.font = timelineBarHeaderFont;
                        this.ctx.fillStyle = tbb.hasLongLabel() ? "#000" : task.getDisplayData().getLabelColor();
                        for (let i = 0; i < labelArr.length; ++i) {
                            if (txtXStart + 20 + txtYOffset > tbb.labelEndX) {
                                //break;
                            }
                            ctx.fillText(labelArr[i], 3 - task.getDisplayData().getHeight(), txtYOffset);
                            txtYOffset += LABEL_LINE_HEIGHT;
                        }

                        if (!tbb.hasLongLabel()) {
                            ctx.font = timelineBarSubHeaderFont;
                            this.ctx.fillStyle = task.getDisplayData().getSecLabelColor();
                            let secLabelArr = task.secname === null || !(task.getName() && task.getName().length > 0) ? "" : Helper.textToArrayFromCache(task.secname, tWidth - 5, ctx);
                            for (let i = 0; i < secLabelArr.length; ++i) {
                                ctx.fillText(secLabelArr[i], 5 - task.getDisplayData().getHeight(), txtYOffset);
                                txtYOffset += SECLABEL_LINE_HEIGHT;
                            }

                            if (txtYOffset < (tbb.barEndX - tbb.barStartX) - 5 && task.getEnd()) {
                                let endTime = this.formatBarDate(lEnd);
                                let endTimeWidth = Helper.textWidthFromCache(endTime, ctx);//ctx.measureText(endTime).width;
                                ctx.fillText(endTime, -endTimeWidth - 5 - 2 * TASKBARINSET, tbb.barEndX - tbb.barStartX - 5);
                            }
                        }
                    }

                    ctx.restore();
                }
            }
        }
    }

    paintTaskSelection(ctx, task) {
        let xStart = this.getXPosForTime(this.props.model.getDisplayedStart(task).getJulianMinutes());
        if (xStart <= this.virtualCanvasWidth) {
            let xEnd = this.getXPosForTime(this.props.model.getDisplayedEnd(task).getJulianMinutes());
            if (xEnd > this.resourceHeaderHeight) {
                let resStartY = this.timelineHeaderHeight + task.getDisplayData().getRelativeYStart() + this.workResOffset + 5;

                ctx.fillRect(xStart - 10, resStartY + TASKBARINSET - 10, 10, 10);
                ctx.fillRect(xStart - 10, resStartY + task.getDisplayData().getHeight() - TASKBARINSET - 10, 10, 10);
                ctx.fillRect(xEnd, resStartY + TASKBARINSET - 10, 10, 10);
                ctx.fillRect(xEnd, resStartY + task.getDisplayData().getHeight() - TASKBARINSET - 10, 10, 10);
            }
        }
    }

    paintMeasureSliders(ctx) {
        if (this.paintMeasureStart && this.paintMeasureEnd) {
            this.paintMeasureSlider(ctx, this.paintMeasureStart, 1);
            this.paintMeasureSlider(ctx, this.paintMeasureEnd, -1);
        }
    }

    paintMeasureSlider(ctx, lcal, direction) {
        let x = this.getXPosForTime(lcal.getJulianMinutes());
        ctx.fillStyle = 'rgba(60,60,60, 0.5)';

        ctx.beginPath();
        //Nicht relevante Zeiten links und rechts grau hinterlegen
        if (direction === 1) {
            if (x > this.resourceHeaderHeight) {
                ctx.rect(this.resourceHeaderHeight, this.timelineHeaderHeight, x - this.resourceHeaderHeight, this.virtualCanvasHeight - this.timelineHeaderHeight);
            }
        } else {
            if (x < this.virtualCanvasWidth) {
                ctx.rect(x, this.timelineHeaderHeight, this.virtualCanvasWidth - x, this.virtualCanvasHeight - this.timelineHeaderHeight);
            }
        }
        ctx.fill();

        ctx.strokeStyle = 'black';

        //Den angezeigten Zeitstring und dessen Breite bestimmen
        ctx.font = "bold 14px Helvetica, sans-serif";
        var str = LCalFormatter.formatDate(lcal, true) + " " + LCalFormatter.formatTime(lcal);
        var strWidth = Helper.textWidthFromCache(str, ctx);//.measureText(str).width;

        if (this.props.measureDurationLock) {
            ctx.fillStyle = '#F50057';
        } else {
            ctx.fillStyle = '#F2EC53';
        }

        let lineThickness = 6;
        ctx.beginPath();
        ctx.moveTo(x, this.timelineHeaderHeight);
        ctx.lineTo(x, this.virtualCanvasHeight);
        ctx.lineTo(x - (direction * lineThickness), this.virtualCanvasHeight);
        ctx.lineTo(x - (direction * lineThickness), this.timelineHeaderHeight + 120 + strWidth);
        ctx.lineTo(x - (direction * 40), this.timelineHeaderHeight + 100 + strWidth);
        ctx.lineTo(x - (direction * 40), this.timelineHeaderHeight + 20);
        ctx.lineTo(x - (direction * lineThickness), this.timelineHeaderHeight);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        //Der Pfeil auf dem Slider
        ctx.strokeStyle = 'black';
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.moveTo(x - (direction * 15), this.timelineHeaderHeight + 40 - 10);
        ctx.lineTo(x - (direction * 25), this.timelineHeaderHeight + 40);
        ctx.lineTo(x - (direction * 15), this.timelineHeaderHeight + 40 + 10);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        ctx.save();
        ctx.translate(x + (direction === 1 ? -15 : 26), this.timelineHeaderHeight + strWidth + 80);
        ctx.rotate(-Math.PI / 2);

        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.rect(-5, -17, strWidth + 10, 23);
        ctx.fill();
        ctx.stroke();

        ctx.fillStyle = 'black';

        ctx.fillText(str, 0, 0);
        ctx.restore();
    }

    //Die RessourcenZeitintervalle zeichnen (Tasks, Events, Moments, whatever..)
    paintTasks(ctx) {
        if (this.props.model !== undefined) {
            let time = Date.now();

            this.props.model.recomputeDisplayData(this.getTaskBarBoundsForLevelComputation);

            const shadowFillCol = 'rgba(150, 150, 150, 0.1)';

            //Farbige Balken zeichnen
            for (let n = 0; n < this.props.model.size(); n++) {
                let task = this.props.model.getItemAt(n);
                if (!task.isDeleted()) {
                    this.paintTaskBar(ctx, task, task.getDisplayData().isShadowTask() ? shadowFillCol : task.getDisplayData().getColor(), true);
                }
            }

            ctx.strokeStyle = "#CCC";
            //Balkenumrandung zeichnen
            ctx.beginPath();
            for (let n = 0; n < this.props.model.size(); n++) {
                let task = this.props.model.getItemAt(n);
                if (!task.isDeleted()) {
                    this.paintTaskBar(ctx, task);
                }
            }
            ctx.stroke();

            ctx.fillStyle = "#000000";
            //Selektierte Vorgänge zeichnen (Falls keine Schattenvorgänge)
            let ids = this.props.model.getSelectedItemIDs();
            for (let n = 0; n < ids.length; n++) {
                let task = this.props.model.getItemByID(ids[n]);
                if (task && !task.getDisplayData().isShadowTask()) {
                    this.paintTaskSelection(ctx, task);
                }
            }

            //ungenaues Start-Ende zeichnen (darübergelegter Verlauf)
            for (let n = 0; n < this.props.model.size(); n++) {
                let task = this.props.model.getItemAt(n);
                if (!task.isDeleted()) {
                    this.paintFuzzyTaskBarTimes(ctx, task);
                }
            }

            //Die Bezeichnung
            for (let n = 0; n < this.props.model.size(); n++) {
                let task = this.props.model.getItemAt(n);
                this.paintTaskBarLabel(ctx, task);
            }

            this.lastIntervalPaintDuration = Date.now() - time;
        }
    }


    paintMovedTasks(ctx) {
        if (this.props.model) {
            this.props.model.recomputeDisplayData(this.getTaskBarBoundsForLevelComputation);

            //Farbige Balken zeichnen
            for (let task of this.props.model.getMovedTasks()) {
                if (!task.isDeleted()) {
                    this.paintTaskBar(ctx, task, task.getDisplayData().getColor(), true);
                }
            }

            //Balkenumrandung zeichnen
            ctx.beginPath();
            ctx.strokeStyle = "#000000";
            for (let task of this.props.model.getMovedTasks()) {
                if (!task.isDeleted()) {
                    this.paintTaskBar(ctx, task, task.getDisplayData().getColor());
                }
            }
            ctx.stroke();


            let ids = this.props.model.getSelectedItemIDs();
            for (let task of this.props.model.getMovedTasks()) {
                this.paintTaskBarLabel(ctx, task);
                //Selektierte Vorgänge zeichnen
                if (ids.indexOf(task.getID() >= 0)) {
                    this.paintTaskSelection(ctx, task);
                }
            }
        }
    }

    paintResourcesIntervals(ctx) {
        /*if (this.props.model) {
            let resModel = this.props.model.getResourceModel();

            ctx.font = resourceMainFont;
            ctx.strokeStyle = "#BBBBBB";

            resModel.recomputeDisplayData();
        }*/
    }

    //Die Ressourcen zeichnen
    paintResources(ctx) {
        if (this.props.model) {
            const resHeaderHeight = this.props.overlayheader ? 100 : this.resourceHeaderHeight;
            let resModel = this.props.model.getResourceModel();

            ctx.font = resourceMainFont;
            ctx.strokeStyle = "#BBBBBB";

            resModel.recomputeDisplayData();
            this._alignWorkResOffset();

            for (let n = 0; n < resModel.size(); n++) {
                let res = resModel.getItemAt(n);
                let relResStartY = res.getDisplayData().getRelativeYStart();

                let resStartY = this.timelineHeaderHeight + relResStartY + this.workResOffset;
                let resHeight = res.getDisplayData().getHeight();

                //nur, wenn noch kein Ereignis eingegeben wurde
                const taskCnt = this.props.model.getItemCntByResourceID(res.getID());

                if(taskCnt === 0 && res.isAdmin && this.props.texts && this.props.texts.presshere) {
                    ctx.font = timelineMainFont;
                    ctx.fillStyle = "#999999";
                    ctx.fillText(this.props.texts.presshere, resHeaderHeight + 10, resStartY + resHeight / 2 + 4);
                }
                ctx.save();

                let icon = resModel.getIcon(res);

                ctx.beginPath();
                ctx.rect(0, resStartY, this.resourceHeaderHeight, resHeight);
                ctx.clip();

                const paintOverlayRes = (startX, endX, startY, endY) => {
                    ctx.fillStyle = "#EEE";
                    ctx.shadowColor = 'black';
                    ctx.lineWidth = 2;
                    ctx.beginPath();

                    const curveRadius = 20;
                    ctx.moveTo(startX, startY);
                    ctx.lineTo(endX, startY);
                    ctx.lineTo(endX, endY - curveRadius);
                    ctx.quadraticCurveTo(endX, endY, endX - curveRadius, endY);
                    ctx.lineTo(0, endY);
                    ctx.fill();
                    ctx.strokeStyle = "#FFF";
                    ctx.stroke();
                }

                //Beschriftung
                //let iconWidth = icon ? Math.max(Math.min(icon.width, 64), 32) : 0; //icon ist zwischen 32 und 64 pixel groß
                //let iconHeight = iconWidth;
                if (this.props.horizontalOrientation) {
                    let resEndY = Math.min(resStartY + resHeight, this.ctx.canvas.height);
                    let textStartY = Math.max(resStartY, this.timelineHeaderHeight);
                    let imgWidth = 0;


                    if (!this.props.overlayheader && icon && icon.width) {
                        try {
                            imgWidth = Math.min(Math.max(Math.round(resHeaderHeight / 2), 32), icon.width); //Maximal so breit, wie das Image im Origional ist, ansonsten mindestens 32 Pixel breit
                            let imgHeight = Math.round(icon.height * imgWidth / icon.width);
                            if (imgHeight > resHeight) {
                                imgHeight = resHeight;
                                imgWidth = Math.round(icon.width * imgHeight / icon.height);
                            }
                            ctx.drawImage(icon, resHeaderHeight - imgWidth - 5, resEndY - imgHeight - 5, imgWidth, imgHeight);
                        } catch (e) {
                            console.log("Exception beim Zeichnen von Icon");
                            console.log(icon);
                            console.log(e);
                        }

                    }

                    if (!this.props.overlayheader && res.getMarkingColor() !== null) {
                        ctx.strokeStyle = res.getMarkingColor();
                        ctx.beginPath();
                        ctx.moveTo(10, resEndY - 10);
                        ctx.lineTo(resHeaderHeight - imgWidth - 10, resEndY - 10);
                        ctx.stroke();
                    }


                    if(this.props.overlayheader) {
                        const startY = textStartY + 1;
                        const endY = textStartY + Math.min(resEndY - textStartY, 70);
                        const startX = 0;
                        const endX = resHeaderHeight;

                        paintOverlayRes(startX, endX, startY, endY);
                    }

                    ctx.beginPath();
                    ctx.rect(0, resStartY, resHeaderHeight, resHeight);
                    ctx.clip();

                    ctx.fillStyle = res.labelColor ? res.labelColor : "#000000";
                    ctx.fillText(res.getName(), 2, textStartY + 20);
                    ctx.fillStyle = res.secLabelColor ? res.secLabelColor : "#AAAAAA";
                    ctx.fillText(res.secname, 2, textStartY + 40);
                    if (this.props.overlayheader) {
                        ctx.fillStyle = "#F00";
                        ctx.fillText("mehr...", 2, textStartY + 60);
                    }

                } else {
                    ctx.translate(20, resStartY + resHeight - 3);
                    this.ctx.rotate(-Math.PI / 2);

                    let textStartX = Math.max(resStartY + resHeight - this.ctx.canvas.width, 0);
                    let resEndX = Math.min(resStartY + resHeight - this.timelineHeaderHeight, resHeight);

                    let imgWidth = 0;
                    if (!this.props.overlayheader && icon) {
                        try {
                            imgWidth = Math.min(Math.max(Math.round(resHeight / 2), 32), icon.width);  //Maximal so breit, wie das Image im Origional ist, ansonsten mindestens 32 Pixel breit
                            let imgHeight = Math.round(icon.height * imgWidth / icon.width);
                            if (imgHeight > resHeaderHeight / 2) {
                                imgHeight = Math.round(resHeaderHeight / 2);
                                imgWidth = Math.round(icon.width * imgHeight / icon.height);
                            }
                            ctx.drawImage(icon, resEndX - imgWidth - 5, resHeaderHeight - imgHeight - 25, imgWidth, imgHeight);
                        } catch (e) {
                            console.log("Exception beim Zeichnen der Icons");
                            console.log(e);
                        }
                    }

                    if(this.props.overlayheader) {
                        ctx.fillStyle = "#EEE";
                        ctx.beginPath();

                        const startY = -30;
                        const endY = resHeaderHeight - 30;
                        const startX =  textStartX - 2;
                        const endX = startX + 100;

                        paintOverlayRes(startX, endX, startY, endY);
                    }

                    if (!this.props.overlayheader && res.getMarkingColor() !== null) {
                        ctx.strokeStyle = res.getMarkingColor();
                        ctx.beginPath();
                        ctx.moveTo(10, resHeaderHeight - 30);
                        ctx.lineTo(resEndX - imgWidth - 10, resHeaderHeight - 30);
                        ctx.stroke();
                    }

                    ctx.fillStyle = "#000000";
                    ctx.fillText(res.getName(), textStartX + 2, 0);
                    ctx.fillStyle = "#AAAAAA";
                    ctx.fillText(res.secname, textStartX + 2, 20);
                    if (this.props.overlayheader) {
                        ctx.fillStyle = "#F00";
                        ctx.fillText("mehr...", textStartX + 2, 40);
                    }
                }

                ctx.restore();
            }

            //Trennstrich zwischen den Ressourcen
            ctx.beginPath();
            if(!this.props.overlayheader) {
                ctx.moveTo(resHeaderHeight, this.timelineHeaderHeight);
                ctx.lineTo(resHeaderHeight, this.virtualCanvasHeight);
            }
            let resStartY = 0;
            let resHeight = 0;
            for (let n = 0; n < resModel.size(); n++) {
                let res = resModel.getItemAt(n);
                let relResStartY = res.getDisplayData().getRelativeYStart();

                resStartY = this.timelineHeaderHeight + relResStartY + this.workResOffset;
                resHeight = res.getDisplayData().getHeight();

                ctx.moveTo(0, resStartY);
                ctx.lineTo(this.virtualCanvasWidth, resStartY);
            }


                ctx.moveTo(0, resStartY + resHeight);
                ctx.lineTo(this.virtualCanvasWidth, resStartY + resHeight);

            ctx.stroke();
        }
    }

    paintScrollBar(ctx) {
        if (this.props.model) {
            let resModel = this.props.model.getResourceModel();

            ctx.fillStyle = "rgba(200, 200, 200, 0.5)";

            let totalScrollbarHeight = this.virtualCanvasHeight - this.timelineHeaderHeight;
            let totalResHeight = resModel.getTotalResourceHeight();
            let factor = totalScrollbarHeight / totalResHeight;
            let barSize = totalScrollbarHeight * factor;

            if (barSize < totalScrollbarHeight) {
                ctx.fillStyle = "rgba(200, 200, 200, 0.5)";
                ctx.fillRect(this.virtualCanvasWidth - 10, this.timelineHeaderHeight, 10, this.virtualCanvasHeight - this.timelineHeaderHeight);

                ctx.fillStyle = "rgba(50, 50, 50, 0.7)";
                ctx.fillRect(this.virtualCanvasWidth - 10, -this.workResOffset * factor + this.timelineHeaderHeight, 10, barSize);
            }
        }
    }


    paintGrid(ctx, start, end, initFunc, addMainTimeFunc, addSubTimeFunc, displMainDateFunc, displSubDateFunc, getBlockColorFunc) {
        let starttime = initFunc(start);

        ctx.font = timelineMainFont;

        //Das Untergrid zeichnen
        let time = starttime.clone();

        ctx.beginPath();
        let lastX = this.getXPosForTime(time.getJulianMinutes());
        if (lastX < this.resourceHeaderHeight) {
            lastX = this.resourceHeaderHeight;
        }
        do {
            let subTime = time.clone();
            //Falls es sich um ein Wochenendtag handelt, dann entsprechend farblich markieren
            let blockColor = getBlockColorFunc(time, true);

            time = addMainTimeFunc(time);
            let x = this.getXPosForTime(time.getJulianMinutes());
            if (x > this.virtualCanvasWidth) {
                x = this.virtualCanvasWidth;
            }

            if (blockColor) {
                ctx.fillStyle = blockColor;
                ctx.fillRect(lastX, this.timelineHeaderHeight, x - lastX, this.virtualCanvasHeight - this.timelineHeaderHeight);
            }

            let lastSubX = lastX;
            do {
                blockColor = getBlockColorFunc(subTime, false);
                subTime = addSubTimeFunc(subTime);
                let subX = this.getXPosForTime(subTime.getJulianMinutes());

                if (blockColor) {
                    ctx.fillStyle = blockColor;
                    ctx.fillRect(lastSubX, this.timelineHeaderHeight, subX - lastSubX, this.virtualCanvasHeight - this.timelineHeaderHeight);
                }

                if (subX > this.resourceHeaderHeight) {
                    ctx.moveTo(subX, this.timelineHeaderHeight);
                    ctx.lineTo(subX, this.virtualCanvasHeight);
                }

                lastSubX = subX;
            } while (subTime.before(time));
            lastX = x;
        } while (time.before(end));

        ctx.strokeStyle = "#EEEEEE";
        ctx.stroke();

        //Das Hauptgrid zeichnen
        time = starttime.clone();
        ctx.beginPath();
        do {
            time = addMainTimeFunc(time);
            let x = this.getXPosForTime(time.getJulianMinutes());

            ctx.moveTo(x, 0);
            ctx.lineTo(x, this.virtualCanvasHeight);

        } while (time.before(end));

        ctx.strokeStyle = "#BBBBBB";
        ctx.stroke();

        //Lücke für die Unterbeschriftung zeichnen
        ctx.fillStyle = timelineHeaderColor;
        ctx.fillRect(0, 25, this.virtualCanvasWidth, this.timelineHeaderHeight - 30);

        ctx.fillStyle = "#000000";

        //Die Hauptbeschriftung zeichnen
        time = starttime.clone();
        lastX = this.getXPosForTime(time.getJulianMinutes());
        if (lastX < this.resourceHeaderHeight) {
            lastX = this.resourceHeaderHeight;
        }

        do {
            ctx.font = timelineMainFont;

            let str = displMainDateFunc(time);

            time = addMainTimeFunc(time);
            var x = this.getXPosForTime(time.getJulianMinutes());
            if (x > this.virtualCanvasWidth) {
                x = this.virtualCanvasWidth;
            }

            //ctx.fillStyle = "#000000";
            var mid = lastX + (x - lastX) / 2;

            let txtWidth = Helper.textWidthFromCache(str, ctx);//ctx.measureText(str).width;
            if (txtWidth > x - lastX) {
                ctx.font = timelineMainFontMini;
            }
            txtWidth = Helper.textWidthFromCache(str, ctx);//ctx.measureText(str).width;

            var txtPos = Math.round(mid - txtWidth / 2);

            if (txtPos < lastX + 2 && x === ctx.canvas.width) {
                txtPos = lastX + 2;
            } else if (txtPos + txtWidth > x - 2 && lastX === this.resourceHeaderHeight) {
                txtPos = x - txtWidth - 2;
            }
            ctx.fillText(str, txtPos, 16);

            lastX = x;
        } while (time.before(end));

        //Die Unterbeschriftung zeichnen
        ctx.font = timelineSubFont;
        time = starttime.clone();
        lastX = null;
        let lastSubTime;
        let lastSubIndex = 0;
        do {
            let subTime = time.clone();
            time = addMainTimeFunc(time);
            let subIndex = 0;
            do {
                let x = this.getXPosForTime(subTime.getJulianMinutes());
                if (lastX) {
                    let str = displSubDateFunc(lastSubTime, lastSubIndex);
                    let txtWidth = Helper.textWidthFromCache(str, ctx);//ctx.measureText(str).width;
                    //var txtPos = Math.round(x - txtWidth / 2);
                    let txtPos = Math.round(lastX + (x - lastX) / 2 - txtWidth / 2);

                    if (this.props.horizontalOrientation || txtWidth > 25) {
                        ctx.fillText(str, txtPos, 40);
                    } else {
                        ctx.save();
                        ctx.translate(txtPos, 40);
                        ctx.rotate(-Math.PI / 2);
                        ctx.fillText(str, 0, 0);
                        ctx.restore();
                    }
                }
                lastSubTime = subTime.clone();
                lastX = x;

                subTime = addSubTimeFunc(subTime);
                lastSubIndex = subIndex;
                subIndex++;
            } while (subTime.before(time));

        } while (time.before(end));
    }
}

export default Timeline;
