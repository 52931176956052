/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class WaitOverlay extends React.Component {
    render() {
        const styles = {
            overlayStyle: {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 100000,
                backgroundColor: "rgba(0,0,0,0.2)"
            },

            d1: {
                display: "table",
                width: "100%",
                height: "100%",
            },
            d2: {
                display: "table-cell",
                textAlign: "center",
                verticalAlign: "middle"
            }
        };

        return (
            <div style={styles.overlayStyle}>
                <div style={styles.d1}>
                    <div style={styles.d2}>
                        <CircularProgress/>
                    </div>
                </div>
            </div>
        )
    }
}

export default WaitOverlay
