import React from "react";
import "whatwg-fetch";
import Config from "./config/config.js";
import {InstrumentedTimeline, LCal, LCalFormatter, LCalHelper, SliderValue, styles} from '@softmanufaktur/timeline';
import PixiModel from "./model/piximodel";
import BookingItem from "./data/bookingitem";
import BaseResource from "./data/baseresource.js";
import Brand from "./data/brand";
import Help from "./help";
import Dataprotection from "./dataprotection";
import AGB from "./agb";
import Impressum from "./impressum";
import NoResourceHelp from "./noresourcehelp";
import Login from "./login.js";
import BookingDetails from "./bookingdetails/bookingdetails.js";
import EditRights from "./editrights.js";
import EditProfile from "./editprofile.js";
import ChangePassword from "./changepassword.js";
import Filter from "./filter/filter.js";
import TransferHandler from "./transfer/transferhandler.js";
import MemberEntry from "./resdetails/memberentry";
import Snackbar from "@material-ui/core/Snackbar";
import DefaultResourceDetails from "./resdetails/defaultresourcedetails.js";
import VehicleResourceDetails from "./resdetails/vehicleresourcedetails.js";
import StudentDriverDetails from "./resdetails/studentdriverresourcedetails.js";
import DrivingInstructorDetails from "./resdetails/drivinginstructorresourcedetails.js";
import RoomDetails from "./resdetails/roomresourcedetails.js";
import History from "./history.js";
import Settings from "./settings.js";
import Export from "./export.js";
import News from "./news/news.js";
import Menu from '@material-ui/core/Menu';
import Details from './ui-components/details';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import IconButton from "@material-ui/core/IconButton/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Vehicle from "./data/vehicle.js";
import DrivingInstructor from "./data/drivinginstructor.js";
import StudentDriver from "./data/studentdriver.js";
import Room from "./data/room.js";
import StudentDriverIcon, {path as studentDriverPath} from "./icons/studentdriver.js";
import DrivingInstructorIcon, {path as drivingInstructorPath} from "./icons/drivinginstructor.js";
import RoomIcon, {path as roomPath} from "./icons/room.js";
import {path as busIconPath} from "./icons/bus.js";
import {path as truckIconPath} from "./icons/truck.js";
import CarIcon, {path as carIconPath} from "./icons/car.js";
import {path as carTrailerIconPath} from "./icons/cartrailer.js";
import {path as constructionvehicleIconPath} from "./icons/constructionvehicle.js";
import {path as motorcycleIconPath} from "./icons/motorcycle.js";
import {path as othersIconPath} from "./icons/others.js";
import {path as truckTrailerIconPath} from "./icons/trucktrailer.js";
import UserRightsIcon from "./icons/userrights.js";
import PasswordIcon from "./icons/password.js";
import ProfileIcon from "./icons/profile.js";
import SettingsIcon from "./icons/settings.js";
import ExportIcon from "./icons/export.js";
import Drawer from "@material-ui/core/Drawer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {createMuiTheme, withStyles} from '@material-ui/core/styles';
import secondaryCol from '@material-ui/core/colors/deepOrange';

const sliderValues = [
    new SliderValue(2628000, "5 J"),
    new SliderValue(525600, "1 J"),
    new SliderValue(44640, "31 T"),
    new SliderValue(10080, "7 T"),
    new SliderValue(1440, "1 T"),
    new SliderValue(60, "1 h"),
]

let getParameterByName = (name) => {
    let  url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&"); //vorher: name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#505050'
        },
        secondary: secondaryCol,
    },
});

const getImg = function(iconPath) {
    let svgData = '<svg xmlns="http://www.w3.org/2000/svg"  width="32" height="32"><path d="' + iconPath + '" /></svg>';
    let img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(svgData);
    img.width = 32;
    img.height = 32;
    return img;
}

const carImg = getImg(carIconPath);
const busImg = getImg(busIconPath);
const truckImg = getImg(truckIconPath);
const motorcycleImg = getImg(motorcycleIconPath);
const constructionVehicleImg = getImg(constructionvehicleIconPath);
const carTrailerImg = getImg(carTrailerIconPath);
const truckTrailerImg = getImg(truckTrailerIconPath);
const otherVehicleImg = getImg(othersIconPath);
const studentDriverImg = getImg(studentDriverPath);
const drivingInstructorImg = getImg(drivingInstructorPath);
const roomImg = getImg(roomPath);


class PixipointMain extends React.Component {
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.login = this.login.bind(this);
    this.loggedIn = this.loggedIn.bind(this);
    this.help = this.help.bind(this);
    this.news = this.news.bind(this);
    this.editRights = this.editRights.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.settings = this.settings.bind(this);
    this.exportData = this.exportData.bind(this);
    this.taskDetails = this.taskDetails.bind(this);
    this.resourceDetails = this.resourceDetails.bind(this);
    this.saveTasks = this.saveTasks.bind(this);
    this.startPollChanges = this.startPollChanges.bind(this);
    this.pollChanges = this.pollChanges.bind(this);

    this.icons = [];

    this.state = {windowWidth: window.innerWidth, 
    			  windowHeight: window.innerHeight, 
    			  menuIsVisible: false, 
    			  horizontalOrientation: true, 
    			  userLoggedIn: false,
                  loadingData: false,
    			  selectedResource: null, 
    			  selectedTask: null,
    			  newsInfo: "0/0",
    			  activeDetailPage: "",
                  detailPageAbortable: true,
                  snackbarOpen: false,
                  snackbarMessage: "",
                  menuAnchorEl: null,
                  menuOpen: false,
                  openMainMenuItem: "",
                  orientationWillChange: false,
                  exitDialogOpen: false,
                  initialLoginCard: "login"
    };
    
    this.model = new PixiModel();
    
    this.journalid = 0;

      //Auf einen hashchange wegen Verhinderung des Beendens der Anwendung lauschen, aber nur, wenn kein Parameter angegeben wurde
      if(window.location.href.indexOf("?")<0) {
          if (window.history.pushState) {
              window.history.replaceState(null, null, '#2');
              window.history.pushState(null, null, '#1');
          }
          window.addEventListener("hashchange", () => {
              if (this.state.activeDetailPage !== "") {
                  this.closeDetails();
                  window.history.pushState(null, null, '#1');
              } else {
                  this.setState({exitDialogOpen: true});
              }
          })
      }
    
    let iconProvider = function(obj, onLoad) {
        //Das Objekt kann entweder eine Ressource sein, oder es handelt sich um ein JSON-Objekt, das restype, i1 und imageurl enthält
        if(obj !== null) {
            let imgSrc = obj.imageurl;
            if(!imgSrc && obj.getImgSrc) {
                imgSrc = obj.getImgSrc();
            }
            if (imgSrc && imgSrc !== "") {
                if (this.icons[imgSrc]) {
                    return this.icons[imgSrc];
                } else {
                    let img = new Image();
                    img.src = Config.getBaseURL() + "/images/resourceimages/thumb/" + imgSrc;
                    if (onLoad) {
                        img.onload = onLoad;
                    }
                    this.icons[imgSrc] = img;
                    return img;
                }
            }
            if (obj instanceof Vehicle || obj.restype === 1) {
                switch (obj.getVehicleCategory ? obj.getVehicleCategory() : obj.i1) {
                    case 1:
                        return carImg;
                    case 2:
                        return busImg;
                    case 3:
                        return truckImg;
                    case 4:
                        return motorcycleImg;
                    case 5:
                        return constructionVehicleImg;
                    case 6:
                        return carTrailerImg;
                    case 7:
                        return truckTrailerImg;
                    default:
                        return otherVehicleImg;
                }
            } else if (obj instanceof DrivingInstructor || obj.restype === 2) {
                return drivingInstructorImg;
            } else if (obj instanceof StudentDriver || obj.restype === 3) {
                return studentDriverImg;
            } else if (obj instanceof Room || obj.restype === 4) {
                return roomImg;
            }
        }
    	return null;
    }.bind(this);

      let resIconProvider = function(obj, onLoad) {
          let p = iconProvider(obj, onLoad);
          if(!p) {
              p = otherVehicleImg;
          }
          return p;
      };


    this.model.getResourceModel().setIconProvider(resIconProvider);
    this.model.setIconProvider(iconProvider);
    
    this.nextID = -1;

    this.onTimelineClick = this.onTimelineClick.bind(this);
    this.onTimelinePress = this.onTimelinePress.bind(this);
    this.onTimelineLongPress = this.onTimelineLongPress.bind(this);
  }

  handleResize() {
    const orientationChanged = this.refreshOrientation();
    this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight, orientationWillChange: orientationChanged});
    //Eine halbe Sekunde später noch mal wegen dem Safari:  https://hackernoon.com/onresize-event-broken-in-mobile-safari-d8469027bf4d
    setTimeout(()=>{
          const orientationChanged2 = this.refreshOrientation();
          if(orientationChanged || orientationChanged2) {
              this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight, orientationWillChange: false});
          }
    }, 400);
  }

    /**
     * returns true, if orientation will change
     * @returns {boolean}
     */
  refreshOrientation() {
      //Die Orientation darf nur geändert werden, wenn kein Detailreiter geöffnet ist (TODO: bzw. keiner, der editiert werden kann, da sonst die Tastatur hochkommen kann und die Orientation damit geändert werden kann)
    if(this.state.activeDetailPage === "") {
        //Die Orientation automatisch bestimmen
        if (window.innerWidth < window.innerHeight) {
            if (this.state.horizontalOrientation) {
                this.setState({horizontalOrientation: false});
                return true;
            }
        } else {
            if (!this.state.horizontalOrientation) {
                this.setState({horizontalOrientation: true});
                return true;
            }
        }
    }
    return false;
  }

    /**
     * Hinweis, dass das aktuell geöffnete Panel nicht automatisch geschlossen werden kann
     */
  showNotAbortableHint() {
      this.setState({snackbarOpen: true, snackbarMessage: "Der Detailbereich kann nur durch Abbrechen oder Speichern geschlossen werden."});
  }



  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    this.handleResize();


    let entryPoint = getParameterByName("entryPoint");
    if(entryPoint === "register") {
        this.setState({activeDetailPage: "login", initialLoginCard: "noAccount"})
    } else {
        //Automatischer Login?
        this.autoLogin();
    }
  }

  onTimelineClick(timelineevent) {
          if(!timelineevent.isTimeHeaderPressed()) {
              if (timelineevent.getTask() !== null) {
                  //Öffnen der Detailseite zum Editieren
                  let task = timelineevent.getTask();
                  this.taskDetails(task);
                  this.model.setSelectedItemIDs([task.getID()]);
              } else if (timelineevent.isResourceHeaderPressed()) {
                  //Öffnen der Detailseite zum Editieren
                  let res = timelineevent.getResource();
                  if (res !== null) {
                      this.resourceDetails(res);
                  }
              } else {
                  this.model.setSelectedItemIDs([]);
                  //Irgendwohin drücken bedeutet immer die Details zu schließen, falls dieses noch offen ist
                  //SELF.setState({menuIsVisible: false});
              }
          }
  }

  onTimelinePress(timelineevent) {
          //Irgendwohin drücken bedeutet oft das Menü zu schließen, falls dieses noch offen ist. genauso deb springe-zu-Dialog
          if(this.state.detailPageAbortable) {
              if(this.state.activeDetailPage !== "") {
                  this.closeDetails();
              }
          } else {
              this.showNotAbortableHint();
          }
          if(this.state.menuIsVisible) {
              this.setState({menuIsVisible: false});
          }
  }

   onTimelineLongPress(timelineevent) {
        if(this.state.detailPageAbortable) {
            //Wurde weder der TimelineHeader noch der Resorucenheader gedrückt, aber wurde trotzdem kein bestehendes Task geklickt?
            if (!(timelineevent.isTimeHeaderPressed() || timelineevent.isResourceHeaderPressed() || timelineevent.getTask() !== null || timelineevent.getResource() === null)) {
                //Zeit auf Viertelstundengrenzen runden
                //issue #301: Neuer Termin Anlegen - Einrastverhalten
                let t = timelineevent.getTime();
                let minute = Math.round(t.getMinute() / 15) * 15;
                t.initYMDHM(t.getYear(), t.getMonth(), t.getDay(), t.getHour(), minute, t.getTimeZone());
                //Neue Task
                let bi = this.model.createBookingItem(timelineevent.getResource().getID(), t, 90);
                this.model.add(bi);
                this.taskDetails(bi);
            } else {
                this.onTimelineClick(timelineevent);
            }
        }
    }

  autoLogin() {
	  let remembermeKey = localStorage.getItem('pixipoint_rememberme_key');
      let rememberMeUser = localStorage.getItem('pixipoint_rememberme_user');
	  
      if(remembermeKey && rememberMeUser) {
          let transferObj = {
              email: rememberMeUser,
              remembermeKey: remembermeKey
          }

          let successFunc = (json) => {
              this.loggedIn(json.sessionid, json.email, json.forename, json.surname, json.company);
          }
          let errorFunc = (code, json) => {
              console.log("Fehler beim automatischen Login (Remember me)");
              this.setState({snackbarOpen: true, snackbarMessage: "Bitte prüfen Sie die Internetverbindung."});
              this.login();
          }

          TransferHandler.request(Config.getLoginURL(), successFunc, errorFunc, transferObj);
      } else {
          //Nicht automatisch eingeloggt? -> Login-Dialog anzeigen
          this.login();
      }
  }
  

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  showMenu(visible) {
    this.setState({menuIsVisible: visible});
  }

  login() {
    if(this.state.userLoggedIn) {
      //LogOut
	  //Falls Remember-me gesetzt ist, dieses zurücksetzen
      localStorage.removeItem('pixipoint_rememberme_key');
      localStorage.removeItem('pixipoint_rememberme_user');
      sessionStorage.removeItem('pixipoint_sessionid')

      this.setState({userLoggedIn: false});
      this.closeDetails();
      this.model.getResourceModel().clear();
      this.model.clear();

      //...und wieder den Anmeldedialog anzeigen
      this.setState({menuIsVisible: false, activeDetailPage: "login", detailPageAbortable: false});
    } else {
      this.setState({menuIsVisible: false, activeDetailPage: "login", detailPageAbortable: false});
    }
   }
  
   loggedIn(sessionID, email, forename, surname, company) {
      this.closeDetails();
	  this.setState({loadingData: true, userLoggedIn: true});

	  sessionStorage.setItem('pixipoint_login_user', email);
	  sessionStorage.setItem('pixipoint_sessionid', sessionID);

	  let f = function() {
	      //Prüfen, ob das Profil vollständig ist. Ansonsten zum Bearbeiten des Profils springen
          if(forename && forename.length > 1 && surname && surname.length > 1) {
              //Prüfen, ob Ressourcen geladen wurden. Falls nicht, dann ist der User bestimmt neu und möchte unterstützt werden
              if (this.model.getResourceModel().size() === 0) {
                  this.setState({activeDetailPage: "noresource_help", detailPageAbortable: true});
              }
          } else {
              this.setState({activeDetailPage: "editProfile", detailPageAbortable: false});
          }

          this.startPollChanges();
          /*this.pollChanges(()=>{
              //Hier wurde das erste Mal pollChanges aufgerufen, und die Antwort kam zurück
              setTimeout(this.startPollChanges, 10000);
          });*/

          this.setState({loadingData: false});
      }.bind(this);

	  //Nach erfolgreichem Login müssen die Daten geladen werden (komplett, nicht nur Delta)
	  this.loadAllData(f);
   }

   jsonToResource(jsonresources) {
       let resources = [];
       //Für jede Ressource ein Objekt erzeugen und zufügen
       for(let jsonRes of jsonresources) {
           let res;
           switch(jsonRes.resourcetype) {
               case 1: res = new Vehicle(jsonRes.id, jsonRes.label, jsonRes.seclabel, jsonRes.deleted, jsonRes.description, jsonRes.isAdmin, jsonRes.bookingdeadline, jsonRes.bookinghorizont, jsonRes.changeusername, jsonRes.userimg, jsonRes.changetime, jsonRes.imgSrc, jsonRes.i1, jsonRes.i2, jsonRes.s1); break;
               case 2: res = new DrivingInstructor(jsonRes.id, jsonRes.label, jsonRes.seclabel, jsonRes.deleted, jsonRes.description, jsonRes.isAdmin, jsonRes.bookingdeadline, jsonRes.bookinghorizont, jsonRes.changeusername, jsonRes.userimg, jsonRes.changetime, jsonRes.imgSrc); break;
               case 3: res = new StudentDriver(jsonRes.id, jsonRes.label, jsonRes.seclabel, jsonRes.deleted, jsonRes.description, jsonRes.isAdmin, jsonRes.bookingdeadline, jsonRes.bookinghorizont, jsonRes.changeusername, jsonRes.userimg, jsonRes.changetime, jsonRes.imgSrc); break;
               case 4: res = new Room(jsonRes.id, jsonRes.label, jsonRes.seclabel, jsonRes.deleted, jsonRes.description, jsonRes.isAdmin, jsonRes.bookingdeadline, jsonRes.bookinghorizont, jsonRes.changeusername, jsonRes.userimg, jsonRes.changetime, jsonRes.imgSrc); break;
               default: res = new BaseResource(jsonRes.id, jsonRes.label, jsonRes.seclabel, jsonRes.deleted, jsonRes.description, jsonRes.isAdmin, jsonRes.bookingdeadline, jsonRes.bookinghorizont, jsonRes.changeusername, jsonRes.userimg, jsonRes.changetime, jsonRes.imgSrc); break;
           }
           resources.push(res);
       }
       return resources;
   }

   jsonToTask(jsontasks) {
       let tasks = [];
       //Für jedes BookingItem ein Objekt erzeugen
       for(let jsonEvt of jsontasks) {
           let start = new LCal().setTimeZone("Europe/Berlin").setJulianMinutes(LCalHelper.unixToJulian(jsonEvt.start * 1000));
           let end = new LCal().setTimeZone("Europe/Berlin").setJulianMinutes(LCalHelper.unixToJulian(jsonEvt.end * 1000));

           let ri1 = new BookingItem(jsonEvt.id, start, end, jsonEvt.resid, jsonEvt.comment, jsonEvt.changeusername, jsonEvt.debitor, jsonEvt.selectedoptions, jsonEvt.canbook, jsonEvt.changeusername, jsonEvt.userimg, jsonEvt.changetime, jsonEvt.bookingNumber, jsonEvt.relatedTasks);
           ri1.getDisplayData().setColor(jsonEvt.createdbyme ? Config.getBookingEditableByMeColor(): (jsonEvt.canbook ? Config.getBookingEditableByResAdminColor() : Config.getBookingNotEditableColor()));
           ri1.getDisplayData().setLabelColor(jsonEvt.createdbyme ? "#FFF" : "#000");
           ri1.getDisplayData().setSecLabelColor(jsonEvt.createdbyme ? "#CCC" : "#222");
           ri1.setDeleted(jsonEvt.deleted);
           tasks.push(ri1);
       }
       return tasks;
   }

    sessionIsInvalid() {
        this.setState({snackbarOpen: true, snackbarMessage: "Ihres Session ist abgelaufen."});
        this.autoLogin();
    }

    loadAllData(onDataLoaded, resid) {
        let onSuccessLoadAll = (json) => {
            let resources = this.jsonToResource(json.resources);
            let tasks = this.jsonToTask(json.events);
            let brands = [];

            //Für jede Marke ein Objekt erzeugen
            for(let brand of json.brands) {
                let b = new Brand(brand.id, brand.categorykey, brand.value);

                brands.push(b);
            }

            //Die Anzahl der offenen und vorhandenen Mails aktualisieren
            this.setState({"newsInfo": json.newsinfo.unread+"/"+json.newsinfo.total});

            this.model.getResourceModel().setAll(resources);
            this.model.setAll(tasks);
            this.model.setBrands(brands);

            this.journalid = json.journalid;

            if(onDataLoaded) {
                onDataLoaded();
            }
        }

        let onSuccessLoadRes = (json) => {
            let resources = this.jsonToResource(json.resources);
            let tasks = this.jsonToTask(json.events);

            this.model.getResourceModel().putAll(resources);
            this.model.putAll(tasks);

            if(onDataLoaded) {
                onDataLoaded();
            }
        }

        let onError = (code, json) => {
            if(code === 500) {
                this.setState({snackbarOpen: true, snackbarMessage: "Bitte prüfen Sie die Internetverbindung."});
            } else if(code === 999) {
                this.sessionIsInvalid();
            }
        }

        //Falls eine Ressource angegeben wurde, dann nur diese Ressource laden
        if(resid) {
            //Eine Dummy-Ressource zum Laden setzen
            let res = new BaseResource();
            res.setID(resid);
            res.setName("wird geladen...")
            res.secname = "bitte warten"
            this.model.getResourceModel().put(res);
            TransferHandler.request(Config.getLoadDataURL(), onSuccessLoadRes, onError, {initial: true, resid: resid});
        } else {
            TransferHandler.request(Config.getLoadDataURL(), onSuccessLoadAll, onError, {initial: true});
        }
    }

   /**
    * Zyklischen Aufruf der Änderungsabfrage starten (nach erhalt des Response 10 Sekunden warten)
    */
   startPollChanges() {
	   this.pollChanges(()=>setTimeout(this.startPollChanges, 10000));
   }
   
   pollChanges(callback, forcePoll) {
		let onSuccess = function(json) {
		    //Daten nur aktualisieren, falls kein Fenster geöffnet ist
            if(this.state.activeDetailPage === "" || forcePoll) {
                let resources = this.jsonToResource(json.resources);
                let tasks = this.jsonToTask(json.events);

                //Die Anzahl der offenen und vorhandenen Mails aktualisieren, sowie der Gruppen, zu denen der angemeldete User gehört
                this.setState({"newsInfo": json.newsinfo.unread + "/" + json.newsinfo.total});

                this.model.getResourceModel().putAll(resources);
                this.model.putAll(tasks);

                this.journalid = json.journalid;
            }
			if(callback) {
				   callback();
			}
		}.bind(this);
		
		let onError = function(code, json) {
		    if(code === 999) { //Invalid Session?
                this.sessionIsInvalid();
            } else if(callback) {
				   callback();
			}
		}.bind(this);

		//Daten nur pollen, falls gerade kein Fenster geöffnet ist
       if(this.state.activeDetailPage === "" || forcePoll) {
           TransferHandler.request(Config.getLoadDataURL(), onSuccess, onError, {
               initial: false,
               journalid: this.journalid
           });
       } else if(callback) {
           callback();
       }
   }
   
   saveTasks(tasks, choosenOpts) {
       let tasksToSave = [];
       for(let t of tasks) {
           let task = {
               id: t.getID(),
               resid: t.getResID(),
               starttime: t.getStart() ? Math.round(LCalHelper.julianToUnix(t.getStart().getJulianMinutes()) / 1000) : null,
               endtime: t.getEnd() ? Math.round(LCalHelper.julianToUnix(t.getEnd().getJulianMinutes()) / 1000): null,
               comment: t.getName(),
               debitor: t.getDebitor(),
               deleted: t.isDeleted(),
               bookingnumber: t.getBookingNumber() || 0,
               selectedoptions: choosenOpts[t.getID()],
           };

           tasksToSave.push(task);
       }

       let onSuccess = function(json) {
           this.pollChanges(()=>{this.closeDetails(()=>this.setState({selectedTask: null}))}, true);
       }.bind(this);


       TransferHandler.request(Config.getSaveURL(), onSuccess, null, {tasks: tasksToSave});
   }

   saveSelectedResource() {
      let resource = {
          id: this.state.selectedResource.getID(),
          bookingdeadline: this.state.selectedResource.getBookingDeadline(),
          bookinghorizont: this.state.selectedResource.getBookingHorizont(),
          members: this.state.selectedResource.getMembers(),
          options: this.state.selectedResource.getBookingOptions(),
          name: this.state.selectedResource.getName(),
          secname: this.state.selectedResource.secname,
          description: this.state.selectedResource.getDescription(),
          imgSrc: this.state.selectedResource.getImgSrc(),
          deleted: this.state.selectedResource.isDeleted()
      }

      if(this.state.selectedResource instanceof Vehicle) {
          resource.restype = 1;
          resource.i1 = this.state.selectedResource.getVehicleCategory();
          resource.i2 = this.state.selectedResource.getVehicleBrand();
          resource.s1 = this.state.selectedResource.getVehicleModel();
      } else if(this.state.selectedResource instanceof DrivingInstructor) {
          resource.restype = 2;
       } else if(this.state.selectedResource instanceof StudentDriver) {
          resource.restype = 3;
       } else if(this.state.selectedResource instanceof Room) {
          resource.restype = 4;
       }


       let onSuccess = (json) => {
           //Speicherung in der DB, dabei wird die ID positiv. Als Response kommt die neue ID
           if(this.state.selectedResource.getID() < 0) {
               this.model.getResourceModel().remove(this.state.selectedResource);
           }

           this.pollChanges(()=>{this.setState({selectedResource: null}); this.closeDetails();}, true);
       };

      let onError = (code, json) => {
          if(code === 1) {
              this.setState({snackbarOpen: true, snackbarMessage: "Bitte mindestens einen Administrator angeben."});
          } else if(code === 999) {
              this.sessionIsInvalid();
          }
      }


       TransferHandler.request(Config.getSaveURL(), onSuccess, onError, {resource: resource});
   }
   
  initNewResource(res) {
      res.setID(this.nextID);
      this.nextID--;
	  this.model.getResourceModel().add(res);

	  //Ein Eintrag mit dem aktuellen Benutzer wird immer vorbelegt
      //TODO: forename, surname, company aus dem Profil ziehen
	  let memberentry = new MemberEntry(sessionStorage.getItem('pixipoint_login_user'), "" , true, "", "", "", true, true);

	  //Handelt es sich um ein Fahrzeug, dann wird PKW vorbelegt
      if(res instanceof  Vehicle) {
          res.setVehicleCategory(1);
      }
      res.setMembers([memberentry]);
	  this.resourceDetails(res);
  }
   
  taskDetails(task) {
	  if(task && this.state.detailPageAbortable) {
		  this.setState({selectedTask: task, activeDetailPage: "bookingdetails", detailPageAbortable: true});
	  } else {
          this.showNotAbortableHint();
      }
  }

  resourceDetails(resource) {
       if(this.state.detailPageAbortable) {
           let detailPage = "DefaultDetails";
           if (resource instanceof Vehicle) {
               detailPage = "VehicleDetails";
           } else if (resource instanceof DrivingInstructor) {
               detailPage = "DrivingInstructorDetails";
           } else if (resource instanceof StudentDriver) {
               detailPage = "StudentDriverDetails";
           } else if (resource instanceof Room) {
               detailPage = "RoomDetails";
           }
           this.setState({selectedResource: resource, activeDetailPage: detailPage, detailPageAbortable: resource.getID()>=0});
       } else {
           this.showNotAbortableHint();
       }
  }

  /*history(item) {
	  this.setState({activeDetailPage: "history"});
  }*/

  help() {
      if(this.state.detailPageAbortable) {
          this.setState({menuIsVisible: false, activeDetailPage: "help", detailPageAbortable: true});
      } else {
          this.showNotAbortableHint();
      }
  }

  dataprotection() {
      if(this.state.detailPageAbortable) {
          this.setState({menuIsVisible: false, activeDetailPage: "dataprotection", detailPageAbortable: true});
      } else {
          this.showNotAbortableHint();
      }
  }

    agb() {
        if(this.state.detailPageAbortable) {
            this.setState({menuIsVisible: false, activeDetailPage: "agb", detailPageAbortable: true});
        } else {
            this.showNotAbortableHint();
        }
    }

    impressum() {
        if(this.state.detailPageAbortable) {
            this.setState({menuIsVisible: false, activeDetailPage: "impressum", detailPageAbortable: true});
        } else {
            this.showNotAbortableHint();
        }
    }

  news() {
      if(this.state.detailPageAbortable) {
          this.setState({menuIsVisible: false, activeDetailPage: "news", detailPageAbortable: true});
      } else {
          this.showNotAbortableHint();
      }
  }
  
  settings() {
      if(this.state.detailPageAbortable) {
          this.setState({menuIsVisible: false, activeDetailPage: "settings", detailPageAbortable: false});
      } else {
          this.showNotAbortableHint();
      }
  }
  
  exportData() {
      if(this.state.detailPageAbortable) {
          this.setState({menuIsVisible: false, activeDetailPage: "export", detailPageAbortable: true});
      }
  }
  
  editRights() {
      if(this.state.detailPageAbortable) {
          this.setState({menuIsVisible: false, activeDetailPage: "editRights", detailPageAbortable: true});
      } else {
          this.showNotAbortableHint();
      }
  }
  
  editProfile() {
      if(this.state.detailPageAbortable) {
          this.setState({menuIsVisible: false, activeDetailPage: "editProfile", detailPageAbortable: false});
      } else {
          this.showNotAbortableHint();
      }
  }
  
  changePassword() {
      if(this.state.detailPageAbortable) {
          this.setState({menuIsVisible: false, activeDetailPage: "changePassword", detailPageAbortable: false});
      } else {
          this.showNotAbortableHint();
      }
  }

  filter() {
      if(this.state.detailPageAbortable) {
          this.setState({menuIsVisible: false, activeDetailPage: "filter", detailPageAbortable: true});
      } else {
          this.showNotAbortableHint();
      }
  }

  resourceVisibilityChanged(resID, isvisible) {
        if(isvisible) {
            //Falls ein Eintrag sichtbar gemacht werden soll, dann muss dieser von der DB nachgeladen werden
            this.loadAllData(null, resID);
        } else {
            //Falls unsichtbar gesetzt werden soll, dann verschwindet der Eintrag einfach aus der Tabelle
            let res = this.model.getResourceModel().getItemByID(resID);
            if(res) {
                this.model.removeResource(res);
            }
        }
  }

  closeDetails(afterCloseCB) {
      this.model.setSelectedItemIDs([]);
      this.setState({
          activeDetailPage: "",
          detailPageAbortable: true,
          menuOpen: false,
          newBookingObjektMenuOpen: false,
          openMainMenuItem: ""
      });

      setTimeout(()=> {
          this.refreshOrientation();
          afterCloseCB && afterCloseCB();
      }, 0);
  }

  openOfferRes() {
      this.refs.newBookingItemMenu.click();
  }

  render() {
    let SELF = this;

    var divStyle = {
        position: "relative",
        background: "white"
    };

    let menuL = 0;
    let searchL = 40;
    let searchT = 0;

    if(window.innerWidth < window.innerHeight) {
      menuL = this.state.windowWidth - 50;
      searchL = this.state.windowWidth - 50;
      searchT = 37;
    }



    var menuButtonStyle = {
        position: "absolute",
        top: "0",
        left: menuL,
        cursor: "pointer",
        display: this.state.detailPageAbortable ? "block" : "none"
    };

    var filterButtonStyle = {
          position: "absolute",
          top: searchT,
          left: searchL,
          cursor: "pointer",
          display: this.state.detailPageAbortable ? "block" : "none"
    };

    let displStart = new LCal().initYMDHM(2000, 1, 1, 0, 0, "Europe/Berlin");
    let displEnd = new LCal().initYMDHM(2000, 1, 3, 0, 0, "Europe/Berlin");

    const classes = this.props.classes;
     return (
         <MuiThemeProvider theme={theme}>
	          <div style={divStyle}>
	          	 <div style={menuButtonStyle}>
                          <IconButton onClick={(event)=>{this.setState({ menuOpen: true, menuAnchorEl: event.currentTarget })}}>
                              <MenuIcon style={{width: 30, height: 30}}/>
                          </IconButton>
                     <Menu
                         id="main-menu"
                         anchorEl={this.state.menuAnchorEl}
                         open={this.state.menuOpen}
                         onClose={()=>this.setState({ menuOpen: false })}
                     >

                         <List className={classes.listRoot}>
                             <ListItem button disabled={!SELF.state.userLoggedIn} onClick={()=>{this.setState({openMainMenuItem: this.state.openMainMenuItem === "newBookingObjekt" ? "" : "newBookingObjekt"})}}>
                                 <ListItemIcon>
                                     <CarIcon />
                                 </ListItemIcon>
                                 <ListItemText inset primary="Neues Buchungsobjekt" />
                                 {this.state.openMainMenuItem === "newBookingObjekt" ? <ExpandLess /> :  <ExpandMore />}
                             </ListItem>

                             <Collapse in={this.state.openMainMenuItem === "newBookingObjekt"} transitionduration="500" unmountOnExit>
                                 <ListItem button onClick={() => {this.setState({menuOpen: false}); this.initNewResource(new Vehicle())}} className={classes.nestedList}>
                                     <ListItemIcon>
                                         <CarIcon />
                                     </ListItemIcon>
                                     <ListItemText inset primary="Fahrzeug" />
                                 </ListItem>

                                 <ListItem button onClick={() => {this.setState({menuOpen: false}); this.initNewResource(new DrivingInstructor())}} className={classes.nestedList}>
                                     <ListItemIcon>
                                         <DrivingInstructorIcon />
                                     </ListItemIcon>
                                     <ListItemText inset primary="Fahrlehrer" />
                                 </ListItem>

                                 <ListItem button onClick={() => {this.setState({menuOpen: false}); this.initNewResource(new StudentDriver())}} className={classes.nestedList}>
                                     <ListItemIcon>
                                         <StudentDriverIcon />
                                     </ListItemIcon>
                                     <ListItemText inset primary="Fahrschüler" />
                                 </ListItem>

                                 <ListItem button onClick={() => {this.setState({menuOpen: false}); this.initNewResource(new Room())}} divider className={classes.nestedList}>
                                     <ListItemIcon>
                                         <RoomIcon />
                                     </ListItemIcon>
                                     <ListItemText inset primary="Raum" />
                                 </ListItem>
                             </Collapse>

                             <ListItem button onClick={()=>{this.setState({menuOpen: false}); this.news()}} disabled={!SELF.state.userLoggedIn}>
                                 <ListItemIcon>
                                     <MailOutlineIcon />
                                 </ListItemIcon>
                                 <ListItemText inset primary="Nachrichten" />
                             </ListItem>

                             <ListItem button onClick={()=>{this.setState({openMainMenuItem: this.state.openMainMenuItem === "extras" ? "" : "extras"})}} disabled={!SELF.state.userLoggedIn} >
                                 <ListItemIcon>
                                     <AddCircleIcon />
                                 </ListItemIcon>
                                 <ListItemText inset primary="Extras" />
                                 {this.state.openMainMenuItem === "extras" ? <ExpandLess /> :  <ExpandMore />}
                             </ListItem>

                             <Collapse in={this.state.openMainMenuItem === "extras"} transitionduration="500" unmountOnExit>
                                 <ListItem button onClick={()=>{this.setState({menuOpen: false}); this.exportData()}} divider className={classes.nestedList}>
                                     <ListItemIcon>
                                         <ExportIcon />
                                     </ListItemIcon>
                                     <ListItemText inset primary="Daten exportieren" />
                                 </ListItem>
                             </Collapse>

                             <ListItem button onClick={()=>{this.setState({openMainMenuItem: this.state.openMainMenuItem === "admin" ? "" : "admin"})}} disabled={!SELF.state.userLoggedIn} divider>
                                 <ListItemIcon disabled={!SELF.state.userLoggedIn}>
                                     <UserRightsIcon />
                                 </ListItemIcon>
                                 <ListItemText inset primary="Verwaltung" />
                                 {this.state.openMainMenuItem === "admin" ? <ExpandLess /> :  <ExpandMore />}
                             </ListItem>

                             <Collapse in={this.state.openMainMenuItem === "admin"} transitionduration="500" unmountOnExit>
                                 <ListItem button onClick={()=>{this.setState({menuOpen: false}); this.editProfile()}} className={classes.nestedList}>
                                     <ListItemIcon>
                                         <ProfileIcon />
                                     </ListItemIcon>
                                     <ListItemText inset primary="Mein Profil" />
                                 </ListItem>
                                 <ListItem button onClick={()=>{this.setState({menuOpen: false}); this.changePassword()}} className={classes.nestedList}>
                                     <ListItemIcon>
                                         <PasswordIcon />
                                     </ListItemIcon>
                                     <ListItemText inset primary="Passwort ändern" />
                                 </ListItem>
                                 <ListItem button onClick={()=>{this.setState({menuOpen: false}); this.settings()}} divider className={classes.nestedList}>
                                     <ListItemIcon>
                                         <SettingsIcon />
                                     </ListItemIcon>
                                     <ListItemText inset primary="Meine Einstellungen" />
                                 </ListItem>
                             </Collapse>

                             <ListItem  button onClick={()=>{this.setState({menuOpen: false}); this.help()}} style={{paddingBottom: 3}}>
                                 <ListItemIcon>
                                     <HelpOutlineIcon />
                                 </ListItemIcon>
                                 <ListItemText inset primary="Hilfe" />
                             </ListItem >

                             <ListItem  button onClick={()=>{this.setState({menuOpen: false}); this.dataprotection()}} style={{paddingTop: 0, paddingBottom: 0}}>
                                 <Typography style={{fontSize: 12, paddingLeft: 56}}>Datenschutzerklärung</Typography>
                             </ListItem >

                             <ListItem  button onClick={()=>{this.setState({menuOpen: false}); this.agb()}} style={{paddingTop: 0, paddingBottom: 0}}>
                                 <Typography style={{fontSize: 12, paddingLeft: 56}}>AGB und Nutzungsbedingungen</Typography>
                             </ListItem >

                             <ListItem  button onClick={()=>{this.setState({menuOpen: false}); this.impressum()}} divider style={{paddingTop: 0, paddingBottom: 0}}>
                                 <Typography style={{fontSize: 12, paddingLeft: 56}}>Impressum</Typography>
                             </ListItem >

                             <ListItem button onClick={()=>{this.setState({menuOpen: false}); this.login()}}>
                                 <ListItemIcon>
                                     <LockOpenIcon />
                                 </ListItemIcon>
                                 <ListItemText inset primary={SELF.state.userLoggedIn ? "Abmelden" : "Anmelden"} secondary={SELF.state.userLoggedIn ? "von "+sessionStorage.getItem('pixipoint_login_user') : null}/>
                             </ListItem>
                         </List>
                     </Menu>
                 </div>

                  <div style={filterButtonStyle}>
                      <IconButton onClick={()=>{this.filter()}} >
                          <SearchIcon style={{width: 30, height: 30}}/>
                      </IconButton>
                  </div>


                  {!this.state.orientationWillChange && <Drawer  open={this.state.activeDetailPage!==""} variant={"persistent"}>
                  {(() => {
                      switch(this.state.activeDetailPage) {
                          case "help":
                              return <Help onClose={()=>this.closeDetails()}/>;
                          case "dataprotection":
                              return <Dataprotection onClose={()=>this.closeDetails()}/>;
                          case "agb":
                              return <AGB onClose={()=>this.closeDetails()}/>;
                          case "impressum":
                              return <Impressum onClose={()=>this.closeDetails()}/>;
                          case "noresource_help":
                              return <NoResourceHelp onOfferResources={(res)=>{this.initNewResource(res)}} onSearchResources={()=>this.setState({activeDetailPage: "filter"})} onClose={()=>this.closeDetails()}/>;
                          case "news":
                              return <News onClose={()=>this.closeDetails()}/>;
                          case "settings":
                              return <Settings onClose={()=>this.closeDetails()}/>;
                          case "export":
                              return <Export onClose={()=>this.closeDetails()}/>;
                          case "login":
                              return <Login onClose={()=>this.closeDetails()} onLogIn={this.loggedIn} initialCard={this.state.initialLoginCard}/>;
                          case "bookingdetails":
                              return <BookingDetails ref='bookingdetails' onClose={()=>this.closeDetails()} onAbortableChange={(isAbortable) => this.setState({detailPageAbortable: isAbortable})} onHistory={()=>this.setState({activeDetailPage: "taskhistory"})} initialEditMode={this.state.selectedTask && this.state.selectedTask.getID() < 0} onSave={(tasks, choosenOpts)=>this.saveTasks(tasks, choosenOpts)} data={this.state.selectedTask} model={this.model}/>;
                          case "VehicleDetails":
                              return <VehicleResourceDetails ref='vehicleDetails' onClose={()=>this.closeDetails()} onAbortableChange={(isAbortable) => this.setState({detailPageAbortable: isAbortable})} onHistory={()=>this.setState({activeDetailPage: "resourcehistory"})} initialEditMode={this.state.selectedResource && this.state.selectedResource.getID() < 0} onSave={()=>this.saveSelectedResource(this.state.selectedResource)} data={this.state.selectedResource} model={this.model}/>;
                          case "DrivingInstructorDetails":
                              return <DrivingInstructorDetails ref='drivingInstructorDetails' onClose={()=>this.closeDetails()} onAbortableChange={(isAbortable) => this.setState({detailPageAbortable: isAbortable})} onHistory={()=>this.setState({activeDetailPage: "resourcehistory"})} initialEditMode={this.state.selectedResource && this.state.selectedResource.getID() < 0} onSave={()=>this.saveSelectedResource(this.state.selectedResource)} data={this.state.selectedResource} model={this.model}/>;
                          case "StudentDriverDetails":
                              return <StudentDriverDetails ref='studentDriverDetails' onClose={()=>this.closeDetails()} onAbortableChange={(isAbortable) => this.setState({detailPageAbortable: isAbortable})} onHistory={()=>this.setState({activeDetailPage: "resourcehistory"})} initialEditMode={this.state.selectedResource && this.state.selectedResource.getID() < 0} onSave={()=>this.saveSelectedResource(this.state.selectedResource)} data={this.state.selectedResource} model={this.model}/>;
                          case "RoomDetails":
                              return <RoomDetails ref='roomDetails' onClose={()=>this.closeDetails()} onAbortableChange={(isAbortable) => this.setState({detailPageAbortable: isAbortable})} onHistory={()=>this.setState({activeDetailPage: "resourcehistory"})} initialEditMode={this.state.selectedResource && this.state.selectedResource.getID() < 0} onSave={()=>this.saveSelectedResource(this.state.selectedResource)} data={this.state.selectedResource} model={this.model}/>;
                          case "DefaultDetails":
                              return <DefaultResourceDetails ref='defaultDetails' onClose={()=>this.closeDetails()} onAbortableChange={(isAbortable) => this.setState({detailPageAbortable: isAbortable})} onHistory={()=>this.setState({activeDetailPage: "resourcehistory"})} initialEditMode={this.state.selectedResource && this.state.selectedResource.getID() < 0} onSave={()=>this.saveSelectedResource(this.state.selectedResource)} data={this.state.selectedResource} model={this.model}/>;
                          case "resourcehistory":
                              return <History onClose={()=>this.closeDetails()} onAbortableChange={(isAbortable) => this.setState({detailPageAbortable: isAbortable})} resource={this.state.selectedResource}/>;
                          case "taskhistory":
                              return <History onClose={()=>this.closeDetails()} task={this.state.selectedTask}/>;
                          case "editRights":
                              return <EditRights onClose={()=>this.closeDetails()}/>;
                          case "editProfile":
                              return <EditProfile onClose={()=>this.closeDetails()}/>;
                          case "changePassword":
                              return <ChangePassword onClose={()=>this.closeDetails()}/>;
                          case "filter":
                              return <Filter onClose={()=>this.closeDetails()} onVisibilityChange={(resID, isvisible)=>this.resourceVisibilityChanged(resID, isvisible)} model={this.model}/>;
                          default: return <Details/>;
                      }
                  })()}
              </Drawer>}
                  <InstrumentedTimeline width={this.state.windowWidth}
                                        height={this.state.windowHeight}
                                        horizontalOrientation={this.state.horizontalOrientation}
                                        showWaitOverlay={this.state.loadingData}
                                        model={this.model}
                                        sliderValues={sliderValues}
                                        start={displStart}
                                        end={displEnd}
                                        timeZone={"Europe/Berlin"}
                                        onClick={(evt) => this.onTimelineClick(evt)}
                                        onPress={(evt) => this.onTimelinePress(evt)}
                                        onLongPress={(evt) => this.onTimelineLongPress(evt)}
                                        dateFormatter={(d)=>LCalFormatter.formatTime(d)}
                                        texts={{
                                            presshere: "Hier 2 Sekunden drücken, um eine neue Buchung zu erstellen"
                                        }}
                  />

                  <Snackbar
                      open={this.state.snackbarOpen}
                      message={this.state.snackbarMessage}
                      autoHideDuration={2500}
                      onClose={()=>this.setState({snackbarOpen: false})}
                  />
                  <Dialog
                      open={this.state.exitDialogOpen}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                      <DialogTitle id="alert-dialog-title">{"Möchten Sie pixipoint wirklich beenden?"}</DialogTitle>
                      <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                              Zum Verlassen klicken Sie bitte nochmals den Zurück-Button.
                          </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={()=>{window.history.pushState(null, null, '#1'); this.setState({exitDialogOpen: false});}} color="primary" autoFocus>
                              Abbrechen
                          </Button>
                      </DialogActions>
                  </Dialog>
	          </div>
         </MuiThemeProvider>
        )
  }
}

export default withStyles(styles)(PixipointMain);
