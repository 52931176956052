import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BookingRightCard from './bookingrightcard';
import MemberEntry from './memberentry.js';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import UserRightsDialog from './userrightsdialog.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import TransferHandler from '../transfer/transferhandler.js';
import Config from '../config/config.js';
import Immutable, {Set} from 'immutable';
import {styles} from '@softmanufaktur/timeline';
import Typography from '@material-ui/core/Typography';
import DistributeRightsDialog from './distributerightsdialog';


class BookingRights extends React.Component {
    constructor(props) {
        super(props);
        this.dialogOKClicked = this.dialogOKClicked.bind(this);
        this.dialogDeleteClicked = this.dialogDeleteClicked.bind(this);

        this.state = {
            members: new Set(),
            editUser: "",
            editUserMode: 'new',
            rightsLoaded: false,
            loadingError: false,
            expanded: false,
            distributeRights: false
        };
    }


    componentDidMount() {
        this.props.onMount && this.props.onMount((res) => this.writeToResource(res));
        //Falls members in den props vorhanden sind, dann werden die genommen
        if (this.props.data.members && this.props.data.members.length > 0) {
            //Wenn die Members per props gesetzt wurden und ein Member dem Profil entspricht, dann den Namen und die Company setzen und als bekannt voraussetzen

            //Im Erfolgsfall den members-Status aktualisieren
            let successFunc = (json) => {
                let members = new Set();
                for (let member of this.props.data.members) {
                    //member.email = member.email;
                    member.forename = json.forename;
                    member.surname = json.surname;
                    member.company = json.company;
                    member.userKnown = true;
                    members = members.add(member);
                }

                this.setState({rightsLoaded: true, members});
            }

            let errorFunc = () => {
                this.setState({loadingError: true})
            }
            TransferHandler.request(Config.getProfileURL(), successFunc, errorFunc);
        } else {
            const transferObj = {resourceid: this.props.data.id}

            //Im Erfolgsfall den members-Status aktualisieren
            let successFunc = (json) => {
                let mArr = json.members.map((val) => {
                    return new MemberEntry(val.email, val.debitor, val.isAdmin ? true : false, val.forename, val.surname, val.company, val.userKnown, val.validated)
                });
                let memb = Immutable.Set(mArr);
                this.setState({rightsLoaded: true, members: memb});
            }

            let errorFunc = () => {
                this.setState({loadingError: true})
            }
            TransferHandler.request(Config.getBookingRightsURL(), successFunc, errorFunc, transferObj);
        }
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }

    writeToResource(res) {
        //TODO: In die Datenbank schreiben, bzw. an ein SAVE-Objekt. Nicht an die Ressource!!
        res.setMembers(this.state.members.toJSON());
    }


    dialogOKClicked(email, debitor, isAdmin, foundUser) {
        //Hier gleich bestimmen, ob es bereits einen User mit dieser E-Mail gibt und welchen forename, surname, company er hat
        let entry = new MemberEntry(email, debitor, isAdmin, foundUser ? foundUser.forename : "", foundUser ? foundUser.surname : "", foundUser ? foundUser.company : "", foundUser ? true : false, foundUser && foundUser.validated ? true : false);
        let items = new Set(this.state.members.delete(this.state.editUser).add(entry));
        this.setState({members: items, editUser: ""});
    }

    dialogDeleteClicked() {
        let items = new Set(this.state.members.delete(this.state.editUser));
        this.setState({members: items, editUser: ""});
    }


    //REFACTOR
    render() {
        const classes = this.props.classes;
        let lastCompany = "@@@@@@";
        let content = this.state.rightsLoaded ?
            (
                <div>
                    {this.state.members.sort((a, b) => {
                        let retVal = a.company.localeCompare(b.company);
                        if (retVal === 0) {
                            retVal = a.email.localeCompare(b.email);
                        }
                        return retVal;
                    }).map((member) => {
                            let retVal = <div key={member.email}>
                                {lastCompany !== member.company &&
                                <Typography variant="subheading" style={{padding: 5, background: "#3F51B5", color: "white"}}>
                                    {member.company && member.company !== "" ? member.company : "UNBEKANNTE FIRMA"}
                                </Typography>}
                                <BookingRightCard member={member} editable={this.props.editMode}
                                                  onClick={() => {
                                                      this.props.editMode && this.setState({
                                                          editUser: member,
                                                          editUserMode: "edit"
                                                      })
                                                  }}/>
                            </div>

                            lastCompany = member.company;
                            return retVal;
                        }
                    )}
                </div>
             )
            : (this.state.loadingError ?
                <div>Fehler beim Laden</div>
                : <CircularProgress />);


        let subtitle = <span style={{display: "flex", flexDirection: "row"}}>
                <span style={{flexGrow: 1}}>
                      {this.state.members.size === 1 ? <div>Das Buchungsobjekt darf nur von <span
                          style={{color: Config.getNeutralColor()}}>{this.state.members.first().email}</span> gebucht
                          werden.</div> : <div>Das Buchungsobjekt darf von <span
                          style={{color: Config.getNeutralColor()}}>{this.state.members.size} Benutzern</span> gebucht
                          werden.
                      </div>}
                 </span>
            </span >

        //Können die Rechte an andere Ressourcen verteilt werden? Nur im Bearbeiten-Modus (im Editiermodus sind die Rechte noch nicht gespeichert), nur wenn der User auf mindestens eine andere Ressource Admin-Rechte hat
        let canDistribute = !this.props.editMode;
        if(canDistribute) {
            canDistribute = false;
            for (let res of this.props.model.getResourceModel().getAll()) {
                if(this.props.data !== res && res.hasAdminRights()) {
                    canDistribute = true;
                    break;
                }
            }
        }

        if(this.state.members.size > 0 || this.props.editMode) {
            return (
                <div>
                    <Card
                        style={{padding: 5, fontSize: 12, fontWeight: 100, background: '#FBFBFB'}}>
                        <CardHeader style={{padding: 7, paddingBottom: 0}}
                                    title="Berechtigungen"
                                    subheader={subtitle}
                                    avatar={<PermIdentityIcon
                                        style={{float: 'left', color: 'gray', width: 30, height: 30, marginRight: 5}}/>}
                        />


                        <CardActions disableActionSpacing>
                            {this.props.editMode &&
                            <Button color="primary" onClick={() => this.setState({editUser: {}, editUserMode: 'new'})}>Benutzer einladen</Button>}
                            {canDistribute &&
                            <Button color="secondary" onClick={() => this.setState({distributeRights: true})}>Kopieren
                                nach</Button>}
                            <div style={{flex: '1 1 auto'}}/>
                            {this.state.members.size > 0 && <IconButton
                                className={classnames(classes.expand, {
                                    [classes.expandOpen]: this.state.expanded,
                                })}
                                onClick={() => this.setState({expanded: !this.state.expanded})}
                            >
                                <ExpandMoreIcon/>
                            </IconButton>
                            }
                        </CardActions>

                        <Collapse in={this.state.expanded} transitionduration='500' unmountOnExit>
                            <CardContent style={{padding: 8}}>
                                {content}
                            </CardContent>
                        </Collapse>
                    </Card>

                    {this.state.distributeRights && <DistributeRightsDialog onClose={() => {
                        this.setState({distributeRights: false})
                    }} resource={this.props.data} model={this.props.model}/>}

                    {this.state.editUser !== "" && <UserRightsDialog onCancel={() => {
                        this.setState({editUser: ""})
                    }} onOK={this.dialogOKClicked} onDelete={this.dialogDeleteClicked} member={this.state.editUser}
                                                                     mode={this.state.editUserMode}/>}
                </div>
            )
        } else {
            return <div></div>
        }
    }
}

export default withStyles(styles)(BookingRights);
