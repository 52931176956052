import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {shouldUseFullScreen} from '@softmanufaktur/timeline';
import ResourceTable from './reschooser/resourcetable';
import TransferHandler from '../transfer/transferhandler.js';
import Config from '../config/config.js';


class DistributeRightsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.resTable = null;
    }

    saveResources(callback) {
        callback && callback();
        if (this.resTable) {
            //Die Rechte von this.props.resource an alle anderen Ressourcen hängen (für die Ressourcen, auf die kopiert werden soll müssen Admin-Berechtigungen vorhanden sein)
            const transferObj = {sourceresid: this.props.resource.id, targetresids: this.resTable.getSelectedItemIDs()}

            //Im Erfolgsfall
            let successFunc = (json) => {
                console.log("kopieren der Benutzerrechte erfolgreich");
            }

            let errorFunc = () => {
                console.log("kopieren der Benutzerrechte fehlgeschlagen");
            }
            TransferHandler.request(Config.getCopyBookingRightsURL(), successFunc, errorFunc, transferObj);
        }
    }

    render() {
        return (
            <Dialog fullScreen={shouldUseFullScreen()}
                    open={true}
                    onClose={this.handleClose}
            >
                <DialogTitle>Rechte verteilen</DialogTitle>
                <DialogContent>
                    <ResourceTable
                        model={this.props.model} resTableRef={(resTable) => this.resTable = resTable} excludeResID={this.props.resource.id}/>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => this.props.onClose()}>
                        Abbrechen
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.saveResources(() => this.props.onClose())
                        }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DistributeRightsDialog;
