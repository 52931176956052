/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */

import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DateTimePicker from './datetimepicker';
import Button from '@material-ui/core/Button';
import shouldUseFullScreen from '../screenutils';

class DateTimePickerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {height: 0, innerWidth: window.innerWidth, innerHeight: window.innerHeight, tab: 0}
    }

    updateSize() {
        //Wenn sich die Fenstergröße geändert hat...
        if (window.innerHeight !== this.state.innerHeight || window.innerWidth !== this.state.innerWidth) {
            this.setState({height: 0, innerWidth: window.innerWidth, innerHeight: window.innerHeight});
        }
        if (this.divElement && this.state.height === 0) {
            const height = this.divElement.clientHeight;
            const width = this.divElement.clientWidth;
            if (height !== this.state.height && width !== this.state.width) {
                this.setState({height});
            }
        }
    }

    componentDidUpdate() {
        setTimeout(() => this.updateSize(), 0);
    }

    render() {
        const {classes, onRequestClose, value, onDateChange, ...other} = this.props;
        const width = Math.min(window.innerWidth, 600);
        const height = this.state.height;

        return (
            <Dialog fullScreen={shouldUseFullScreen()} onClose={onRequestClose} {...other}>
                {this.state.height === 0 && <div style={{height: "100vH"}} ref={(divElement) => {
                    this.divElement = ReactDOM.findDOMNode(divElement)
                }}>

                </div>}

                {this.state.height > 0 &&
                <DateTimePicker width={width} height={height} value={value} onDateChange={onDateChange}
                                tab={this.state.tab} onTabChanged={(tab) => this.setState({tab})}/>}

                <DialogActions>
                    <Button onClick={onRequestClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DateTimePickerDialog;