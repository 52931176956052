import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {NumberField} from '@softmanufaktur/timeline';
import Config from '../config/config.js'
import AlarmIcon from '@material-ui/icons/Alarm';

class BookingDeadlines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {bookingdeadline: props.data.bookingdeadline, bookinghorizont: props.data.bookinghorizont};
  }
  
  componentDidMount() {
      this.props.onMount && this.props.onMount((res)=>this.writeToResource(res));
  }
  
  componentWillUnmount() {

  }

  writeToResource(res) {
      res.setBookingDeadline(this.state.bookingdeadline);
      res.setBookingHorizont(this.state.bookinghorizont);
  }
  
  render() {
      const subtitle = <div>Eine Buchung darf nur geändert werden, wenn der Beginn des Termins zwischen <span style={{color: Config.getNeutralColor()}}>{this.props.editMode?<NumberField value={this.state.bookingdeadline} onChange={(val)=>this.setState({bookingdeadline: val})}/>:this.state.bookingdeadline} Stunden</span> und <span style={{color: Config.getNeutralColor()}}>{this.props.editMode?<NumberField value={this.state.bookinghorizont} onChange={(val)=>this.setState({bookinghorizont: val})}/>:this.state.bookinghorizont} Tagen</span> in der Zukunft liegt.</div>

       return <Card
              style={{padding: 5, fontSize: 12, fontWeight: 100, background: '#FBFBFB'}}>
            <CardHeader style={{padding: 7, paddingBottom: 0}}
                title="Buchungsfristen"
                subheader={subtitle}
                avatar={<AlarmIcon
                    style={{float: 'left', color: 'gray', width: 30, height: 30, marginRight: 5}}/>}
            />
        </Card>
  }
}

export default BookingDeadlines;
