import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const path = "M 22.871511,5.0631345 0.91656037,5 C 0.93918406,10.331928 0.96913969,12.213975 1,16 l 2,0 c 0,1.65 1.34,3 3,3 1.66,0 3,-1.35 3,-3 l 5.5,0 c 0,1.65 1.34,3 3,3 1.66,0 3,-1.35 3,-3 l 2.436865,-5e-6 c 0,0 5.352252,3.494872 4.832192,3.031052 -0.52006,-0.46382 -4.828992,-4.633792 -4.832192,-5.063728 z M 6,17.5 c -0.83,0 -1.5,-0.67 -1.5,-1.5 0,-0.83 0.67,-1.5 1.5,-1.5 0.83,0 1.5,0.67 1.5,1.5 0,0.83 -0.67,1.5 -1.5,1.5 z m 11.5,0 C 16.67,17.5 16,16.83 16,16 c 0,-0.83 0.67,-1.5 1.5,-1.5 0.83,0 1.5,0.67 1.5,1.5 0,0.83 -0.67,1.5 -1.5,1.5 z";

const TruckTrailer = (props) => (
		  <SvgIcon {...props}>
		    <path d={path} />
		  </SvgIcon>
);


export default TruckTrailer;
export {path};