import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const path = "M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z";

const Room = (props) => (
		  <SvgIcon {...props}>
		    <path d={path} />
		  </SvgIcon>
);


export default Room;
export {path};