/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 * --------------------------------------------------------
 * Die Daten, die für die Anzeige der Ressource gebraucht werden
 * Diese Daten werden nicht mit den Ressourcendaten gespeichert, sondern allenfalls profilabhängig
*/
class DisplayData {
    constructor() {
        this.height = 100; //Die Zeilen- oder Balkenhöhe
        this.relYStart = undefined; //Die y-Position relativ zur ersten Ressource. Diese wird bei Änderungen einer Höhe oder wenn eine Ressource dazukommt oder wegfällt neu berechnet.
        this.successor = undefined; //Nachfolgebalken auf gleichem Level
    }

    setHeight(height) {
        this.height = height;
    }

    getHeight() {
        return this.height;
    }

    setRelativeYStart(y) {
        this.relYStart = y;
    }

    getRelativeYStart() {
        return this.relYStart;
    }

    setSuccessor(succ) {
        this.successor = succ;
    }

    getSuccessor() {
        return this.successor;
    }
}

export default DisplayData
