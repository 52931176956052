import React from 'react';
import Paper from '@material-ui/core/Paper';

export default (props)=>{
    return <Paper style={{marginTop: 5, marginBottom: 5, cursor: props.editable ? "pointer" : "default"}} onClick={props.onClick}>
        <div style = {{padding: 5}}>
            <b>{props.optionGroup.name}</b>
            <br/>
            {props.optionGroup.options}
            <br/>
            {props.optionGroup.multichoice ? "Mehrfachauswahl" : "Einfachauswahl"}
        </div>
    </Paper>



  //  case 0: return entry.name;
  //  case 1: return entry.options;
  //  case 2: return entry.multichoice;
}