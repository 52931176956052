const styles = theme => ({
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    flexGrow: {
        flex: '1 1 auto',
    },
    listRoot: {
        width: '100%',
        maxWidth: 360,
        background: theme.palette.background.paper,
    },
    nestedList: {
        paddingLeft: theme.spacing.unit * 4,
    },

    field: {
        marginBottom: 10
    }
});

export default styles;