import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const path = "M 15.5 5.5 c 1.1 0 2 -0.9 2 -2 s -0.9 -2 -2 -2 s -2 0.9 -2 2 s 0.9 2 2 2 Z M 5 12 c -2.8 0 -5 2.2 -5 5 s 2.2 5 5 5 s 5 -2.2 5 -5 s -2.2 -5 -5 -5 Z m 0 8.5 c -1.9 0 -3.5 -1.6 -3.5 -3.5 s 1.6 -3.5 3.5 -3.5 s 3.5 1.6 3.5 3.5 s -1.6 3.5 -3.5 3.5 Z m 5.8 -10 l 2.4 -2.4 l 0.8 0.8 c 1.3 1.3 3 2.1 5.1 2.1 V 9 c -1.5 0 -2.7 -0.6 -3.6 -1.5 l -1.9 -1.9 c -0.5 -0.4 -1 -0.6 -1.6 -0.6 s -1.1 0.2 -1.4 0.6 L 7.8 8.4 c -0.4 0.4 -0.6 0.9 -0.6 1.4 c 0 0.6 0.2 1.1 0.6 1.4 L 11 14 v 5 h 2 v -6.2 l -2.2 -2.3 Z M 19 12 c -2.8 0 -5 2.2 -5 5 s 2.2 5 5 5 s 5 -2.2 5 -5 s -2.2 -5 -5 -5 Z m 0 8.5 c -1.9 0 -3.5 -1.6 -3.5 -3.5 s 1.6 -3.5 3.5 -3.5 s 3.5 1.6 3.5 3.5 s -1.6 3.5 -3.5 3.5 Z";

const Others = (props) => (
		  <SvgIcon {...props}>
		    <path d={path} />
		  </SvgIcon>
);


export default Others;
export {path};