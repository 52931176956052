/**
* Eine Eintrag in der Nachrichtentabelle
*/
class NewsEntry {
  constructor(id, subject, creationDate, isread) {
    this.id = id;
    this.subject = subject;
    this.creationDate = creationDate;
    this.isread = isread;
  }
}

export default NewsEntry
