import React from 'react';
import Button from '@material-ui/core/Button';
import Details from './ui-components/details';
import 'whatwg-fetch';
import Config from './config/config.js';

class Export extends React.Component {
  constructor(props) {
    super(props);
    this.state = {contentLoaded: false};
  }

  render() {
    let sessID = sessionStorage.getItem('pixipoint_sessionid');

    return (
		<Details onClose={()=>this.props.onClose()} title="Export">
			<Button variant="raised" href={Config.getExportURL()+"?sessionid="+sessID} >Daten nach MS-Excel exportieren</Button>
		</Details>
    )
  }

  
}

export default Export;
