import React from 'react';
import Timeline from './timeline';
import Slider from '../slider/slider';
import NowButton from '../nowbutton/nowbutton.js';
import LCal from '../calendar/lcal.js';
import LCalHelper from '../calendar/lcalhelper.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LCalFormatter from '../calendar/lcalformatter';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';

class InstrumentedTimeline extends React.Component {
    constructor(props) {
        super(props);

        this.onTimelinePress = this.onTimelinePress.bind(this);
        this.onTimelineLongPress = this.onTimelineLongPress.bind(this);
        this.onTimelineClick = this.onTimelineClick.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.onSliderChange = this.onSliderChange.bind(this);
        this.onOffsetChange = this.onOffsetChange.bind(this);
        this.mouseMoveHandle = 0;
        this.state = {
            controllerValue: 0,
            measureInterval: null,
            measureDurationLock: false,
        }
    }

    componentDidMount() {
        this.goToNow();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({measureInterval: nextProps.initialMeasureInterval});
    }

    goToNow() {
        let now = new LCal();
        now.setJulianMinutes(LCalHelper.getNowMinutes());
        this.goToDate(now);
    }

    goToDate(d) {
        if (d instanceof LCal) {
            d = d.clone();
            let timeline = this.refs.timeline;
            let displMinutes = timeline.getDisplayedMinutes();
            d.addMinutes(-Math.abs(displMinutes / 3));
            timeline.animateTo(d);
        } else {
            this.goToNow();
        }
    }

    goToResource(res) {
        let timeline = this.refs.timeline;
        timeline.scrollToResource(res);
    }

    animateTo(startLCal, endLCal, animationCompletedCB) {
        this.refs.timeline.animateTo(startLCal, endLCal, animationCompletedCB);
    }

    getStartTime() {
        return this.refs.timeline.canvasStartTime;
    }

    getEndTime() {
        return this.refs.timeline.canvasEndTime;
    }

    turnButtonToNow() {
        var timeline = this.refs.timeline;
        var nowbutton = this.refs.nowbutton;

        let now = LCalHelper.getNowMinutes();
        let nowX = timeline.getXPosForTime(now);
        //Hier muss der Winkel bestimmt werden, um den der Button gedreht werden muss
        let timelineX = timeline.getCanvas().getBoundingClientRect().left;
        let timelineY = timeline.getCanvas().getBoundingClientRect().top;
        let buttonX = nowbutton.getCanvas().getBoundingClientRect().left + Math.abs(nowbutton.getCanvas().width / 2);
        let buttonY = nowbutton.getCanvas().getBoundingClientRect().top + Math.abs(nowbutton.getCanvas().height / 2);

        let angle = 0;

        if (this.props.horizontalOrientation) {
            angle = Math.atan((timelineX + nowX - buttonX) / Math.abs(timelineY - buttonY));
        } else {
            let timelineRight = timeline.getCanvas().getBoundingClientRect().right;
            angle = Math.atan((timelineY + nowX - buttonY) / Math.abs(timelineRight - buttonX)) + Math.PI / 2;
        }

        this.refs.nowbutton.setAngle(angle);
    }

    onZoomChange(startLCal, endLCal) {
        // this.refs.slider.setControllerValue(endLCal.getJulianMinutes() - startLCal.getJulianMinutes());
        this.setState({controllerValue: endLCal.getJulianMinutes() - startLCal.getJulianMinutes()});
    }

    onSliderChange(displayedMinutes) {
        //Die Timeline muss auf Veränderungen der Zoomstufe im Slider reagieren
        this.refs.timeline.zoomToDisplayMinutes(displayedMinutes);
        this.setState({controllerValue: displayedMinutes});
    }

    onTimelinePress(timelineevent) {
        if (this.props.onPress) {
            this.props.onPress(timelineevent);
        }
        this.refs.nowbutton.closeJumpTo();
    }

    onMouseMove(timelineevent) {
        this.props.onMouseMove && this.props.onMouseMove(timelineevent);
    }

    onTimelineLongPress(timelineevent) {
        if (this.props.onLongPress) {
            this.props.onLongPress(timelineevent);
        }
        this.refs.nowbutton.closeJumpTo();
    }

    onTimelineClick(timelineevent) {
        if (timelineevent.isTimeHeaderPressed()) {
            let time = timelineevent.getTime();
            this.setState({menuIsVisible: false});

            const curDisplStartJulMin = this.refs.timeline.workStartTime.getJulianMinutes();
            const curDisplEndJulMin = this.refs.timeline.workEndTime.getJulianMinutes();

            //Nächsten Sliderwert bestimmen
            const curDuration = curDisplEndJulMin - curDisplStartJulMin;
            let nextDuration = 0;
            for (let v of this.props.sliderValues) {
                nextDuration = v.value;
                if (v.value < curDuration) {
                    break;
                }
            }

            //let sliderValue = this.refs.slider.getNeighbourControllerValues()[1];
            //if (sliderValue !== null) {
            let newStart = time.clone();
            let newStartJulMin = newStart.getJulianMinutes();

            let clickPercentage = (newStartJulMin - curDisplStartJulMin) / (curDisplEndJulMin - curDisplStartJulMin);

            newStart.addMinutes(-Math.abs(nextDuration * clickPercentage));
            let newEnd = time.clone();
            newEnd.addMinutes(Math.abs(nextDuration * (1 - clickPercentage)));
            this.refs.timeline.animateTo(newStart, newEnd);


            //Header drücken bedeutet immer die Details zu schließen, falls dieses noch offen ist
            //SELF.setState({menuIsVisible: false});

        } else if (this.props.onClick) {
            this.props.onClick(timelineevent);
        }
    }

    onOffsetChange() {
        //Der Button muss auf die Jetzt-Zeit zeigen
        this.turnButtonToNow();
    }

    render() {
        let sliderWidth = Math.max(this.props.width / 3, 200);

        let buttonStyle;
        let measureBoxStyle;
        //let isHorizontal = window.innerWidth >= window.innerHeight;
        if (this.props.horizontalOrientation) {
            buttonStyle = {
                position: "absolute",
                bottom: "20px",
                right: 20
            }
            measureBoxStyle = {
                position: "absolute",
                top: 5,
                right: 10
            }
        } else {
            buttonStyle = {
                position: "absolute",
                bottom: "20px",
                left: 20
            }
            measureBoxStyle = {
                position: "absolute",
                top: 5,
                left: 5
            }
        }


        const waitStyle = {
            position: "absolute",
            top: this.props.height / 2 - 60,
            left: this.props.width / 2 - 80,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        };

        let endMeasureButton = <div>
            <Button variant="raised" size="small" color="secondary" style={{margin: 10}} onClick={() => {
                this.setState({measureDurationLock: !this.state.measureDurationLock});
            }}>
                Dauer
                {this.state.measureDurationLock ? <Lock/> : <LockOpen/>}
            </Button>
            <Button variant="raised" size="small" color="secondary" style={{margin: 10}} onClick={() => {
                if (this.props.onMeasureIntervalChanged) {
                    this.props.onMeasureIntervalChanged(null);
                }
            }}>
                Beenden
                <ExitToApp/>
            </Button>
        </div>

        //#F50057 = 245 00 87
        return (
            <div>
                {this.state.measureInterval && <div style={measureBoxStyle}>
                    <Typography variant="subheading"
                                style={{color: "white", background: "rgba(221, 44, 0, 0.7)", padding: 10}}>
                        {LCalFormatter.formatDuration(this.state.measureInterval)}
                    </Typography>
                </div>}

                <div style={buttonStyle}>
                    <div style={{
                        display: 'flex',
                        justifyContent: this.props.horizontalOrientation ? 'flex-end' : 'flex-start',
                        alignItems: 'center'
                    }}>
                        {this.props.horizontalOrientation && this.state.measureInterval &&
                        endMeasureButton
                        }

                        <div style={{width: 40, height: 40}}>
                            <NowButton ref="nowbutton" width={40} height={40} onJump={(d) => this.goToDate(d)}
                                       yearPositions={this.props.yearPositions}
                                       onClose={this.props.onNowDialogClose}>
                                <div>
                                    <Slider ref='slider' width={sliderWidth} height={70} onChange={this.onSliderChange}
                                            sliderValues={this.props.sliderValues}
                                            controllerValue={this.state.controllerValue}/>
                                    {this.props.nowbuttonChildren}
                                </div>
                            </NowButton>
                        </div>


                        {!this.props.horizontalOrientation && this.state.measureInterval &&
                        endMeasureButton
                        }

                    </div>
                </div>

                {this.props.showWaitOverlay &&
                <div style={waitStyle}><CircularProgress size={80}/><Typography>Lade Daten...</Typography></div>}
                <Timeline ref="timeline"
                          {...this.props}
                          onClick={(evt) => this.onTimelineClick(evt)}
                          onPress={(evt) => this.onTimelinePress(evt)}
                          onLongPress={(evt) => this.onTimelineLongPress(evt)}
                          onZoomChange={(startLCal, endLCal) => this.onZoomChange(startLCal, endLCal)}
                          onOffsetChange={this.onOffsetChange}
                          measureDurationLock={this.state.measureDurationLock}
                          onMeasureIntervalChanged={(interval, isAligning) => {
                              isAligning ? this.setState({measureInterval: interval}) : this.props.onMeasureIntervalChanged && this.props.onMeasureIntervalChanged(interval)
                          }}
                />
            </div>
        )
    }


}

export default InstrumentedTimeline;
