class BaseConfig {

    static getBaseURL() {
        return '/app/php/';
    }

  static getHelpURL() {
      return this.getBaseURL()+'help/help.php';
  }

    static getDataprotectionURL() {
        return this.getBaseURL() + 'help/dataprotection.php';
    }

    static getAGBURL() {
        return this.getBaseURL() + 'help/agb.php';
    }

    static getImpressumURL() {
        return this.getBaseURL() + 'help/impressum.php';
    }

  static getLoginURL() {
	     return this.getBaseURL()+'account/login.php';
  }

  static getCreateAccountURL() {
         return this.getBaseURL()+'account/createaccount.php';
  }

  static getBookingRightsURL() {
      return this.getBaseURL()+'account/bookingrights.php';
  }

  static getOptionGroupsURL() {
      return this.getBaseURL()+'account/optiongroups.php';
  }

  static getChoosenOptionsURL() {
      return this.getBaseURL()+'account/choosenoptions.php';
  }

  static getAutocompleteURL() {
      return this.getBaseURL()+'autocomplete/autocomplete.php';
  }

  static getPossibleRessourcesURL() {
        return this.getBaseURL()+'account/possibleresources.php';
  }

  static getPossibleDebitorsURL() {
        return this.getBaseURL()+'account/possibledebitors.php';
  }

  static getVisibleResourceURL() {
      return this.getBaseURL()+'account/visibleresource.php';
  }

  static getSentRememberanceURL() {
      return this.getBaseURL()+"notification/sendremembrance.php"
  }

  static getSaveURL() {
      return this.getBaseURL()+'transactiondata/savedata.php';
  }
  
  static getRequestNewPasswordURL() {
	     return this.getBaseURL()+'account/requestnewpassword.php';
  }
  
  static getChangePasswordURL() {
	     return this.getBaseURL()+'account/changepassword.php';
}

  static getLoadDataURL() {
	     return this.getBaseURL()+'transactiondata/readdata.php';
  }
  
  static getHistoryURL() {
	     return this.getBaseURL()+'history/readhistory.php';
  }

    static getHistoryUsersURL() {
        return this.getBaseURL()+'history/readhistoryusers.php';
    }

  static getNewsListURL() {
	     return this.getBaseURL()+'notification/mynews.php';
  }

  static getMailURL() {
	     return this.getBaseURL()+'notification/getmail.php';
  }
  
  static getProfileURL() {
	  return this.getBaseURL()+'account/getprofile.php';
  }
  
  static setProfileURL() {
      return this.getBaseURL() + 'account/setprofile.php';
  }

  static getExportURL() {
      return this.getBaseURL()+'export/export.php';
  }

  static getUploadImageURL() {
      return this.getBaseURL()+'image/upload.php';
  }

  static getCopyBookingRightsURL() {
      return this.getBaseURL()+'account/copybookingrights.php';
  }

  static getCopyBookingOptionsURL() {
      return this.getBaseURL()+'account/copybookingoptions.php';
  }


  static getErrorColor() {
      return "red";
  }

  static getNeutralColor() {
      return "#0067b0";
  }

  static getPositiveColor() {
      return "green";
  }

  static getWarningColor() {
      return "#e68a00";
  }

  static getBookingNotEditableColor() {
      return "#CCCCCC";
  }

    static getBookingEditableByResAdminColor() {
        return "#c6d8ec";
    }

    static getBookingEditableByMeColor() {
        return "#3F51B5";
    }

    /**
     * Liefert die zusammengesetzte Zeichenkette für den Namen.
     * Bei Änderungen muss auch helper.php geändert werden
     * @param forename
     * @param surname
     * @param company
     * @returns {*|string}
     */
  static getNameString(forename, surname, company) {
        forename = forename || "";
        surname = surname || "";
        company = company || "";
        let name = forename;

        if (name.length > 0 && surname.length > 0) {
            name += " "+surname;
        } else {
            name += surname;
        }
        if (name.length > 0 && company.length > 0) {
            name += ", "+company;
        } else {
            name += company;
        }
        return name;
    }
}

export default BaseConfig;
