import React from 'react';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';


const ResourceItem = ({ res, onClick, resIsPending, model }) =>

        <div style={{display: "flex", border: "1px solid lightgray", background: res.isVisible ? "white" : "#EEEEEE", color: res.isVisible ? "black" : "lightgray", cursor: "pointer", margin: 5, padding: 10}}>
            <div style = {{marginRight: 5}}>
                {/* <img style={{width: 32, height: 32, verticalAlign: 'middle', margin: 2}} src={this.props.model.getIcon(res).src}/>*/}
                <img style={{width: 32, height: 32, verticalAlign: 'middle', margin: 2}} src={model.getIcon(res).src} alt=""/>
            </div>
            <div style = {{flexGrow: 30}}>
                <Typography variant="subheading" color="inherit">
                    {res.name}
                </Typography>
                <Typography variant="body1" color="inherit">
                    {res.secname}
                </Typography>
            </div>
            <div>
                <div>
                    <div style={{textAlign: "right"}}>
                        <Switch
                            color="primary"
                            disabled={resIsPending}
                            checked={res.isVisible || resIsPending}
                            onChange={onClick}
                            style={{height:"20px"}}
                        />
                    </div>
                    {res.lender && <Typography variant="caption"  style={{marginLeft: "auto", color:"gray", maxWidth: "20vH", textAlign: "right"}}>
                        von {res.lender}
                    </Typography>}
                </div>
            </div>
        </div>


export default ResourceItem;