import React from 'react';

//Führt einen JSON-Request, gibt die sessionid mit und führt die successFunction aus, der das json-Ergebnis mitgegeben wird
class TransferHandler {
 
  static request(url, successFunction, errorFunc, transferObj) {
	  	if(!transferObj) {
	  		transferObj = {};
	  	}
	  	
	  	//Falls eine Session-ID vorhanden ist, dann wird dem Request eine hinzugefügt, ansonsten nicht (z.B. bei Anmeldung)
	  	let sessID = sessionStorage.getItem('pixipoint_sessionid');
	  	if(sessID) {
	  		transferObj.sessionid = sessID;
	  	}
	  	
		let headers = new Headers({
			 "Content-Type" :  "application/json"
		});
	    
		let request = new Request(url, {
				method: 'post',
				headers: headers,
				body: JSON.stringify(transferObj)
		});
		 

	    fetch(request).then(function(response) {
	        return response.json();
	    }).then(function(json) {
	    	//Kommt ein Fehler zurück, dann zunächst das Standard-Fehlerhandling ausführen falls keine Fehlerfunktion mitgegeben wurde
	    	if(json.retval === "NOK") {
	    		if(errorFunc) {
	    		  errorFunc(parseInt(json.code), json);
	    		}
	    	} else if(successFunction) {
	    		successFunction(json);	 
	    	}
	    }).catch(function(ex)  {
			console.log("Es ist ein Fehler beim Aufruf von "+url+" aufgetreten:");
			console.log(ex);
			if(errorFunc) {
				errorFunc(500); //Es kam etwas zurück, das kein JSON ist
	    	}
		});
    }

    static postForm(url, successFunction, errorFunc, form) {
        let request = new Request(url, {
            method: 'post',
			body: new FormData(form)
        });

        fetch(request).then(function(response) {
            return response.json();
        }).then(function(json) {
            //Kommt ein Fehler zurück, dann zunächst das Standard-Fehlerhandling ausführen falls keine Fehlerfunktion mitgegeben wurde
            if(json.retval === "NOK") {
                if(errorFunc) {
                    errorFunc(parseInt(json.code), json);
                }
            } else if(successFunction) {
                successFunction(json);
            }
        }).catch(function(ex)  {
            console.log("Es ist ein Fehler beim Aufruf von "+url+" aufgetreten:");
            console.log(ex);
            if(errorFunc) {
                errorFunc(500); //Es kam etwas zurück, das kein JSON ist
            }
        });
    }
	
	static getDialogInfo(errorCode) {
		switch(errorCode) {
		case 0: return {dialogTitle: "OK", dialogContent: "OK"}; //Sollte nie angefragt werden
		case 500: return {dialogTitle: "Interner Serverfehler", dialogContent: (<div>Es ist ein interner Serverfehler aufgetreten.<br/>Bitte setzen Sie sich mit einem Ansprechpartner von pixipoint in Verbindung.</div>)};
		case 901: return {dialogTitle: "Fehler bei der Anmeldung", dialogContent: (<div>Der Benutzer ist in unserem System vorhanden, er wurde aber noch nicht von Ihnen über den Link in der E-Mail bestätigt.</div>)};
		case 902: return {dialogTitle: "Fehler bei der Anmeldung", dialogContent: (<div>Der Benutzer oder das eingegebene Passwort sind uns nicht bekannt.</div>)};
		case 903: return {dialogTitle: "Fehler beim Ändern des Passworts", dialogContent: (<div>Das alte Kennwort ist uns nicht bekannt.</div>)};
		
		case 910: return {dialogTitle: "Eingabefehler", dialogContent: (<div>Die eingegebenen Daten entsprechen nicht den Richtlinien.</div>)};
		case 911: return {dialogTitle: "Fehler", dialogContent: (<div>Daten konnten nicht aktualisiert werden.</div>)};
		
		case 920: return {dialogTitle: "Fehler", dialogContent: (<div>Die eingegebene E-Mailadresse ist bereits vorhanden</div>)};
		case 921: return {dialogTitle: "Fehler", dialogContent: (<div>Der Benutzer konnte nicht angelegt werden</div>)};
		
		
		case 990: return {dialogTitle: "Fehler", dialogContent: (<div>Google reCaptcha: Sie konnten nicht als Mensch identifiziert werden.</div>)};
		
		case 998: return {dialogTitle: "Fehler", dialogContent: (<div>Die Eingaben sind nicht plausibel.</div>)};
		case 999: return {dialogTitle: "Fehler", dialogContent: (<div>Die Sitzung ist nicht mehr gültig.<br/>Bitte melden Sie sich erneut an.</div>)};
		default: return {dialogTitle: "Fehler", dialogContent: (<div>Es ist ein unbekannter Fehler mit der Nummer {errorCode} aufgetreten.<br/>Bitte setzen Sie sich mit einem Ansprechpartner von pixipoint in Verbindung.</div>)};
		}
	}

}

export default TransferHandler;




/*else if (json.code === "1") {
	  //Interner Serverfehler
	  this.setState({dialogOpen: "INTERNAL_ERROR"});
  } else if (json.code === "2") {
      //User oder Passwort falsch
	  this.setState({dialogOpen: "SESSION_INVALID"});
  } else if (json.code === "3") {
      //Altes Passwort falsch
	  this.setState({dialogOpen: "OLD_PWD_INCORRECT"});
  }

*/


/*let dialogTitle = null;		  
let dialogContent = null;
if(this.state.dialogOpen === "INTERNAL_ERROR") {
	  dialogTitle = "Fehler";
	  dialogContent = (<div>Es ist ein unbekannter Fehler aufgetreten.<br/>Bitte setzen Sie sich mit einem Ansprechpartner von pixipoint in Verbindung.</div>);
}  else if (this.state.dialogOpen === "SESSION_INVALID") {
	  dialogTitle = "Sitzung ungültig";
	  dialogContent = (<div>Bitte melden Sie sich erneut an.</div>);
} else if (this.state.dialogOpen === "PASSWORD_POLICY") {
	  dialogTitle = "Fehler beim Ändern des Passworts";
	  dialogContent = (<div>Das gewählte Passwort muss mindestens 8 Zeichen enthalten.</div>);
} else if (this.state.dialogOpen === "PASSWORDS_NOT_EQUAL") {
	  dialogTitle = "Fehler beim Ändern des Passworts";
	  dialogContent = (<div>Das wiederholte Passwort unterscheidet sich vom ersten eingegebenen Passwort.</div>);
} else if (this.state.dialogOpen === "OLD_PWD_INCORRECT") {
	  dialogTitle = "Fehler beim Ändern des Passworts";
	  dialogContent = (<div>Ihr altes Passwort ist nicht korrekt.</div>);
	  
	*/