import React from 'react';
import Details from './ui-components/details';
import 'whatwg-fetch';
import Typography from '@material-ui/core/Typography';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {contentLoaded: false};
  }

  render() {
    const styles = {
			  outerContainer: {
				  height: "100%"
			  },
			  contentStyle: {
				  	padding: 10,
	    		    height: "90%",
	    		    overflowY: "auto"			    		  
			  }
	};
	  

    return (
		<Details onClose={()=>this.props.onClose()} title="Einstellungen">
	    		<div style={styles.contentStyle} key = "news">
	    			<Typography>Einstellungen</Typography>
	    		</div>
    		</Details>
    )
  }

  
}

export default Settings;
