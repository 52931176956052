import React from 'react';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton/IconButton';
import NavigationCloseIcon from '@material-ui/icons/Close';
import 'whatwg-fetch';
import Vehicle from './data/vehicle.js';
import DrivingInstructor from './data/drivinginstructor.js';
import StudentDriver from './data/studentdriver.js';
import Room from './data/room.js';
import CarIcon from './icons/car.js';
import StudentDriverIcon from './icons/studentdriver.js'
import DrivingInstructorIcon from './icons/drivinginstructor.js'
import RoomIcon from './icons/room.js'
import SearchIcon from '@material-ui/icons/Search';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

class NoResourceHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
	const styles = {
			  outerContainer: {
				  height: "100%",
                  overflow: "hidden"
			  },
			  contentStyle: {
				  	padding: 10,
	    		    height: "90%",
	    		    overflowY: "auto"			    		  
			  }
	};
	  

    return (
    		<div style={styles.outerContainer}>
				<AppBar position="static">
					<Toolbar style={{paddingLeft: 0}}>
						<IconButton color="inherit" onClick={(evt)=>{if(this.props.onClose) {this.props.onClose()}}}>
							<NavigationCloseIcon />
						</IconButton>
						<Typography variant="title" color="inherit" style={{flex: 1}}>
							Neu hier?
						</Typography>
					</Toolbar>
				</AppBar>
	    		<div style={styles.contentStyle} key = "news">
	    			Was möchten Sie als nächstes tun?
					<br/>
					<br/>
					<Button
						color="primary"
						onClick={()=>this.props.onSearchResources()}
						icon = {<SearchIcon />}
					>Buchungsobjekte suchen</Button>
					<br/>
					<Button
						color="primary"
						onClick={()=>this.props.onOfferResources(new Vehicle())}
						icon = {<CarIcon />}
					>Fahrzeug anlegen</Button>
					<br/>
					<Button
						color="primary"
						onClick={()=>this.props.onOfferResources(new DrivingInstructor())}
						icon = {<DrivingInstructorIcon />}
					>Fahrlehrer anlegen</Button>
					<br/>
					<Button
						color="primary"
						onClick={()=>this.props.onOfferResources(new StudentDriver())}
						icon = {<StudentDriverIcon />}
					>Fahrschüler anlegen</Button>
					<br/>
					<Button
						color="primary"
						onClick={()=>this.props.onOfferResources(new Room())}
						icon = {<RoomIcon />}
					>Raum anlegen</Button>
	    		</div>
    		</div>
    )
  }

  
}

export default NoResourceHelp;
