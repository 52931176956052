/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import AbstractModel from './abstractmodel.js';
import ResourceModel from './resourcemodel.js'
import LCal from '../calendar/lcal.js';
import LCalHelper from '../calendar/lcalhelper.js';

/**
 * Die Datenquelle für RessourcenIntervalle
 */
class TaskModel extends AbstractModel {
    constructor() {
        super();
        this.movedTasksChangeCallbacks = [];
        this.resourceModel = new ResourceModel(this);
        this.movedTasks = [];
        this.verticalPadding = 0;
        this.barSize = 100;
        this.expandBars = false; //Wenn true, dann können sich die Balken ausdehnen, so dass nicht alle Balken die selbe Höhe haben
        this.resID2TaskCnt = new Map();
    }

    _setDisplayDataDirty(dirty) {
        this.displDataDirty = dirty;
    }

    addMovedTasksChangeCallback(listener) {
        this.movedTasksChangeCallbacks.push(listener);
    }

    addDataChangeCallback(listener) {
        super.addDataChangeCallback(listener);
        this.getResourceModel().addDataChangeCallback(listener);
    }

    _fireMovedTasksChanged() {
        for (let o of this.movedTasksChangeCallbacks) {
            o();
        }
    }

    getResourceModel() {
        return this.resourceModel;
    }

    getDisplayedStart(task) {
        if (task.getStart()) {
            return task.getStart().getEarliestLCal();
        } else {
            //Gibt es ein innerEvent mit einer Startzeit?
            if (task.innerEvents) {
                //Suchen des Events mit der frühsten Startzeit
                let earliestStart = null;
                for (let evt of task.innerEvents) {
                    if (evt.startYear) {
                        let start = new LCal().initYMDHM(evt.startYear, evt.startMonth, evt.startDay, evt.startHour, evt.startMinute, evt.startPrecision, evt.startType).getEarliestLCal();
                        if (earliestStart === null || start.before(earliestStart)) {
                            earliestStart = start;
                        }
                    }
                }
                if (earliestStart) {
                    return earliestStart;
                }
            }


            let now = LCalHelper.getNowMinutes();
            if (task.getEnd() === null || task.getEnd().getEarliestLCal().getJulianMinutes() > now) {
                return new LCal().setJulianMinutes(LCalHelper.getNowMinutes()).setTimeZone("Europe/Berlin"); //TODO: Irgendwoher eine Standardzeitzone holen
            } else {
                return task.getEnd().getEarliestLCal();
            }
        }
    }

    getDisplayedEnd(task) {
        if (task.getEnd()) {
            return task.getEnd().getLatestLCal();
        } else {
            //Gibt es ein innerEvent mit einer Startzeit?
            if (task.innerEvents) {
                //Suchen des Events mit der frühsten Startzeit
                let latestEnd = null;
                for (let evt of task.innerEvents) {
                    if (evt.startYear) {
                        let end = new LCal().initYMDHM(evt.startYear, evt.endMonth, evt.endDay, evt.endHour, evt.endMinute, evt.endPrecision, evt.endType).getLatestLCal();
                        if (latestEnd === null || end.after(latestEnd)) {
                            latestEnd = end;
                        }
                    }
                }

                if (latestEnd) {
                    return latestEnd;
                }
            }

            let now = LCalHelper.getNowMinutes();
            if (task.getStart() === null || task.getStart().getLatestLCal().getJulianMinutes() < now) {
                return new LCal().setJulianMinutes(now).setTimeZone("Europe/Berlin"); //TODO: Irgendwoher eine Standardzeitzone holen
            } else {
                return task.getStart().getLatestLCal();
            }
        }
    }


    recomputeDisplayData(getTaskBarBoundsForLevelComputation) {
        if (this.isDisplayDataDirty()) {
            this.resID2TaskCnt = new Map();
            this.sort();
            let resID2levelOccupiedUntil = new Map();
            let reslevel2OrderedTasks = new Map();

            //Bestimmen, auf welchem Level ein Vorgang liegen muss (wegen Überschneidungen)
            //Dazu die Vorgänge von der ältesten Startzeit her durchlaufen
            //levelOccupiedUntil.length ist am Ende die Gesamtanzahl an benötigten Levels
            for (let task of this.data) {
                task.getDisplayData().setLevel(0);
                task.getDisplayData().setSuccessor(null);

                let res = this.getResourceModel().getItemByID(task.getResID());
                if (res !== undefined) {
                    let levelOccupiedUntil = resID2levelOccupiedUntil.get(res.getID());
                    if (!levelOccupiedUntil) {
                        levelOccupiedUntil = [];
                        resID2levelOccupiedUntil = resID2levelOccupiedUntil.set(res.getID(), levelOccupiedUntil);
                    }

                    const tbb = getTaskBarBoundsForLevelComputation(task);
                    //levelOccupiedUntil so lange durchlaufen, bis ein Zeitpunkt darin kleiner ist als der Startzeitpunkt
                    //An dieser Stelle den Eintrag ersetzen
                    //Keinen Eintrag gefunden? -> neuen Eintrag machen
                    let level = -1;
                    for (let i = 0; i < levelOccupiedUntil.length; i++) {
                        let occupiedUntil = levelOccupiedUntil[i];
                        if (occupiedUntil <= tbb.barStartX) {
                            levelOccupiedUntil[i] = tbb.maxEndInclLabel;
                            level = i;
                            break;
                        }
                    }
                    if (level < 0) {
                        level = levelOccupiedUntil.length;
                        levelOccupiedUntil.push(tbb.maxEndInclLabel);
                    }
                    task.getDisplayData().setLevel(level);

                    let orderedTasks = reslevel2OrderedTasks.get(res.getID() + "_" + level);
                    if (!orderedTasks) {
                        orderedTasks = [];
                        reslevel2OrderedTasks = reslevel2OrderedTasks.set(res.getID() + "_" + level, orderedTasks);
                    }
                    let newLen = orderedTasks.push(task);
                    if (newLen > 1) {
                        orderedTasks[newLen - 2].getDisplayData().setSuccessor(task);
                    }

                    resID2levelOccupiedUntil = resID2levelOccupiedUntil.set(res.getID(), levelOccupiedUntil);
                }
            }

            //je nach maximalem Besetzungsgrad der Ressource muss diese von der Höhe her variieren
            // if (this.getResourceModel().isDisplayDataDirty()) {
            for (let res of this.getResourceModel().getAll()) {
                let levelOccupiedUntil = resID2levelOccupiedUntil.get(res.getID());
                if (levelOccupiedUntil) {
                    res.getDisplayData().setHeight(this.verticalPadding * 2 + (levelOccupiedUntil.length) * this.barSize);
                } else {
                    res.getDisplayData().setHeight(this.verticalPadding * 2 + this.barSize);
                }
            }
            //Neuberechnung der Ressourcen
            this.getResourceModel().recomputeDisplayData();
            //}

            //Das oberste Level kann immmer 1/levelanzahl vom Gesamtplatz einnehmen.
            //Vorgänge können immer so hoch werden wie das minimale Level eines darüberliegenden Vorgangs es erlaubt

            //Nimm der Reihe nach jeden Vorgang und schaue nach Überschneidungen der darüberliegenden Level.

            //Neuberechnung der relativen Startposition der Vorgänge
            //Dazu muss bestimmt werden, wie hoch sich ein Vorgang nach oben ausdehnen darf
            for (let i = 0; i < this.data.length; i++) {
                let task = this.data[i];
                let res = this.getResourceModel().getItemByID(task.getResID());
                if (res) {
                    let levelOccupiedUntil = resID2levelOccupiedUntil.get(task.getResID());
                    const tbbTask = getTaskBarBoundsForLevelComputation(task);
                    //TODO: Für den obersten Level wissen wir schon, dass der nur 1/levelanzahl hoch werden kann.

                    //Beim Rest müssen wir nach links und nach rechts schauen, bis keine Überschneidungen mehr möglich sind
                    //TODO: am besten vorher nach Level ordnen, dann müssen nur die darüberliegenden betrachtet werden
                    let maxExpansionLevel = levelOccupiedUntil.length - 1; //Bis zu diesem Level darf sich der Task nach oben strecken
                    //console.log("maxExpansionLevel für res "+res+": "+maxExpansionLevel + " taskLevel: "+task.getDisplayData().getLevel());
                    if (maxExpansionLevel > task.getDisplayData().getLevel()) {
                        //TODO: folgendes dauert zu lange!
                        // für alle darüberliegenden Level die Vorgänge rechts vom betrachteten Vorgang betrachten
                        for (let l = i - 1; l >= 0; l--) {
                            let leftTask = this.data[l];
                            if (leftTask.getResID() === task.getResID() && leftTask.getDisplayData().getLevel() > task.getDisplayData().getLevel()) { //Gibt es einen Vorgang mit einem größeren Level, der diesen Vorgang schneidet, und ist das Level-1 kleiner als das maxExpansionLevel, dann ist das neue ExpansionLevel dieses Level - 1
                                const tbbLeft = getTaskBarBoundsForLevelComputation(leftTask);
                                if (tbbLeft.maxEndInclLabel > tbbTask.barStartX) {
                                    if (leftTask.getDisplayData().getLevel() - 1 < maxExpansionLevel) {
                                        maxExpansionLevel = leftTask.getDisplayData().getLevel() - 1;
                                        if (maxExpansionLevel === task.getDisplayData().getLevel()) {
                                            break;
                                        }
                                    }
                                } //hier im else-Fall keinen Break, da nicht nach Endzeit geordnet
                            }
                        }

                        for (let l = i + 1; l < this.data.length; l++) {
                            let rightTask = this.data[l];
                            if (rightTask.getResID() === task.getResID() && rightTask.getDisplayData().getLevel() > task.getDisplayData().getLevel()) { //Gibt es einen Vorgang mit einem größeren Level, der diesen Vorgang schneidet, und ist das Level-1 kleiner als das maxExpansionLevel, dann ist das neue ExpansionLevel dieses Level - 1
                                const tbbRight = getTaskBarBoundsForLevelComputation(rightTask);
                                if (tbbRight.barStartX < tbbTask.maxEndInclLabel) {
                                    if (rightTask.getDisplayData().getLevel() - 1 < maxExpansionLevel) {
                                        maxExpansionLevel = rightTask.getDisplayData().getLevel() - 1;
                                        if (maxExpansionLevel === task.getDisplayData().getLevel()) {
                                            break;
                                        }
                                    }
                                } else {
                                    break;
                                }

                            }
                        }
                    }

                    let effectiveHeight = res.getDisplayData().getHeight() - 2 * this.verticalPadding;
                    let effectiveRelativeYStart = res.getDisplayData().getRelativeYStart() + this.verticalPadding;
                    let totalLevelCnt = levelOccupiedUntil.length;
                    let levelHeight = effectiveHeight / totalLevelCnt;

                    if (this.expandBars) {
                        let expansionLevelCnt = maxExpansionLevel - task.getDisplayData().getLevel() + 1;
                        task.getDisplayData().setHeight(expansionLevelCnt * levelHeight);
                    } else {
                        task.getDisplayData().setHeight(levelHeight);
                    }
                    task.getDisplayData().setRelativeYStart(effectiveRelativeYStart + effectiveHeight - task.getDisplayData().getLevel() * levelHeight - task.getDisplayData().getHeight());
                    resID2levelOccupiedUntil = resID2levelOccupiedUntil.set(res.getID(), levelOccupiedUntil);
                }
            }

            this.recomputeMovedTasksDisplayData();

            this._setDisplayDataDirty(false);
        }
    }

    recomputeMovedTasksDisplayData() {
        //Neuberechnung der relativen Startposition der Schattenvorgänge
        for (let task of this.movedTasks) {
            let res = this.getResourceModel().getItemByID(task.getResID());
            if (res !== undefined) {
                task.getDisplayData().setRelativeYStart(res.getDisplayData().getRelativeYStart() + this.verticalPadding);
                task.getDisplayData().setHeight(res.getDisplayData().getHeight() - 2 * this.verticalPadding);
            }
        }
    }


    getItemByID(id) {
        let item = super.getItemByID(id);
        if (!item) {
            //Falls mit dieser ID nichts gefunden wird, dann ist es möglich, dass das Item nur bei den movedTasks vorhanden ist
            for (let mt of this.movedTasks) {
                if (mt.getID() === id) {
                    return mt;
                }
            }
        }
        return item;
    }


    getItemCntByResourceID(resID) {
        let result = this.resID2TaskCnt.get(resID);
        if ( typeof(result) === "undefined" || result === null ) {
            result = 0;
            for (let t of this.data) {
                if (t.getResID() === resID) {
                    result ++;
                }
            }
            this.resID2TaskCnt.set(resID, result);
        }
        return result;
    }

    getItemsByResourceID(resID) {
        let result = [];
        for (let t of this.data) {
            if (t.getResID() === resID) {
                result.push(t);
            }
        }
        return result;
    }

    setMovedTasks(movedTasks) {
        this.movedTasks = movedTasks;
        this.recomputeMovedTasksDisplayData();

        //Bei displayData ein Vermerk machen welche Vorgänge jetzt als Schattenvorgänge gezeichnet werden sollen
        for (let task of this.data) {
            let isShadow = false;
            for (let mt of this.movedTasks) {
                if (mt.getID() === task.getID()) {
                    isShadow = true;
                    break;
                }
            }
            task.getDisplayData().setIsShadowTask(isShadow);
        }

        this._fireDataChanged();
        this._fireMovedTasksChanged();
    }

    getMovedTasks() {
        return this.movedTasks;
    }

    /**
     * Entfernen der Ressource und aller Vorgänge, die auf dieser Ressource liegen aus dem Model
     */
    removeResource(res) {
        this.displDataDirty = true;
        this.getResourceModel().remove(res);
        this.data = this.data.filter((t) => t.getResID() !== res.id);
    }

    /*
     * Sortierung ist aufsteigend nach Startzeit, da der Algorithmus zum übereinanderlegen das so braucht
     */
    sort() {
        this.data.sort((i1, i2) => {
            let retVal = this.getDisplayedStart(i1).getJulianMinutes() - this.getDisplayedStart(i2).getJulianMinutes();
            if (retVal === 0) {
                retVal = i1.getID() - i2.getID();
            }
            return retVal;
        });
    }
}

export default TaskModel
