import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const path = "M 8.8556451,4.9368655 3,5 C 1.9,5 1,5.89 1,7 l 0,9 2,0 c 0,1.65 1.34,3 3,3 1.66,0 3,-1.35 3,-3 l 5.5,0 c 0,1.65 1.34,3 3,3 1.66,0 3,-1.35 3,-3 l 1.111041,-0.126275 -0.378812,-4.772843 -5.192815,-0.01261 1.56258,-3.737548 2.320195,-0.5176865 3.64602,3.0178473 L 21.216448,5.1031845 16.40244,5.2799779 13.608737,11.01261 12.519667,11 z M 6,17.5 c -0.83,0 -1.5,-0.67 -1.5,-1.5 0,-0.83 0.67,-1.5 1.5,-1.5 0.83,0 1.5,0.67 1.5,1.5 0,0.83 -0.67,1.5 -1.5,1.5 z m 11.5,0 C 16.67,17.5 16,16.83 16,16 c 0,-0.83 0.67,-1.5 1.5,-1.5 0.83,0 1.5,0.67 1.5,1.5 0,0.83 -0.67,1.5 -1.5,1.5 z M 2.9413039,11.126269 2.8781694,6.4317892 7.729376,6.3055201 l 2.61104,4.8207489 z";

const ConstructionVehicle = (props) => (
		  <SvgIcon {...props}>
		    <path d={path} />
		  </SvgIcon>
);

export default ConstructionVehicle;
export {path};