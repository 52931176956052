import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import BookingDeadlines from './bookingdeadlines';
import BookingRights from './bookingrights';
import BookingOptions from './bookingoptions';
import Config from '../config/config.js';
import TransferHandler from '../transfer/transferhandler.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Details from '../ui-components/details';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AbstractResourceDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editMode : props.initialEditMode,
			name : props.data.getName() || "",
			secname : props.data.secname || "",
            description: props.data.getDescription() || "",
            imageFileName: props.data.getImgSrc() || "",
			uploadInProgress: false,
			requestDelete: false
		};
        this.saveBookingDeadline = null;
		this.saveBookingRights = null;
        this.saveBookingOptions = null;
		this.deleted = false;
	}

    chooseUploadImage() {
        this.refs.imgUploadInput.click();
    }

    uploadImage() {
		this.setState({uploadInProgress: true});
        TransferHandler.postForm(Config.getUploadImageURL(), (json)=>{this.setState({uploadInProgress: false, imageFileName: json.filename || ""})}, (code, json)=>{console.log("error")}, ReactDOM.findDOMNode(this.refs.imgUploadForm));
    }

    getImageUploadForm() {
        return this.state.uploadInProgress ?
			<CircularProgress/>
			:
			(this.state.imageFileName === "" ?
			<div>
				<Button variant="raised" onClick={() => this.chooseUploadImage()}>Bild zufügen</Button>
				<form ref="imgUploadForm" id="imgUpload" action="php/imageupload.php" method="post" encType="multipart/form-data">
					<input ref="imgUploadInput" id="imgUploadInput" type="file" accept="image/*" name="image" style={{display: "none"}} value="" onChange={()=>this.uploadImage()}/>
					<input id="sessionid" type="text" name="sessionid" style={{display: "none"}}  value={sessionStorage.getItem('pixipoint_sessionid') || ""} readOnly/>
				</form>
			</div>
            :
				<Button variant="raised" onClick={() => this.setState({imageFileName: ""})}>Bild löschen</Button>)
	}

	edit() {
        this.setState({
            editMode : true
        })
		if(this.props.onAbortableChange) {
        	this.props.onAbortableChange(false);
		}
	}

	deleteResource() {
        this.deleted = true;
        this.setState({requestDelete: false})
		this.save();
	}

    requestDeleteResource() {
			this.setState({requestDelete: true});
	}

	componentWillUnmount() {
		//Falls noch nicht gespeichert wurde und es sich um eine neue Ressource handelt, dann die Ressource löschen
		if (this.props.data && this.props.data.getID() < 0) {
			this.props.model.getResourceModel().remove(this.props.data);
		}
	}

	save() {
		this.writeToResource(this.props.data);
		this.saveBookingDeadline(this.props.data);
        this.saveBookingRights(this.props.data);
        this.saveBookingOptions(this.props.data);
		this.props.onSave();
	}

	writeToResource(res) {
		res.setName(this.state.name);
		res.secname = this.state.secname;
        res.setImgSrc(this.state.imageFileName);
        res.setDeleted(this.deleted);
        res.setDescription(this.state.description);
	}

    getIcon() {
		if(this.state.imageFileName !== "") {
            return <img src={Config.getBaseURL()+"/images/resourceimages/"+this.state.imageFileName} alt=""/>
        } else {
            return null;
        }
    }

	getStyles() {
		const styles = {
				buttonStyle : {
					color : "white"
				},
				resName : {
					fontWeight : 'bold',
					fontSize : 20
				},
				fullWidth: {
					width: "100%"  
				},
				halfWidth: {
					width: "50%"  
				},
				table: {
					tableLayout: "fixed"
				}, paper: {
					padding: 5,
					fontSize: 12,
					fontWeight: 100,
					background: '#FBFBFB'
			    }, paperHeader: {
			    	color: '#BBBBBB',
			    	marginBottom: 4
			    }, changeSpan: {
			    	float: 'right',
			    	fontWeight: 400,
			    	fontSize: 16
			    }, resIcon: {
			    	width: '70%',
			    	height: '70%'	
			    }, center: {
			    	textAlign: 'center'
			    }
			};
		
		return styles;
	}
	
	getContent() {
		return "";
	}

	getFooter() {
		let footer = <div>
						<BookingDeadlines onMount={(cb)=>this.saveBookingDeadline = cb}  editMode={this.state.editMode} data={this.props.data}/>
						<br/>
				     	<BookingRights onMount={(cb)=>this.saveBookingRights = cb} editMode={this.state.editMode} data={this.props.data} model={this.props.model}/>
						<br/>
						<BookingOptions onMount={(cb)=>this.saveBookingOptions = cb} editMode={this.state.editMode} data={this.props.data} model={this.props.model}/>
					</div>
		return footer;
	}

	getTitleString() {
		return this.props.data.getID() < 0 ? "neues Buchungsobjekt" : "Buchungsobjekt";
	}

	render() {
		let styles = this.getStyles();

		let buttons;
		if (this.state.editMode) {
			buttons = <div>
				<Button style={styles.buttonStyle} onClick={() => this.save()}>Speichern</Button>
					</div>
		} else {
            if (this.props.data.isAdmin) {
                buttons = <div>
					<Button style={styles.buttonStyle} onClick={() => this.edit()}>Bearbeiten</Button>
				</div>
            }
		}
		let isEditAdmin = !!(!this.state.editMode && this.props.data.isAdmin && this.props.data.isAdmin !== 0); //!! weil hier manchmal 0 rauskommen kann
		return (
			<Details onClose={() => this.props.onClose()} title={this.getTitleString()} addButtons={buttons} statusBarData={this.props.data}>
					{this.getContent()}
					<br/>
					{isEditAdmin && <Button variant="raised" onClick={() => this.props.onHistory()}>Historie</Button>}
					{this.state.editMode && this.props.data && this.props.data.getID() >= 0 && <Button variant="raised" onClick={() => this.requestDeleteResource()}>Löschen</Button>}
				<Dialog open={this.state.requestDelete} onClose={()=>this.setState({requestDelete: false})}>
					<DialogTitle>{"Wirklich löschen?"}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Möchten Sie wirklich die Ressource löschen?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>this.deleteResource()} color="primary">
							Ja
						</Button>
						<Button onClick={()=>this.setState({requestDelete: false})} color="primary">
							Nein
						</Button>
					</DialogActions>
				</Dialog>
	    	</Details>
		)
	}
}

export default AbstractResourceDetails;