/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

class LCalTypeField extends React.Component {
    render() {
        const classes = this.props.classes;
        return (
            <FormControl className={classes.formControl} style={{margin: 1, padding: 0}}>
                <InputLabel htmlFor="type"><span style={{whiteSpace: "nowrap"}}>{this.props.isEndType ? "Art des Endes" : "Art des Starts" }</span></InputLabel>
                <Select
                    value={this.props.type}
                    onChange={(evt) => this.props.onTypeChange(evt.target.value)}
                    inputProps={{
                        name: 'type',
                        id: 'type',
                    }}
                >
                    {!this.props.isEndType && <MenuItem value={585} style={{border: "3px solid yellow"}}><b>Zeitpunkt</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={569}><b>geboren</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={1319}><b>frühestens</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={580}><b>Start</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={571}><span><b>Entstehung</b><br/><Typography variant="caption">oder Gründung/Erstellung</Typography></span></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={575}><b>Entdeckung</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={577}><b>Publikation</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={574}><b>Erstbeschreibung</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={1636}><b>getauft</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={1191}><b>Uraufführung</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={1619}><b>eröffnet</b></MenuItem>}
                    {!this.props.isEndType && <MenuItem value={2031}><b>aktiv seit</b></MenuItem>}


                    {this.props.isEndType && <MenuItem value={570}><b>gestorben</b></MenuItem>}
                    {this.props.isEndType && <MenuItem value={1326}><b>spätestens</b></MenuItem>}
                    {this.props.isEndType && <MenuItem value={582}><b>Ende</b></MenuItem>}
                    {this.props.isEndType && <MenuItem value={730}><b>Stilllegung</b></MenuItem>}
                    {this.props.isEndType && <MenuItem value={576}><b>aufgelöst</b></MenuItem>}
                    {this.props.isEndType && <MenuItem value={746}><b>verschollen seit</b></MenuItem>}
                    {this.props.isEndType && <MenuItem value={2032}><b>aktiv bis</b></MenuItem>}
                    {this.props.isEndType && <MenuItem value={2669}><b>eingestellt</b></MenuItem>}
                    {this.props.isEndType && <MenuItem value={3999}><b>geschlossen</b></MenuItem>}
                </Select>
            </FormControl>
        )
    }
}


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    }
});


export default withStyles(styles)(LCalTypeField);
