import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TransferHandler from './transfer/transferhandler.js'
import {WaitOverlay} from '@softmanufaktur/timeline';
import Config from './config/config.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Details from './ui-components/details';

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {forename: "", surname: "", company: "", showWaitOverlay: true, isAbortable: false, imageFileName: "", uploadInProgress: false}
  }
  
  componentDidMount() {
	  //TODO: Fehlerbehandlung (Direkt als props in das WaitOverlay schreiben)

      let successFunc = (json) => {
        let isAbortable = json.forename && json.forename.length > 0 && json.surname && json.surname.length > 0;

        this.setState({forename: json.forename || "", surname: json.surname || "", company: json.company || "", imageFileName: json.imgSrc || "", showWaitOverlay: false, isAbortable: isAbortable});
      }

      let errorFunc = () => {
          //TODO: Markieren der ungültigen Eingaben
      }

	  TransferHandler.request(Config.getProfileURL(), successFunc, errorFunc);
  }

  requestAbort() {
  	//Das Fenster lässt sich nur abbrechen, wenn vorher etwas vernünftiges im Profil stand und dann abgebrochen wird
	  if(this.state.isAbortable) {
          this.props.onClose();
      }
  }
  
  saveProfile() {
      let successFunc = (json) => {
          this.props.onClose();
      }

      let errorFunc = () => {
          this.setState({showWaitOverlay: false});
      }
	  this.setState({showWaitOverlay: true});
	  TransferHandler.request(Config.setProfileURL(), successFunc, errorFunc, {forename: this.state.forename, surname: this.state.surname, company: this.state.company, imgSrc: this.state.imageFileName});
  }

  setForename(forename) {
      this.setState({forename: forename});
  }

  setSurname(surname) {
      this.setState({surname: surname});
  }

  setCompany(company) {
      this.setState({company: company});
  }

    chooseUploadImage() {
        this.refs.imgUploadInput.click();
    }

    getIcon() {
        if(this.state.imageFileName !== "") {
            return <img src={Config.getBaseURL()+"/images/resourceimages/"+this.state.imageFileName} alt=""/>
        } else {
            return null;
        }
    }

    getImageUploadForm() {
        return this.state.uploadInProgress ?
            <CircularProgress/>
            :
            (this.state.imageFileName === "" ?
            <div>
                <Button onClick={() => this.chooseUploadImage()}>Bild zufügen</Button>
                < form ref="imgUploadForm" id="imgUpload" action="php/imageupload.php" method="post" encType="multipart/form-data">
                    <input ref="imgUploadInput" id="imgUploadInput" type="file" accept="image/*" name="image" style={{display: "none"}} value="" onChange={()=>this.uploadImage()}/>
                    <input id="sessionid" type="text" name="sessionid" style={{display: "none"}}  value={sessionStorage.getItem('pixipoint_sessionid') || ""} readOnly/>
                </form>
            </div>
            :
                <Button variant="raised" onClick={() => this.setState({imageFileName: ""})}>Bild löschen</Button>)

    }

    uploadImage() {
        this.setState({uploadInProgress: true});
        TransferHandler.postForm(Config.getUploadImageURL(), (json)=>{this.setState({uploadInProgress: false, imageFileName: json.filename})}, (code, json)=>{console.log("error")}, ReactDOM.findDOMNode(this.refs.imgUploadForm));
    }

  render() {
		return (
            <Details onClose={() => this.props.onClose()} title="Profil" addButtons={<Button onClick={() => this.saveProfile()} disabled={this.state.showWaitOverlay} style={{color: "white"}}>Speichern</Button>}>
                {this.state.showWaitOverlay && <WaitOverlay/>}

                <div>
                    <TextField id="profileForename" fullWidth label="Vorname" value={this.state.forename} onChange={(evt)=>this.setForename(evt.target.value)} error = {this.state.forename.length < 1} helperText={this.state.forename.length < 1 ? "Bitte geben Sie Ihren Vornamen ein": null}/>
                    <br/>
                    <br/>
                    <TextField id="profileSurname" fullWidth label="Nachname" value={this.state.surname} onChange={(evt)=>this.setSurname(evt.target.value)} error = {this.state.surname.length < 1} helperText={this.state.surname.length < 1 ? "Bitte geben Sie Ihren Nachnamen ein": null}/>
                    <br/>
                    <br/>
                    <TextField id="profileCompany" fullWidth label="Firma" value={this.state.company} onChange={(evt)=>this.setCompany(evt.target.value)} error = {this.state.company.length === 1 || this.state.company.length === 2} helperText={this.state.company.length === 1 || this.state.company.length === 2  ? "Bitte geben Sie einen gültigen Firmennamen ein (mind. drei Stellen)": null} style={{marginBottom: 10}}/>
                    <br/>
                    <br/>
                    {this.getIcon()}
                    <br/>
                    {this.getImageUploadForm()}
                </div>

            </Details>

        )
  }
}

export default EditProfile;
