import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {shouldUseFullScreen} from '@softmanufaktur/timeline';


class OptionsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {name: props.optionGroup.name ? props.optionGroup.name : "", options: props.optionGroup.options, multichoice: props.optionGroup.multichoice};

  }

  setName(val) {
      this.setState({name: val});
  }

  render() {
      const isNameValid = this.state.name.trim().length > 1;

    return (
        <Dialog fullScreen={shouldUseFullScreen()}
            open={true}
            onClose={this.handleClose}
        >
            <DialogTitle>{this.props.mode === 'new' ? "Neue Optionsgruppe": "Optionsgruppe bearbeiten"}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Bezeichnung"
                    placeholder="z.B. Art der Fahrt"
                    value={this.state.name}
                    onChange={(evt) => this.setName(evt.target.value)}
                    helperText={isNameValid ? "" : "Bitte eine Bezeichnung angeben"}
                />
                <br/>
                <TextField
                    helperText="pro Zeile eine Option, z.B Autobahnfahrt"
                    label="Optionen"
                    value={this.state.options}
                    onChange={(evt) => this.setState({options: evt.target.value})}
                    multiline
                />
                <br/>
                <br/>
                <FormControlLabel control={
                    <Switch
                        color="primary"
                        checked={this.state.multichoice ? true : false}
                        onChange={(evt, toggle) => {this.setState({multichoice: toggle})}}
                    />
                } label="Mehrfachauswahl möglich" />
                <br/>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={()=>this.props.onCancel()}>
                    Abbrechen
                </Button>
                <Button
                    color="primary"
                    onClick={()=>this.props.onOK(this.state.name, this.state.options, this.state.multichoice)}
                    disabled={!isNameValid}>
                    OK
                </Button>
                {this.props.mode !== 'new' ? <Button
                    color="primary"
                    onClick={()=>this.props.onDelete()}
                    style={{float: "left"}}>
                    Löschen
                </Button>
                    :
                    null}
            </DialogActions>
        </Dialog>
    )
  }
}

export default OptionsDialog;
