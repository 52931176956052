import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import {AutoSuggest, shouldUseFullScreen} from '@softmanufaktur/timeline';
import TransferHandler from '../transfer/transferhandler.js';
import Config from '../config/config.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/InfoOutline';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import {findDOMNode} from 'react-dom';
import Typography from '@material-ui/core/Typography';

class UserRightsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: props.member.email,
            debitor: props.member.debitor,
            isAdmin: props.member.isAdmin ? true : false,
            dataSource: [],
            foundUser: {},
            mailIsValid: false,
            remembranceSent: false,
            helpOpen: null,
            anchor: null
        };
    }

    componentDidMount() {
        if (this.props.member.email !== "") {
            this.handleUpdateEMail(this.props.member.email);
        }
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }

    resendInvitation() {
        const transferObj = {email: this.state.email};

        //Im Erfolgsfall den members-Status aktualisieren
        let successFunc = (json) => {
            this.setState({remembranceSent: true});
        }

        let errorFunc = () => {

        }
        TransferHandler.request(Config.getSentRememberanceURL(), successFunc, errorFunc, transferObj);
    }

    handleUpdateEMail(value) {

        if (!value) {
            value = "";
        }

        value = value.toLowerCase();

        //Nur prüfen, falls die Mailadresse ein @ beinhaltet, und nach dem @ ein Punkt kommt (ansonsten könnten die User für Fremde sichtbar sein)
        let i = value.indexOf("@");
        if(i > 0) {
            if (value.indexOf(".", i + 1) > 0) {
                const transferObj = {field: "email", valuetocomplete: value}

                //Im Erfolgsfall den members-Status aktualisieren
                let successFunc = (json) => {
                    let user = {};
                    let dataSrc = [];
                    for (let a of json.options) {
                        dataSrc.push(a.email);
                        //prüfen, ob in json.options irgendwo als email die gleiche Zeichenkette vorkommt wie value -> das ist dann der user, der angezeigt werden muss und dessen Daten zurückgeliefert werden müssen
                        if (value === a.email.toLowerCase()) {
                            user = a;
                        }
                    }
                    this.setState({
                        dataSource: dataSrc,
                        foundUser: user,
                        mailIsValid: json.mailisvalid
                    });

                    if (this.state.debitor === "" && this.state.foundUser.email) {
                        this.setState({
                            debitor: user.company
                        });
                    }
                }

                let errorFunc = () => {

                }
                TransferHandler.request(Config.getAutocompleteURL(), successFunc, errorFunc, transferObj);
            }
        }
        this.setState({
            email: value
        });
    };

    render() {
        const actions = [];

        if (this.props.mode !== 'new') {
            actions.push(<Button
                color="primary"
                onClick={() => this.props.onDelete()}
                style={{float: "left"}}
            >Löschen</Button>);
        }

        let foundUserBlock = null;

        if (this.state.foundUser.email) {
            foundUserBlock = <div style={{
                color: this.state.foundUser.validated ? Config.getPositiveColor() : Config.getWarningColor(),
                fontSize: "12px"
            }}>
                {this.state.foundUser.forename + " " + this.state.foundUser.surname + ", " + this.state.foundUser.company + (this.state.foundUser.validated ? "" : " (Der Benutzer hat sich noch nie angemeldet)")}
                {this.state.foundUser.validated || this.state.remembranceSent ? null :
                    <div><br/><br/><Button variant="raised" color="primary" onClick={() => this.resendInvitation()}>Benutzer an
                        Einladung erinnern</Button></div>}
            </div>
        } else if (this.state.mailIsValid) {
            foundUserBlock =
                <div style={{color: Config.getWarningColor(), fontSize: "12px"}}>Der Benutzer ist uns bisher nicht
                    bekannt und wird zu pixipoint eingeladen.</div>
        }

        //REFACTOR: AutoComplete
        return (
            <Dialog fullScreen={shouldUseFullScreen()}
                open={true}
                onClose={this.handleClose}
            >
                <DialogTitle>{this.props.mode === 'new' ? "Neue Berechtigung" : "Berechtigung bearbeiten"}</DialogTitle>
                <DialogContent>
                    <Popover
                        open={this.state.helpOpen !== null}
                        anchorEl={this.state.anchor}
                        onClose={()=>{this.setState({helpOpen: null})}}
                    >
                        <div style={{maxWidth: window.innerWidth - 40, padding: 5}}>
                            {this.state.helpOpen}
                        </div>
                    </Popover>


                    <AutoSuggest
                        label="E-Mail"
                        searchText={this.state.email ? this.state.email : ""}
                        dataSource={this.state.dataSource}
                        onUpdateInput={(value) => this.handleUpdateEMail(value)}
                        helperText={this.state.mailIsValid ? "" : "Bitte eine gültige E-Mailadresse angeben"}
                        fullWidth
                    />
                    <br/>
                    {foundUserBlock}
                    {this.state.remembranceSent ? <div><br/>Es wurde gerade eine Erinnerung gesendet.</div> : null}
                    <br/>


                    <div style={{fontSize: 12, display: "flex", flexDirection: "row"}}>
                        <TextField
                            helperText="pro Zeile ein möglicher Rechnungsempfänger"
                            label="Rechnungsempfänger"
                            value={this.state.debitor}
                            onChange={(evt) => this.setState({debitor: evt.target.value})}
                            multiline
                            fullWidth
                        />
                        <IconButton ref={node => {this.button1 = findDOMNode(node)}} onClick={()=>{this.setState({anchor: this.button1, helpOpen: (<Typography variant="body1"  >
                                Geben Sie hier optional die Bezeichnung des Rechnungsempfängers an.
                                <br/>
                                Bei mehreren, durch Zeilenumbruch getrennten Rechnungsempfängern kann der Buchende
                                auswählen.
                                <br/>
                                Die Bezeichnung finden Sie dann im Datenexport unter dem Rechnungsempfänger wieder.
                            </Typography>)
                        })}}>
                            <InfoIcon/>
                        </IconButton>
                    </div>
                    <br/>
                    <br/>
                    <div style={{fontSize: 12, display: "flex", flexDirection: "row"}}>
                        <FormControlLabel control={
                            <Switch
                                color="primary"
                                checked={this.state.isAdmin}
                                onChange={(evt, toggle) => {
                                    this.setState({isAdmin: toggle})
                                }}
                            />
                        } label="ist Admin"/>
                        <IconButton ref={node => {this.button2 = findDOMNode(node)}} onClick={()=>{this.setState({anchor: this.button2, helpOpen: (<Typography variant="body1"  >
                            Wenn Sie einem anderen Benutzer Adminrechte auf dieses Buchungsobjekt geben, dann kann dieser:
                            <br/>
                            - das Buchungsobjekt ändern und löschen
                            <br/>
                            - alle Termine auf diesem Objekt ändern und löschen
                            <br/>
                            - Termine außerhalb der definierten Zeitschranken buchen
                        </Typography>)
                        })}}>
                            <InfoIcon/>
                        </IconButton>
                    </div>
                    <br/>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => this.props.onOK(this.state.email, this.state.debitor, this.state.isAdmin, this.state.foundUser.email ? this.state.foundUser : null)}
                        disabled={!this.state.mailIsValid}>
                        OK
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => this.props.onCancel()}>
                        Abbrechen
                    </Button>
                    {this.props.mode !== 'new' ? <Button
                        color="primary"
                        onClick={() => this.props.onDelete()}
                        style={{float: "left"}}>
                        Löschen
                    </Button>
                        :
                        null}
                </DialogActions>
            </Dialog>
        )
    }
}

export default UserRightsDialog;
