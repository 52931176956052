import BaseResource from './baseresource.js';

/**
* Eine Fahrlehrer
*/
class DrivingInstructor extends BaseResource {
  /*constructor(id, name, secname, deleted, desc, isAdmin, bookingdeadline, bookinghorizont, changeuser, userimg, changetime, imgSrc) {
      super(id, name, secname, deleted, desc, isAdmin, bookingdeadline, bookinghorizont, changeuser, userimg, changetime, imgSrc);
  }*/


  clone() {
    return new DrivingInstructor(this.id, this.name, this.secname, this.deleted, this.description, this.isAdmin, this.bookingdeadline, this.bookinghorizont, this.changeuser, this.changeUserImg, this.changetime, this.changeUserImg, this.changetime, this.imgSrc);
  }
}

export default DrivingInstructor;
