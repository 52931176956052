import BaseResource from './baseresource.js';

/**
* Eine Fahrzeugressource
*/
class Vehicle extends BaseResource {
  constructor(id, name, secname, deleted, desc, isAdmin, bookingdeadline, bookinghorizont, changeuser, userimg, changetime, imgSrc, category, brand, vm) {
    super(id, name, secname, deleted, desc, isAdmin, bookingdeadline, bookinghorizont, changeuser, userimg, changetime, imgSrc);

    this.members = [];

    this.vehicleCategory = category;
    this.vehicleBrand = brand;
    this.vehicleModel = vm;
  }

  clone() {
    return new Vehicle(this.id, this.name, this.secname, this.deleted, this.description, this.isAdmin, this.bookingdeadline, this.bookinghorizont, this.changeuser, this.changeUserImg, this.changetime, this.imgSrc, this.vehicleCategory, this.vehicleBrand, this.vehicleModel);
  }
  
  getVehicleCategory() {
	  return this.vehicleCategory;
  }
  
  setVehicleCategory(c) {
	  this.vehicleCategory = c;
  }
  
  getVehicleBrand() {
	  return this.vehicleBrand;
  }
  
  setVehicleBrand(b) {
	  this.vehicleBrand = b; 
  }
  
  setVehicleModel(m) {
	  this.vehicleModel = m;
  }
  
  getVehicleModel() {
	  return this.vehicleModel;
  }
}

export default Vehicle;
