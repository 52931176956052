import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const path = "M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5z";

const Settings = (props) => (
		  <SvgIcon {...props}>
		    <path d={path} />
		  </SvgIcon>
);


export default Settings;
export {path};