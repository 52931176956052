/**
* Eine Marke
*/
class Brand {
  constructor(id, categorykey, value) {
      this.id = id;
      this.categorykey = categorykey;
      this.value = value;
  }

}

export default Brand;
