/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 *
 */
class LevelComputationTaskBarBounds {
    constructor(barStart, maxEndInclLabel) {
        this.barStartX = barStart;
        this.maxEndInclLabel = maxEndInclLabel;
    }
}

export default LevelComputationTaskBarBounds
