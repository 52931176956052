import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import {fromJS, List, Map} from 'immutable';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import DeleteForever from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import TransferHandler from '../transfer/transferhandler.js';
import Config from '../config/config.js';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Typography from '@material-ui/core/Typography';


class BookedResourceOptions extends React.Component {
  constructor(props) {
    super(props);
    let opts = fromJS(this.props.data.getSelectedOptions());
    this.state = {optionGroups: [], chosenOpts: opts && Map.isMap(opts) ? opts : Map(), resID: this.props.data.getResID(), isStorno: false, debitors: [], debitor: props.data.getDebitor() || "-"};
  }

	componentDidMount() {
		this.updateOptions(this.state.resID);
	}

    getIcon(res) {
        let img = this.props.model.getResourceModel().getIcon(res);
        return <img style={{width: 32, height: 32, verticalAlign: 'middle', margin: 2}} src={img.src} alt=""/>
    }

    resourceChanged(resID, isStorno) {
		this.updateOptions(resID);
        this.setState({resID: resID, isStorno: isStorno});
        if(this.props.onResourceChange) {
        	this.props.onResourceChange(resID, isStorno);
		}
	}

	getChoosenOptions() {
  		return this.state.chosenOpts;
	}

	getDebitor() {
  		return this.state.debitor;
	}

	updateOptions(resID) {
        //Buchungsoptionen und die derzeit gewählten Optionen nachladen
         //Im Erfolgsfall den optionGroups-Status aktualisieren
        let successFunc = (json) => {
            this.setState({optionGroups: json.optionGroups});
        }

        let errorFunc = () => {
            //this.setState({loadingError: true})
        }

        TransferHandler.request(Config.getChoosenOptionsURL(), successFunc, errorFunc, {resourceid: resID});


        //Laden der möglichen Debitoren
		 successFunc = (json) => {
		 	this.setState({debitors: json.debitors});
		 }

		 errorFunc = () => {

		 }
		 TransferHandler.request(Config.getPossibleDebitorsURL(), successFunc, errorFunc, {resourceid: resID, taskid: this.props.data.getID()});
	}

	storno() {
  		//Auf die ursprüngliche Ressource zurücksetzen
  		//this.setState({resID: this.props.data.getResID(), isStorno: true});
  		this.resourceChanged(this.props.data.getResID(), true);
    }

    selectCBOption(groupName, option, selected) {
        let o = this.state.chosenOpts; // Abfrage nach isKeyed(this.state.chosenOpts) klappt anscheinend nicht wegen WebPack-Bug
		let selOpts = o.get(groupName);
        if(!selOpts) {
        	selOpts = List();
		}
		let index = selOpts.indexOf(option);
		if(index >= 0) {
        	if(!selected) {
                selOpts = selOpts.delete(index); //Entfernen der Option
			}
		} else {
        	if(selected) {
                selOpts = selOpts.push(option);
			}
		}

        let newChoosenOpts = o.set(groupName, selOpts);

		this.setState({chosenOpts: newChoosenOpts});
	}


    selectRBOption(groupName, option) {
        let selOpts = List();
    	selOpts = selOpts.push(option);
        let newChoosenOpts = this.state.chosenOpts.set(groupName, selOpts);

        this.setState({chosenOpts: newChoosenOpts});
    }

  render() {
	  let res = this.props.model.getResourceModel().getItemByID(this.state.resID);

	  if(this.state.isStorno) {
          return (
			  <div style={{width: "100%", marginBottom: 5, border: "1px solid #DDDDDD", background: "rgb(250, 250, 250)"}}>
				  <div style={{position: "relative"}} >
				  	<Typography style={{position: "absolute", background: "rgba(255,100,100,0.4)", color: "white", fontSize: 25, left: 0, right: 0, textAlign: "center"}}>Storno</Typography>
				  </div>
				  <div style={{display: "flex", padding: 5}}>
					  <div>
                          {this.getIcon(res)}
					  </div>
					  <div style={{flex: 1, marginLeft: 10}}>
						  <Typography>{res.getName()}</Typography>
						  <br/>
						  <Typography style={{color: "gray", fontSize: "12px"}}>{res.secname}</Typography>
					  </div>
				  </div>
			  </div>
          )
	  } else if(this.props.editMode) {
          let resourceItems = [];

          for(let r of this.props.chooseableResources) {
			  let txt = <div>
				  			<Typography style={{lineHeight: "normal"}}>{r.getName()}</Typography>
				  			<Typography style={{color: "gray", fontSize: 12, lineHeight: "normal"}}>{r.secname}</Typography>
			  			</div>
			  resourceItems.push(<MenuItem key={r.getID()} value={r.getID()}>{txt}</MenuItem>);
          }

          let optGrps = [];
          for(let optGrp of this.state.optionGroups) {
			  let choosenOpts = this.state.chosenOpts.get(optGrp.name);

              let opts = [];
              for(let opt of optGrp.options) {
              	if(optGrp.multichoice) {
              		let checked = choosenOpts && choosenOpts.indexOf(opt) >= 0;

              		let cb = <FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={checked}
								onChange={(evt, isChecked)=> this.selectCBOption(optGrp.name, opt, isChecked) }
								value={opt}
							/>
                        }
						label={opt}
						key={opt}
					/>
                    opts.push(cb);
                } else {
                    let rad = <FormControlLabel
						control={<Radio/>}
						key={opt}
						value={opt}
						label={opt}
					/>

                    opts.push(rad);
				}
              }
              let optBox;
              if(optGrp.multichoice) {
              	  optBox = <div>{opts}</div>
              } else {
				  optBox = <RadioGroup value={choosenOpts ? choosenOpts.get(0) : null} name={optGrp.name}  onChange={(evt, value)=> this.selectRBOption(optGrp.name, value)}>{opts}</RadioGroup>
			  }
              optGrps.push(<div key={optGrp.id} style={{marginLeft: 5, padding: 5, fontSize: 14}}><Typography>{optGrp.name}</Typography>{optBox}</div>);
          }

          let debitorItems = [];
          if(this.state.debitors) {
              debitorItems.push(<MenuItem key={"-"} value={"-"}>{"-"}</MenuItem>);
              for(let d of this.state.debitors) {
				  debitorItems.push(<MenuItem key={d} value={d}>{d}</MenuItem>);
              }
          }
          let getDebitorSelectField = () => {
              if(debitorItems && debitorItems.length > 0) {
                  return (<FormControl  style={{ marginLeft: 10}}>
					  <InputLabel htmlFor="debitorInput">Rechnungsempfänger</InputLabel>
					  <Select
						  value={this.state.debitor}
						  onChange={(evt) => this.setState({debitor: evt.target.value})}
						  input={<Input id="debitorInput" />}
					  >
						  {debitorItems}
					  </Select>
				  </FormControl>);
              } else {
                  return null;
              }
          }

          return (
			  <div style={{width: "100%", marginBottom: 5, border: "1px solid #DDDDDD", background: "rgb(250, 250, 250)"}}>
				  {this.props.data.getID() >= 0 ?
					  <IconButton onClick={()=>this.storno()} style={{float: "right"}}>
                      	<DeleteForever/>
                      </IconButton>
					  : this.props.onRemove
					  ? <IconButton onClick={()=>this.props.onRemove()} style={{float: "right"}}>
				  			<RemoveCircle/>
				  		</IconButton>
                      : null
                  }
				  <div style={{display: "flex", padding: 5, alignItems: "center"}}>
					  <div>
                          {this.getIcon(res)}
					  </div>
					  <div style={{flex: 1, marginLeft: 10}}>
							  <Select
								  style={{height: 60}}
								  value={this.state.resID}
								  onChange={(evt) => this.resourceChanged(evt.target.value, this.state.isStorno)}
								  input={<Input/>}
							  >
                                  {resourceItems}
							  </Select>
					  </div>
				  </div>
                  {optGrps}

				  {getDebitorSelectField()}
			  </div>
          )
      } else {
	  	let optGrps = [];
	  	for(let optGrp of this.state.optionGroups) {
            let choosenOpts = this.state.chosenOpts.get(optGrp.name);
	  		let opts = [];
	  		for(let opt of optGrp.options) {
                if(choosenOpts && choosenOpts.indexOf(opt) >= 0) { //Falls Option ausgewählt ist
                    opts.push(opt);
                }
			}
			if(opts.length>0) {
                optGrps.push(<div key={optGrp.id} style={{marginLeft: 5, padding: 5, fontSize: 14}}>
					<Typography>{optGrp.name}: </Typography><Typography>{opts.join(", ")}</Typography></div>);
            }
		}

          let getDebitorDiv = () => {
              if(this.state.debitor && this.state.debitor !== "-") {
                  return (<Typography style={{marginLeft: 5, padding: 5, fontSize: 14}}>Rechnungsempfänger: {this.state.debitor}</Typography>);
              } else {
                  return null;
              }
          }

          return (
			  <div style={{
                  width: "100%",
                  marginBottom: 5,
                  border: "1px solid #DDDDDD",
                  background: "rgb(250, 250, 250)"
              }}>
				  <div style={{display: "flex", padding: 5}}>
					  <div>
                          {this.getIcon(res)}
					  </div>
					  <div style={{flex: 1, marginLeft: 10}}>
						  <Typography>
                              {res.getName()}
							  <br/>
							  <span style={{color: "gray", fontSize: "12px"}}>{res.secname}</span>
						  </Typography>
					  </div>
				  </div>
                  {optGrps}
                  <br/>
				  {getDebitorDiv()}
			  </div>
          )
	  }
  }
}

export default BookedResourceOptions;
