/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {shouldUseFullScreen} from '@softmanufaktur/timeline';
import Config from '../config/config.js';

class NewsDialog extends React.Component {
    render() {
        let url = Config.getMailURL()+"?id="+this.props.itemID+"&setRead=true&sessionid="+ sessionStorage.getItem('pixipoint_sessionid');

        return (
            <Dialog open={true} fullScreen={shouldUseFullScreen()} onClose={this.props.onClose}>
                <div style={{width: window.innerWidth}}>
                    <iframe  title="news" width={Math.min(window.innerWidth, 600)} height={Math.min(window.innerHeight - 50, 600)} src={url} style={{borderWidth: 0}}/>
                </div>

                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default NewsDialog;