/**
* Eine Eintrag in der Berechtigungstabelle
*/
class MemberEntry {
  constructor(email, debitor, isAdmin, forename, surname, company, userKnown, validated) {
    this.email = email;
    this.debitor = debitor;
    this.isAdmin = isAdmin;
    this.forename = forename;
    this.surname = surname;
    this.company = company;
    this.userKnown = userKnown;
    this.userValidated = validated;


  }

  equals(other) {
    return other.email === this.email;
  }

  hashCode() {
    //return Immutable.hash(this.email); //TODO: funktioniert derzeit noch nicht, siehe https://github.com/facebook/immutable-js/issues/1072

      let hash = 0, len = this.email.length;
      if (this.email.length === 0) {
          return hash;
      }
      for (let i = 0; i < len; i++) {
          let charC = this.email.charCodeAt(i);
          hash = ((hash<<5)-hash)+charC;
          hash = hash & hash; // Convert to 32bit integer
      }

      return hash;
  }
}

export default MemberEntry
