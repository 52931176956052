import React from 'react';
import TextField from '@material-ui/core/TextField';
import AbstractResourceDetails from './abstractresourcedetails.js';
import Typography from '@material-ui/core/Typography';


class DefaultResourceDetails extends AbstractResourceDetails {
    getIcon() {
        let a = super.getIcon();
        if(a) {
            return a;
        } else {
            return null;
        }
    }

    getContent() {
        let styles = this.getStyles();
        let content;
        if (this.state.editMode) {
            content = <div style={styles.contentStyle}>
				<TextField id="resNameInputField" label="Bezeichnung" value={this.state.name} onChange={(evt) => this.setState({name : evt.target.value})}  style={{width: "100%", fontWeight: 'bold', fontSize: 20}}/>
				<br/>
				<TextField id="resSecNameInputField" label="Kurzbeschreibung" value={this.state.secname} onChange={(evt) => this.setState({secname : evt.target.value})} style={styles.fullWidth}/>
				<br/>
				<table style={styles.table}>
					<tbody>
					<tr>
						<td style={styles.center}>
                            {this.getIcon()}
                            <br/>
                            {this.getImageUploadForm()}
						</td>
					</tr>
					</tbody>
				</table>
				<br/>
                {this.getFooter()}
			</div>
        } else {
            content = <div style={styles.contentStyle}>
				<Typography>
					<span style={styles.resName}>{this.props.data.getName()}</span>
					<br/>
					{this.props.data.secname}
					<br/>
					<br/>
				</Typography>
					<table style={styles.table}>
						<tbody>
						<tr>
							<td style={styles.center}>
								{this.getIcon()}
							</td>
						</tr>
						</tbody>
					</table>
					<br/>
                {this.getFooter()}
			</div>
        }

        return content;
    }
}

export default DefaultResourceDetails;