import React from 'react';
import ResourceItem from './resourceitem';
import {Set} from 'immutable';

class ResourceTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedResourceIDs: new Set()};
        this.props.resTableRef && this.props.resTableRef(this);
    }

    resTapped(res, index) {
        //Als checked merken
        if(this.state.selectedResourceIDs.includes(res.id)) {
            this.setState({selectedResourceIDs: this.state.selectedResourceIDs.delete(res.id)});
        } else {
            this.setState({selectedResourceIDs: this.state.selectedResourceIDs.add(res.id)});
        }
    }


    getSelectedItemIDs() {
        return this.state.selectedResourceIDs;
    }

    render() {
        return (
            <div>
                {
                    this.props.model.getResourceModel().getAll().map((res, index) => {
                        return (res.hasAdminRights() && res.id !== this.props.excludeResID) ? <ResourceItem key={index} res={res} onClick={()=>this.resTapped(res, index)} isChecked={this.state.selectedResourceIDs.includes(res.id)} model={this.props.model}/> : null;
                    })
                }
            </div>
        )
    }
}

export default ResourceTable;
