import React from 'react';
import RoomIcon from '../icons/room';
import DefaultResourceDetails from './defaultresourcedetails.js';

class RoomResourceDetails extends DefaultResourceDetails {
    getIcon() {
        let styles = this.getStyles();
        let a = super.getIcon();
        if(a===null) {
            return <RoomIcon style={styles.resIcon}/>
        } else {
        	return a;
		}
    }

	getTitleString() {
		return (this.props.data && this.props.data.getID()) < 0 ? "neuer Raum" : "Raum";
	}
}

export default RoomResourceDetails;