/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import React from 'react';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardHeader from '@material-ui/core/CardHeader';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import styles from './styles';
import Typography from '@material-ui/core/Typography';

class ExpandableCardHeader extends React.Component {
    render() {
        const classes = this.props.classes;
        return (
            <CardHeader style={{padding: 7, paddingBottom: 0}}
                        title={<span style={{display: "flex", flexDirection: "row"}} onClick={this.props.onOpen}>
							  <span style={{flexGrow: 1}}>
                                  <Typography variant="subheading">{this.props.title}</Typography>
							  </span>
							  <IconButton
                                  className={classnames(classes.expand, {
                                      [classes.expandOpen]: this.props.open,
                                  })}
                              >
									<ExpandMoreIcon/>
							  </IconButton>
						  </span>}
            />
        )
    }
}

export default withStyles(styles)(ExpandableCardHeader);
